import { numberInputAnatomy as parts } from '@chakra-ui/anatomy';
import type {
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleFunction,
  SystemStyleObject,
} from '@chakra-ui/theme-tools';
import { cssVar } from '@chakra-ui/theme-tools';
import { Input } from './Input';

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/number-input.ts

const { variants, defaultProps } = Input;

const $stepperWidth = cssVar('number-input-stepper-width');

const baseStyleRoot: SystemStyleObject = {
  [$stepperWidth.variable]: '42px',
};

const baseStyleStepper: SystemStyleFunction = () => {
  return {
    border: 'none',
  };
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  root: baseStyleRoot,
  stepper: baseStyleStepper(props),
});

type Size = 'xs' | 'sm' | 'md' | 'lg';

function getSize(size: Size): PartsStyleObject<typeof parts> {
  const sizeStyle = Input.sizes[size];
  return {
    field: {
      ...sizeStyle.field,
    },
  };
}

const sizes = {
  xs: getSize('xs'),
  sm: getSize('sm'),
  md: getSize('md'),
  lg: getSize('lg'),
};

export const NumberInput = {
  parts: parts.keys,
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
