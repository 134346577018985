import { MemberRoles } from '@monax/aspen-spec';

export type UseCase =
  | 'organizations-manage-organization'
  | 'storefronts-create-storefront'
  | 'storefronts-update-storefront'
  | 'storefronts-launch-storefront'
  | 'storefronts-update-storefront-organization-id'
  | 'storefronts-update-storefront-owner-address';

type RoleToUseCase = Record<MemberRoles, Record<UseCase, boolean>>;

const index: RoleToUseCase = {
  ADMIN: {
    'organizations-manage-organization': true,
    'storefronts-create-storefront': true,
    'storefronts-update-storefront': true,
    'storefronts-launch-storefront': true,
    'storefronts-update-storefront-organization-id': true,
    'storefronts-update-storefront-owner-address': true,
  },
  MEMBER: {
    'organizations-manage-organization': false,
    'storefronts-create-storefront': false,
    'storefronts-update-storefront': true,
    'storefronts-launch-storefront': false,
    'storefronts-update-storefront-organization-id': false,
    'storefronts-update-storefront-owner-address': false,
  },
  READONLY: {
    'organizations-manage-organization': false,
    'storefronts-create-storefront': false,
    'storefronts-update-storefront': false,
    'storefronts-launch-storefront': false,
    'storefronts-update-storefront-organization-id': false,
    'storefronts-update-storefront-owner-address': false,
  },
};

export const hasUseCase = (role: MemberRoles, useCase: UseCase): boolean => {
  return index[role][useCase];
};
