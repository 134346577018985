import { isAxiosError } from '@/utils/axios';
import { addBreadcrumb, captureException } from '@/utils/sentry';
import { toast } from '@/utils/toast';
import { capitalize } from 'lodash';

type Options = {
  context?: unknown;
  disableToast?: boolean;
  toastDuration?: number;
  captureException?: boolean;
};

export type HandleError = (error: unknown, message?: string, options?: Options) => void;

export const useError = () => {
  return {
    handleError: (error: unknown, message = '', options: Options = {}) => {
      handleError(error, message, options);
    },
  };
};

export const getErrorMessage = (error: unknown) => {
  let msg = undefined;
  if (isAxiosError<{ message: string }>(error) && error.response?.data.message) {
    msg = capitalize(error.response.data.message);
  } else if (error instanceof Error) {
    msg = error.message;
  }

  return msg;
};

export const handleError: HandleError = (error: unknown, message = '', options: Options = {}) => {
  console.error(error);

  const errorMessage = getErrorMessage(error);

  addBreadcrumb({
    category: 'error',
    message: message,
    data: {
      context: options.context,
      errorMessage: errorMessage,
      error: JSON.parse(JSON.stringify(error)),
    },
  });

  if (!options.disableToast) {
    toast({
      status: 'error',
      title: message ? message : errorMessage,
      duration: options.toastDuration,
    });
  }

  if (options.captureException) {
    captureException(error, {
      level: 'fatal',
      extra: { message, context: options.context },
    });
  }
};
