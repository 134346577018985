export const QUERY_GROUP = {
  nfts: 'nfts',
  user: 'user',
  userFolders: 'user-folders',
  collection: 'collection',
  storefront: 'storefront',
  ens: 'ens',
  chain: 'chain',
  creatorToolsStorefront: 'creator-tools-storefront',
  creatorToolsMarketing: 'creator-tools-marketing',
  creatorToolsRoyalties: 'creator-tools-royalties',
  creatorToolsMembership: 'creator-tools-membership',
  creatorToolsRedemption: 'creator-tools-redemption',
  marketplace: 'marketplace',
  media: 'media',
  nftDrop: 'nft-drop',
  subscription: 'subscription',
  royalties: 'royalties',
  organization: 'organization',
  stripe: 'stripe',
  webhooks: 'webhooks',
};

export const QUERY_KEY = {
  nft: `${QUERY_GROUP.nfts}-item`,
  nftAdjacent: `${QUERY_GROUP.nfts}-adjacent-list`,
  nftEvents: `${QUERY_GROUP.nfts}-events-list`,
  nftsPorfolio: `${QUERY_GROUP.nfts}-portfolio-list`,
  nftsAccountNfts: `${QUERY_GROUP.nfts}-account-list`,
  nftsCollectionNfts: `${QUERY_GROUP.nfts}-collection-list`,
  nftCollections: `${QUERY_GROUP.nfts}-collections-list`,
  nftCollectionsOwned: `${QUERY_GROUP.nfts}-owned-collections-list`,
  nftOwnership: `${QUERY_GROUP.nfts}-owns-token`,
  nftOwnedCount: `${QUERY_GROUP.nfts}-tokens-owned-count`,
  nftOwner: `${QUERY_GROUP.nfts}-owner`,
  nftClaimConditions: `${QUERY_GROUP.nfts}-claim-conditions`,
  nftDummyList: `${QUERY_GROUP.nfts}-dummy-list`,
  nftFauxTokenList: `${QUERY_GROUP.nfts}-faux-token-list`,

  storefrontPublicInfo: `${QUERY_GROUP.storefront}-public-info`,
  storefrontRoyaltiesInfo: `${QUERY_GROUP.storefront}-royalties-info`,
  baseCollectionInfo: `${QUERY_GROUP.storefront}-base-collection-info`,
  featuredStorefronts: `${QUERY_GROUP.storefront}-featured-storefronts`,

  collectionContractInfo: `${QUERY_GROUP.collection}-contract-info`,
  collectionContractInfoExtended: `${QUERY_GROUP.collection}-contract-info-extended`,

  userCurrencyBalances: `${QUERY_GROUP.user}-user-currency-balances`,
  userPortfolioNfts: `${QUERY_GROUP.user}-user-portfolio-nfts`,

  userAssetFolders: `${QUERY_GROUP.userFolders}-user-asset-folder-list`,
  userAssetFolder: `${QUERY_GROUP.userFolders}-user-asset-folder`,
  userAssetFolderAssets: `${QUERY_GROUP.userFolders}-user-asset-folder-asset-list`,
  userDefaultAssetFolder: `${QUERY_GROUP.userFolders}-default-user-asset-folder`,

  ensLookupAddress: `${QUERY_GROUP.ens}-lookup-address`,
  ensLookupName: `${QUERY_GROUP.ens}-lookup-name`,

  chainERC20Info: `${QUERY_GROUP.chain}-erc20-info`,
  nativeTokenBalance: `${QUERY_GROUP.chain}-native-token-balance`,

  creatorToolsStorefronts: `${QUERY_GROUP.creatorToolsStorefront}-storefront-list`,
  creatorToolsStorefrontMembershipLists: `${QUERY_GROUP.creatorToolsMembership}-storefront-lists`,
  creatorToolsStorefrontsMembershipLists: `${QUERY_GROUP.creatorToolsMembership}-storefronts-lists`,
  creatorToolsStorefrontsIdMembership: `${QUERY_GROUP.creatorToolsMembership}-storefront-id`,
  creatorToolsMembershipLists: `${QUERY_GROUP.creatorToolsMembership}-membership-list`,
  creatorToolsMembershipListUserDiscordStatus: `${QUERY_GROUP.creatorToolsMembership}-user-discord-status`,
  creatorToolsStorefrontTermTemplates: `${QUERY_GROUP.creatorToolsStorefront}-term-templates`,
  creatorToolsStorefrontAspenMetadata: `${QUERY_GROUP.creatorToolsStorefront}-storefront-aspen-metadata`,
  creatorToolsStorefrontsCorrespondenceInfo: `${QUERY_GROUP.creatorToolsStorefront}-correspondence-info`,
  creatorToolsCollectionTokens: `${QUERY_GROUP.creatorToolsStorefront}-tokens-list`,
  creatorToolsAllRoyaltiesStats: `${QUERY_GROUP.creatorToolsRoyalties}-royalty-stats-list`,
  creatorToolsRedemptionConfigurations: `${QUERY_GROUP.creatorToolsRedemption}-redemption-configuration-list`,

  marketplaceAttributesAll: `${QUERY_GROUP.marketplace}-attributes-all`,
  marketplaceAttributesExplore: `${QUERY_GROUP.marketplace}-attributes-explore`,
  marketplaceStats: `${QUERY_GROUP.marketplace}-stats`,
  marketplaceTokenAsks: `${QUERY_GROUP.marketplace}-token-asks`,
  marketplaceTokens: `${QUERY_GROUP.marketplace}-tokens`,

  media: `${QUERY_GROUP.media}-item`,

  nftDropGenerateOneTimePads: `${QUERY_GROUP.nftDrop}-generate-one-time-pads`,
  nftDropDownloadQrCodes: `${QUERY_GROUP.nftDrop}-download-qr-codes`,
  nftDropPadStatus: `${QUERY_GROUP.nftDrop}-one-time-pad-status-item`,
  nftDropStorefrontPads: `${QUERY_GROUP.nftDrop}-storefront-pads`,
  nftDropStorefronts: `${QUERY_GROUP.nftDrop}-storefronts-list`,

  subscriptionPublicSubscriptionDefinition: `${QUERY_GROUP.subscription}-public-subscription-definition`,
  subscriptionSubscriptionDefinition: `${QUERY_GROUP.subscription}-subscription-definition`,
  subscriptionStorefrontIdsForSubscription: `${QUERY_GROUP.subscription}-storefront-ids-for-subscription`,
  subscriptionPayments: `${QUERY_GROUP.subscription}-subscription-payments`,
  subscriptionDetailedList: `${QUERY_GROUP.subscription}-subscription-detailed-list`,
  subscriptionVoucherCampaigns: `${QUERY_GROUP.subscription}-voucher-campaigns`,
  subscriptionVoucherCampaignVouchers: `${QUERY_GROUP.subscription}-voucher-campaigns-vouchers`,
  subscriptionCoupon: `${QUERY_GROUP.subscription}-coupon`,
  subscriptionCoupons: `${QUERY_GROUP.subscription}-coupons`,
  subscriptionSubscriber: `${QUERY_GROUP.subscription}-subscription-subscriber`,
  subscriptionUserInvoices: `${QUERY_GROUP.subscription}-user-subscription-invoices`,

  royaltiesDue: `${QUERY_GROUP.royalties}-royalties-due`,

  organizationById: `${QUERY_GROUP.organization}-organization-by-id`,

  stripeIssuanceStatus: `${QUERY_GROUP.stripe}-issuance-status`,

  webhooksWebhooksForOrganization: `${QUERY_GROUP.webhooks}-webhooks-for-organization`,
};
