import { switchAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleFunction, SystemStyleFunction } from '@chakra-ui/theme-tools';

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/switch.ts

const baseStyleTrack: SystemStyleFunction = ({ colorScheme }) => {
  return {
    _checked: {
      bg: colorScheme === 'primary' ? 'primary' : undefined,
    },
  };
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  track: baseStyleTrack(props),
});

const defaultProps = {
  size: 'md',
  colorScheme: 'primary',
};

export const Switch = {
  parts: parts.keys,
  baseStyle,
  defaultProps,
};
