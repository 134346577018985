import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { HStack, Spinner, Text, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.vite';

export type PdfViewMode = 'all-pages' | 'first-page' | 'pager';

type Props = {
  pdfLink: string;
  viewMode?: PdfViewMode;
};

export const PdfViewer: React.FC<Props> = ({ pdfLink, viewMode = 'first-page' }) => {
  if (viewMode === 'pager') return <PdfViewerPager pdfLink={pdfLink} />;
  return <PdfViewerSingle pdfLink={pdfLink} showAllPages={viewMode === 'all-pages'} />;
};

type PdfViewerSingleProps = {
  pdfLink: string;
  showAllPages: boolean;
};
const PdfViewerSingle: React.FC<PdfViewerSingleProps> = ({ pdfLink, showAllPages }) => {
  const [numPages, setNumPages] = useState<number>(0);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  return (
    <VStack w="full" h="full" overflow={showAllPages ? 'auto' : 'hidden'}>
      <Document file={pdfLink} onLoadSuccess={onDocumentLoadSuccess} loading={<Spinner />}>
        {[...Array(showAllPages ? numPages : 1)]
          .map((_x, i) => i + 1)
          .map((pageNumber) => (
            <Page key={pageNumber} renderAnnotationLayer={false} renderTextLayer={false} pageNumber={pageNumber} />
          ))}
      </Document>
    </VStack>
  );
};

type PdfViewerPagerProps = {
  pdfLink: string;
};

const PdfViewerPager: React.FC<PdfViewerPagerProps> = ({ pdfLink }) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  const nextPage = () => {
    if (pageNumber === numPages) return;
    setPageNumber(pageNumber + 1);
  };

  const previousPage = () => {
    if (pageNumber === 1) return;
    setPageNumber(pageNumber - 1);
  };

  return (
    <VStack w="full" h="full">
      <Document file={pdfLink} onLoadSuccess={onDocumentLoadSuccess} loading={<Spinner />}>
        <Page renderAnnotationLayer={false} renderTextLayer={false} pageNumber={pageNumber} />
      </Document>
      {numPages > 1 && (
        <HStack display="flex" flex="none">
          <ArrowLeftIcon cursor="pointer" color="primary" onClick={() => previousPage()} />
          <Text>
            {'Page '}
            {pageNumber}
            {' of '}
            {numPages}
          </Text>
          <ArrowRightIcon cursor="pointer" color="primary" onClick={() => nextPage()} />
        </HStack>
      )}
    </VStack>
  );
};
