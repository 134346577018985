import { CheckIcon } from '@chakra-ui/icons';
import { HStack, MenuItem, Spacer, Text } from '@chakra-ui/react';
import React from 'react';

type Props = {
  isSelected?: boolean;
  onClick?: () => void;
  icon?: React.ReactNode;
};

export const StyledMenuItem: React.FC<React.PropsWithChildren<Props>> = ({ onClick, isSelected, icon, children }) => {
  return (
    <MenuItem onClick={() => onClick && onClick()}>
      <HStack alignItems="center" spacing={3} w="full">
        {icon}
        <Text fontWeight={isSelected ? 'semibold' : 'normal'}>{children}</Text>
        {isSelected && (
          <>
            <Spacer />
            <CheckIcon color="green.500" />
          </>
        )}
      </HStack>
    </MenuItem>
  );
};
