import { Icon, ResponsiveValue } from '@chakra-ui/react';
import React from 'react';
import { BiDollar } from 'react-icons/bi';

type Props = {
  active?: boolean;
  height?: ResponsiveValue<string>;
  width?: ResponsiveValue<string>;
};

export const SubscriptionsIcon: React.FC<Props> = ({ active = false, height = '5', width = '5' }) => {
  return <Icon as={BiDollar} color={active === true ? 'white' : 'gray.300'} height={height} width={width} />;
};
