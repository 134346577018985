import { tabsAnatomy as parts } from '@chakra-ui/anatomy';
import { cssVar } from '@chakra-ui/react';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import type {
  PartsStyleFunction,
  PartsStyleInterpolation,
  PartsStyleObject,
  SystemStyleFunction,
} from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';
import { semanticTokens } from '../colors';

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/tabs.ts

const $fg = cssVar('tabs-color');
const $bg = cssVar('tabs-bg');

const { definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const baseStyleTab: SystemStyleFunction = () => {
  return {
    _selected: { color: 'primary', fontWeight: 'semibold' },
  };
};

const baseStyleTablist: SystemStyleFunction = () => {
  return {};
};

const baseStyleTabpanel: SystemStyleFunction = () => {
  return {};
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  tab: baseStyleTab(props),
  tablist: baseStyleTablist(props),
  tabpanel: baseStyleTabpanel(props),
});

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  sm: definePartsStyle({
    tab: {
      py: 1,
      px: 4,
      fontSize: 'sm',
    },
  }),
  md: definePartsStyle({
    tab: {
      fontSize: 'md',
      py: 2,
      px: 4,
    },
    tablist: {
      h: 9, // to match lg button height
    },
  }),
  lg: definePartsStyle({
    tab: {
      fontSize: 'lg',
      py: 3,
      px: 4,
    },
    tablist: {
      h: 12, // to match xl button height
    },
  }),
  xl: definePartsStyle({
    tab: {
      fontSize: 'xl',
      px: 5,
    },
    tablist: {
      h: 14, // to match xl button height
    },
  }),
};

const variantUnstyled: PartsStyleFunction<typeof parts> = () => {
  return {
    tab: {
      _selected: {
        color: 'primary',
        fontWeight: 'semibold',
      },
    },
  };
};

const variantBordered: PartsStyleFunction<typeof parts> = (props) => {
  // Negative margin matching the `Tabs` size variant, that allows
  // the selected item border to match the Tabs outter border.
  const negativeMargin = `-${sizes[props.size].tab?.py || '1'}`;

  return {
    tablist: {
      mb: 0,
      py: '5px',
      backgroundColor: 'cardBackground',
      borderRadius: 'md',
    },
    tab: {
      marginTop: negativeMargin,
      marginBottom: negativeMargin,
      border: `1px solid transparent`,
      borderRadius: 'md',
      _selected: {
        borderColor: 'primary',
        textDecoration: 'none',
      },
    },
    tabpanel: {
      px: 0,
    },
  };
};

const variantLineGradient: PartsStyleFunction<typeof parts> = (props) => {
  const { orientation } = props;
  const isVertical = orientation === 'vertical';
  const borderProp = isVertical ? 'borderStart' : 'borderBottom';
  const marginProp = isVertical ? 'marginStart' : 'marginBottom';

  return {
    tablist: {
      [borderProp]: '2px solid',
      borderColor: 'inherit',
    },
    tab: {
      [borderProp]: '2px solid',
      borderColor: 'transparent',
      [marginProp]: '-2px',
      _selected: {
        textDecoration: 'none',
        background: 'primaryGradient',
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        border: '10px solid',
        borderImageSlice: 1,
        borderWidth: '2px',
        borderImageSource: mode(semanticTokens.primaryGradient.default, semanticTokens.primaryGradient._dark)(props),
        borderRight: 0,
        borderLeft: 0,
        borderTop: 0,
      },
      _active: {
        [$bg.variable]: 'colors.gray.200',
        _dark: {
          [$bg.variable]: 'colors.whiteAlpha.300',
        },
      },
      _disabled: {
        _active: { bg: 'none' },
      },
      color: $fg.reference,
      bg: $bg.reference,
    },
  };
};

const variantButtonGradient: PartsStyleFunction<typeof parts> = (props) => {
  const textColor = mode('black', 'white')(props);
  const textSubtle = mode('gray.500', 'gray.400')(props);
  return {
    tab: {
      w: 'full',
      h: 'full',
      mx: 6,
      role: 'group',
      borderRadius: 'full',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: textSubtle,
      fontWeight: 'medium',
      textDecoration: 'none',
      position: 'relative',
      transition: '0.5s',
      _selected: {
        color: textColor,
        _before: {
          border: '2px solid transparent',
          borderColor: undefined,
          background: `${mode(
            semanticTokens.primaryGradient.default,
            semanticTokens.primaryGradient._dark,
          )(props)} border-box`,
        },
      },
      _hover: {
        color: textColor,
        _before: {
          border: '1px solid transparent',
          opacity: 0.5,
          borderColor: undefined,
          background: `${mode(
            semanticTokens.primaryGradient.default,
            semanticTokens.primaryGradient._dark,
          )(props)} border-box`,
        },
      },
      _before: {
        content: `""`,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: 'full',
        border: '1px solid transparent',
        borderColor: textSubtle,
        WebkitMask: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
        WebkitMaskComposite: 'destination-out',
        maskComposite: 'exclude',
        transition: '0.5s',
      },
    },
  };
};

const variants: Record<string, PartsStyleInterpolation<typeof parts>> = {
  unstyled: variantUnstyled,
  bordered: variantBordered,
  'line-gradient': variantLineGradient,
  'button-gradient': variantButtonGradient,
};

const defaultProps = {
  size: 'md',
  variant: 'unstyled',
  isFitted: true,
};

export const Tabs = {
  parts: parts.keys,
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
