import { Icon, ResponsiveValue } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineGift } from 'react-icons/ai';

type Props = {
  active?: boolean;
  height?: ResponsiveValue<string>;
  width?: ResponsiveValue<string>;
};

export const RedemptionIcon: React.FC<Props> = ({ active = false, height = '5', width = '5' }) => {
  return <Icon as={AiOutlineGift} color={active === true ? 'white' : 'gray.300'} height={height} width={width} />;
};
