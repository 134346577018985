import { ColorModeIcon } from '@/components/icons/general/ColorModeIcon';
import { PendingUserInvitationsCard } from '@/components/organizations/PendingUserInvitationsCard';
import { UserOrganizationsMenu } from '@/components/organizations/UserOrganizationsMenu';
import { ProfileButton } from '@/components/profile/ProfileButton';
import { getRootPath } from '@/components/routes';
import { useAtScrollTop } from '@/hooks/common/useAtScrollTop';
import { Box, Button, ContainerProps, HStack, Spacer, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '..';
import { Container } from '../Container';

type Props = {
  hideProfileButton?: boolean;
  maxW?: ContainerProps['maxW'];
  title?: React.ReactNode;
  titleAddOn?: React.ReactNode;
  footer?: React.ReactNode;
  pageLayoutRef?: React.RefObject<HTMLDivElement>;
};

export const PageHeader: React.FC<React.PropsWithChildren<Props>> = ({
  hideProfileButton,
  maxW = 'container.xl',
  title,
  titleAddOn,
  footer,
  pageLayoutRef,
  children,
}) => {
  const headerBackground = useColorModeValue('rgba(23, 23, 23, 0.1)', 'rgba(23, 23, 23, 0.7)');
  const atScrollTop = useAtScrollTop(pageLayoutRef);
  const navigate = useNavigate();

  return (
    <Box
      position="sticky"
      top="0"
      zIndex="sticky"
      w="full"
      backgroundColor={atScrollTop ? 'transparent' : headerBackground}
      backdropFilter={atScrollTop ? undefined : 'blur(16px)'}
    >
      <Container maxW={maxW}>
        <HStack w="full" py={5}>
          {children}
          {!hideProfileButton && (
            <>
              <Spacer />
              <Button variant="gradient" size="xl" color="white" onClick={() => navigate(getRootPath())}>
                Collect
              </Button>
              <PendingUserInvitationsCard />
              <UserOrganizationsMenu />
              <ColorModeIcon />
              <ProfileButton />
            </>
          )}
        </HStack>
        {title && <PageTitle addOn={titleAddOn}>{title}</PageTitle>}
        {footer}
      </Container>
    </Box>
  );
};
