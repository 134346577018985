import { config } from '@/config';
import IPFSGatewayTools from '@pinata/ipfs-gateway-tools/dist/browser';
import isIpfs from 'is-ipfs';

const gatewayTools = new IPFSGatewayTools();

export function containsCID(hashOrUrl: string): string {
  return gatewayTools.containsCID(hashOrUrl).containsCid;
}

export function getCID(hashOrUrl: string): string {
  return gatewayTools.containsCID(hashOrUrl).cid;
}

export function resolveIpfsUrl(hashOrUrl: string): string {
  let url = hashOrUrl;
  if (isIpfs.cid(url)) {
    url = `ipfs://${hashOrUrl}`;
  }

  if (url.indexOf('ipfs://') === 0) {
    try {
      return gatewayTools.convertToDesiredGateway(url, config.integrations.ipfsGatewayUrl);
    } catch (err) {
      console.error(url, err);
    }
  }

  return url;
}
