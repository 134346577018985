import { parse } from '@monax/xylem/dist/schema';
import { UUIDFromString } from '@monax/xylem/dist/types';
import jwt_decode from 'jwt-decode';
import { SigninResponse } from 'oidc-client-ts';
import { persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

type TokenData = {
  exp: number;
  iat: number;
  iss: string;
  sub: string;
};

type State = {
  token: string | null;
  signinResponse: SigninResponse | null;
  userId: UUIDFromString | null;
  expiresAt: number | null;
  signature: string | null;
};

type Actions = {
  handleSignIn: (signinResponse: SigninResponse) => void;
  reset: () => void;
  updateSignature: (signature: string) => void;
};

// define the initial state
const initialState: State = {
  token: null,
  signinResponse: null,
  userId: null,
  expiresAt: null,
  signature: null,
};

export const accessTokenStore = createWithEqualityFn<State & Actions>()(
  persist(
    (set) => ({
      ...initialState,
      handleSignIn: (signinResponse: SigninResponse) => {
        set({ token: signinResponse.access_token, signinResponse: signinResponse });
        const decoded = jwt_decode<TokenData>(signinResponse.access_token);
        set({ userId: parse(UUIDFromString, decoded.sub), expiresAt: decoded.exp });
      },
      reset: () => {
        set(initialState);
      },
      updateSignature: (signature: string) => {
        set({ signature });
      },
    }),
    {
      name: 'user-access-token-store-v2', // Bumped to v2 when migrating to identity service
    },
  ),
  shallow,
);
