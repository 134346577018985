import { AspectRatio, AspectRatioProps, Box, BoxProps } from '@chakra-ui/react';
import React, { LegacyRef, NamedExoticComponent, PropsWithChildren } from 'react';

const DEFAULT_CARD_MIN_W = '250px';
const DEFAULT_CARD_MAX_W = '350px';
const DEFAULT_CONTENT_HEIGHT = '80px';
const DEFAULT_FOOTER_HEIGHT = '22px';

type Props = {
  ref?: LegacyRef<HTMLDivElement> | undefined;
} & BoxProps;

const Card: React.FC<Props> = (boxProps) => {
  return (
    <Box
      minW={DEFAULT_CARD_MIN_W}
      maxW={DEFAULT_CARD_MAX_W}
      display="flex"
      flexDir="column"
      borderRadius="xl"
      overflow="hidden"
      backgroundColor="cardBackground"
      {...boxProps}
    />
  );
};

const Image = React.memo(function BaseCardImage({ children, ...aspectRatioProps }: AspectRatioProps) {
  return (
    <AspectRatio w="full" flex="1" ratio={1} {...aspectRatioProps}>
      {children}
    </AspectRatio>
  );
});

const Content = React.memo(function BaseCardContent({ children, ...boxProps }: Props) {
  return (
    <Box w="full" h={DEFAULT_CONTENT_HEIGHT} {...boxProps}>
      {children}
    </Box>
  );
});

const Footer = React.memo(function BaseCardFooter({ children, ...boxProps }: Props) {
  return (
    <Box w="full" h={DEFAULT_FOOTER_HEIGHT} {...boxProps}>
      {children}
    </Box>
  );
});

type MemoizedCardCompose = {
  Image: NamedExoticComponent<AspectRatioProps>;
  Content: NamedExoticComponent<Props>;
  Footer: NamedExoticComponent<Props>;
};

export const BaseCard = React.memo(Card) as NamedExoticComponent<PropsWithChildren<Props>> & MemoizedCardCompose;

// Composition must come after memoization, otherwise `.displayName` in BaseCard will throw errors.
BaseCard.Image = Image;
BaseCard.Content = Content;
BaseCard.Footer = Footer;
