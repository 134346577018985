import { getEnabledFields } from '@/utils/validation';
import { UUIDFromString } from '@monax/xylem/dist/types';
import { useEffect, useState } from 'react';
import { Path } from 'react-hook-form';
import { useStore } from 'zustand';
import { redemptionConfigStore } from '../store/redemptionConfigStore';
import { EditRedemptionConfigForm } from '../types';

const ALL_FIELDS_ENABLED: Record<Path<EditRedemptionConfigForm>, boolean> = {
  name: true,
  description: true,
  image: true,
  requireEmail: true,
  requireShippingAddress: true,
  webhookId: true,
  subscriptionId: true,
  subscriptionVoucherCampaignId: true,
};

export const useRedemptionConfigFields = (storefrontId: UUIDFromString) => {
  const { storefrontRedemptionConfigs, selectedRedemptionConfigId } = useStore(redemptionConfigStore, (s) => ({
    storefrontRedemptionConfigs: s.storefrontRedemptionConfigs,
    selectedRedemptionConfigId: s.selectedRedemptionConfigId,
  }));

  const storefrontRedemptions = storefrontRedemptionConfigs[storefrontId];
  const publishedRedemption = storefrontRedemptions.find((r) => r.id === selectedRedemptionConfigId)?.published;

  const [enabledFields, setEnabledFields] = useState<Path<EditRedemptionConfigForm>[]>([]);

  useEffect(() => {
    if (publishedRedemption) {
      setEnabledFields(['description']);
      return;
    }

    setEnabledFields(
      getEnabledFields({
        ...ALL_FIELDS_ENABLED,
      }),
    );
  }, [publishedRedemption]);

  return {
    enabledFields,
    allFieldsDisabled: enabledFields.length === 0,
  };
};
