import { Box, FormControl, FormErrorMessage, FormErrorMessageProps } from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';

type Props<T extends FieldValues> = {
  name: Path<T>;
  isStandAlone?: boolean;
  errorMessageProps?: FormErrorMessageProps;
};

function InputError<T extends FieldValues>({ name, isStandAlone, errorMessageProps }: Props<T>) {
  const {
    formState: { errors },
  } = useFormContext();

  const errorMessage = (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message, messages }) => {
        if (!messages) return <FormErrorMessage {...errorMessageProps}>{message}</FormErrorMessage>;
        return (
          <FormErrorMessage {...errorMessageProps}>
            {Object.entries(messages).map(([type, message]) => (
              <Box key={type}>{message}</Box>
            ))}
          </FormErrorMessage>
        );
      }}
    />
  );

  if (!isStandAlone) return <>{errorMessage}</>;

  return <FormControl isInvalid={true}>{errorMessage}</FormControl>;
}

export { InputError };
