import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/checkbox.ts

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

// TODO[Daniel]: add more sizes
const sizes = {
  sm: definePartsStyle({
    label: defineStyle({
      fontSize: 'md',
    }),
  }),
};

const baseStyle = definePartsStyle({
  icon: {
    boxSize: 5,
    color: 'white',
  },
  label: {
    marginLeft: 2,
  },
  control: {
    p: 0,
    borderRadius: 'sm',
    boxSize: 5,
    borderWidth: '1px',
    _light: {
      borderColor: 'gray.300',
    },
    _dark: {
      borderColor: 'white',
    },
    _checked: {
      backgroundColor: 'primary',
    },
  },
});

export const Checkbox = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: { size: 'sm' },
});
