import React, { useEffect, useRef } from 'react';
import { useBlurhashQuery } from './hooks/useBlurhashQuery';

export type Props = {
  // The encoded blurhash string.
  hash: string;
  // Width (CSS) of the decoded image.
  width?: number | string | 'auto';
  // Height (CSS) of the decoded image.
  height?: number | string | 'auto';
  // The X-axis resolution in which the decoded image will be rendered at. Recommended min. 32px. Large sizes (>128px) will greatly decrease rendering performance. (Default: 32)
  resolutionX?: number;
  // The Y-axis resolution in which the decoded image will be rendered at. Recommended min. 32px. Large sizes (>128px) will greatly decrease rendering performance. (Default: 32)
  resolutionY?: number;
  // Controls the "punch" value (~contrast) of the blurhash decoding algorithm. (Default: 1)
  punch?: number;
};

const canvasStyle: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  width: '100%',
  height: '100%',
};

// This component is based off: https://github.com/woltapp/react-blurhash
const BlurhashCanvasBase: React.FC<Props> = ({
  hash,
  height = 100,
  width = 100,
  resolutionX = 32,
  resolutionY = 32,
  punch = 1,
}) => {
  const ref = useRef<HTMLCanvasElement>(null);

  const query = useBlurhashQuery({ hash, width: resolutionY, height: resolutionX, punch });

  useEffect(() => {
    const canvas = ref.current;

    if (!canvas || !query.data) return;

    const pixels = query.data;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const imageData = ctx.createImageData(resolutionX, resolutionY);
    imageData.data.set(pixels);
    ctx.putImageData(imageData, 0, 0);
  }, [query.data]);

  return (
    <div style={{ display: 'inline-block', height, width, position: 'relative' }}>
      <canvas style={canvasStyle} height={resolutionY} width={resolutionX} ref={ref} />
    </div>
  );
};

export const BlurhashCanvas = React.memo(BlurhashCanvasBase);
