import { useReconnectLastConnector } from '@/hooks';
import { trackEventStore } from '@/utils/segment';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useStore } from 'zustand';
import { Loader } from '../common';
import { AppRoutes } from './Routes';
import { useQueryCache, useUserProfile } from './hooks';
import { useUserWallet } from './hooks/useUserWallet';

export const Startup: React.FC = () => {
  useReconnectLastConnector();
  const { loaded: userProfileLoaded } = useUserProfile();
  useUserWallet();

  const ensureAnalytics = useStore(trackEventStore, (s) => s.ensureAnalytics);

  useEffect(() => {
    ensureAnalytics();
  }, []);

  if (!userProfileLoaded) return <Loader />;
  return <Content />;
};

export const Content: React.FC = () => {
  useQueryCache();
  return (
    <>
      <Helmet>
        <title>Aspen</title> {/* Default title */}
      </Helmet>
      <AppRoutes />
    </>
  );
};
