import { formErrorAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleFunction, SystemStyleFunction } from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/form-error.ts

const baseStyleText: SystemStyleFunction = (props) => {
  return {
    fontSize: 'md',
    color: mode('red.500', 'red.500')(props),
  };
};

const baseStyleIcon: SystemStyleFunction = (props) => {
  return {
    color: mode('red.500', 'red.500')(props),
  };
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  text: baseStyleText(props),
  icon: baseStyleIcon(props),
});

export const FormError = {
  parts: parts.keys,
  baseStyle,
};
