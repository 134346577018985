import { yupResolver } from '@hookform/resolvers/yup';
import { DefaultValues, FieldValues, useForm as useFormRHF } from 'react-hook-form';
import type { SchemaOf } from 'yup';

type ValidationMode = {
  onBlur: 'onBlur';
  onChange: 'onChange';
  onSubmit: 'onSubmit';
};

type ReValidateMode = keyof ValidationMode;

export const useForm = <T extends FieldValues>(
  schema: SchemaOf<T>,
  defaultValues?: DefaultValues<T>,
  reValidateMode?: ReValidateMode,
) => {
  const methods = useFormRHF<T>({
    resolver: yupResolver<SchemaOf<T>>(schema),
    defaultValues: defaultValues,
    reValidateMode: reValidateMode,
  });
  return methods;
};
