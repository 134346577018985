import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import { UserProfileTabKey, userProfileTabsStore } from './userProfileTabsStore';

type State = {
  isOpen: boolean;
};

type Actions = {
  openDrawer: (tab?: UserProfileTabKey) => void;
  closeDrawer: () => void;
};

// define the initial state
const initialState: State = {
  isOpen: false,
};

export const userProfileDrawerStore = createWithEqualityFn<State & Actions>()(
  (set) => ({
    ...initialState,
    openDrawer: (tab?: UserProfileTabKey) => {
      set({ isOpen: true });
      if (tab) userProfileTabsStore.getState().setActiveTabKey(tab);
    },
    closeDrawer: () => {
      set({ isOpen: false });
      userProfileTabsStore.getState().setActiveTabKey('wallets');
    },
  }),
  shallow,
);
