import { useTranslation } from '@/featureflags';
import { toast } from '@/utils/toast';
import { useClipboard as useClipboardHook } from 'use-clipboard-copy';

export const useClipboard = () => {
  const { t } = useTranslation(['common']);
  return useClipboardHook({
    onSuccess() {
      toast({
        status: 'info',
        description: t('common:common.copiedToClipboard'),
      });
    },
  });
};
