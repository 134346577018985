import type { SelectOption } from '@/components/form';
import { Webhook } from '@monax/aspen-spec';
import { UUIDFromString } from '@monaxlabs/aspen-sdk';

type WebhookOption = SelectOption<UUIDFromString>;

export const useWebhookOptions = (webhooks: Webhook[]): WebhookOption[] => {
  return webhooks.map((webhook) => ({
    value: webhook.id,
    label: `${webhook.name ?? webhook.id}${!webhook.isActive ? ' (inactive)' : ''}`,
  }));
};
