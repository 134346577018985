import { config } from '@/config';
import IPFSGatewayTools from '@pinata/ipfs-gateway-tools/dist/browser';
import isIpfs from 'is-ipfs';
import { useCallback } from 'react';

const gatewayTools = new IPFSGatewayTools();

export const useIPFS = () => {
  const containsCID = useCallback((hashOrUrl: string) => {
    return gatewayTools.containsCID(hashOrUrl).containsCid;
  }, []);
  const resolveIpfsUrl = useCallback((hashOrUrl: string) => {
    if (isIpfs.cid(hashOrUrl)) {
      hashOrUrl = `ipfs://${hashOrUrl}`;
    }
    return gatewayTools.convertToDesiredGateway(hashOrUrl, config.integrations.ipfsGatewayUrl);
  }, []);
  return {
    containsCID,
    resolveIpfsUrl,
  };
};
