import { config } from '@/config';
import { accessTokenStore } from '@/store/user';
import { pathMeta } from '@monax/aspen-spec';
import { ApiClient } from '@monax/xylem/dist/api';
import { useCallback } from 'react';
import { useStore } from 'zustand';

// TODO drop this and use `getAspenApi` instead
export const useAspenApiClient = () => {
  const accessToken = useStore(accessTokenStore, (s) => s.token);

  const getApiClient = useCallback(() => {
    return new ApiClient(pathMeta, { baseUrl: config.apiUrl, token: accessToken });
  }, [accessToken]);

  return { getApiClient };
};
