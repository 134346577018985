import { Input, useForm } from '@/components/form';
import { FormConfigContext } from '@/components/form/context';
import { OrganizationIcon } from '@/components/icons/organizations/OrganizationIcon';
import { handleError } from '@/hooks';
import { userOrganizationsStore } from '@/store/user/organizations';
import { trackSegmentEvent } from '@/utils/segment';
import { captureException } from '@/utils/sentry';
import { toast } from '@/utils/toast';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useStore } from 'zustand';
import { CreateOrganization, getCreateOrganizationFormSchema } from './validation';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const CreateOrganizationDialog: React.FC<Props> = ({ isOpen, onClose }) => {
  const { createOrganization } = useStore(userOrganizationsStore, (s) => ({
    createOrganization: s.createOrganization,
  }));

  const methods = useForm<CreateOrganization>(getCreateOrganizationFormSchema(), { name: '' });

  const onSubmit = async (data: CreateOrganization) => {
    try {
      const organization = await createOrganization(data.name);

      toast({
        status: 'success',
        title: 'Organization successfully created',
      });

      trackSegmentEvent({
        name: 'organizations-create-organization',
        properties: {
          savedStatus: true,
          userType: 'creator',
          organization,
        },
      });

      methods.reset();
      onClose();
    } catch (error) {
      trackSegmentEvent({
        name: 'organizations-create-organization',
        properties: {
          savedStatus: false,
          userType: 'creator',
        },
      });
      captureException(error, {
        level: 'fatal',
        extra: { message: 'Failed to create organization' },
      });
      handleError(error, 'Failed to create organization');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="xl" p={7}>
        <ModalCloseButton />
        <VStack py={6}>
          <OrganizationIcon height="8" width="8" />
          <Text color="text" fontWeight="bold" fontSize="xl">
            Create New Organization
          </Text>
          <Text color="textSubtle" fontWeight="medium" fontSize="lg">
            Choose the name of the new organization you want to create.
          </Text>
        </VStack>
        <FormConfigContext.Provider
          value={{
            inputSize: 'sm',
          }}
        >
          <FormProvider {...methods}>
            <ModalBody p={0}>
              <form id="create-organization-form" onSubmit={methods.handleSubmit(onSubmit)}>
                <Input<CreateOrganization> name="name" placeholder="Enter organization name" label="Name" />
              </form>
            </ModalBody>
          </FormProvider>
        </FormConfigContext.Provider>

        <ModalFooter p={0}>
          <Button
            w="full"
            variant="primary"
            type="submit"
            form="create-organization-form"
            isLoading={methods.formState.isSubmitting || methods.formState.isValidating}
          >
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
