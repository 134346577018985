import { Input as InputCui, InputGroup, InputLeftAddon, InputRightAddon, useColorModeValue } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';
import { FormItem } from '..';
import { useInputProps } from '../context';
import type { InputSize } from '../types';

type Props<T extends FieldValues> = {
  name: Path<T>;
  label?: string | ReactNode;
  tag?: string;
  placeholder?: string;
  type?: 'number' | 'text' | 'password';
  size?: InputSize;
  tooltip?: string;
  isDisabled?: boolean;
  min?: number;
  max?: number;
  leftElement?: ReactNode;
  rightElement?: ReactNode;
};

function Input<T extends FieldValues>({
  name,
  label,
  tag,
  placeholder,
  type,
  size,
  tooltip,
  isDisabled,
  min,
  max,
  leftElement,
  rightElement,
}: Props<T>) {
  const { register } = useFormContext<T>();
  const hookProps = register(name);
  const inputProps = useInputProps({ name, size, isDisabled });
  const bg = useColorModeValue('gray.200', 'whiteAlpha.200');

  return (
    <FormItem<T> name={name} label={label} tag={tag} tooltip={tooltip}>
      <InputGroup>
        {leftElement && <InputLeftAddon marginInlineEnd={0}>{leftElement}</InputLeftAddon>}
        <InputCui id={name} placeholder={placeholder} type={type} min={min} max={max} {...inputProps} {...hookProps} />
        {rightElement && (
          <InputRightAddon bg={bg} marginInlineStart={0}>
            {rightElement}
          </InputRightAddon>
        )}
      </InputGroup>
    </FormItem>
  );
}

export { Input };
