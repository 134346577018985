const LS_KEYS = {
  wagmiWallet: 'wagmi.wallet',
} as const;

export const getLastConnectorId = () => {
  return localStorage.getItem(LS_KEYS.wagmiWallet);
};

// Example of setter for a local storage variable, we don't
// need it because the wagmi package is setting this variable
// export const setLastConnectorId = (wagmiWallet: string): void => {
//   localStorage.setItem(LS_KEYS.wagmiWallet, wagmiWallet);
// };
