import { Box, ContainerProps, Flex } from '@chakra-ui/react';
import React, { useRef } from 'react';
import { Container } from '../Container';
import { PageHeader } from './PageHeader';

type Props = {
  header?: JSX.Element;
  maxW?: ContainerProps['maxW'];
};

export const PageLayout: React.FC<React.PropsWithChildren<Props>> = ({ header, maxW = 'container.xl', children }) => {
  const pageLayoutRef = useRef<HTMLDivElement>(null);
  const headerElement = header ? React.cloneElement(header, { pageLayoutRef }) : undefined;

  return (
    <Flex id="pageRoot" h="full" direction="column" overflowY="auto" ref={pageLayoutRef}>
      {headerElement ? headerElement : <PageHeader pageLayoutRef={pageLayoutRef} />}
      <Box flex={1} h="full">
        <Container h="full" maxW={maxW}>
          {children}
        </Container>
      </Box>
    </Flex>
  );
};
