import { parse } from '@monax/xylem/dist/schema';
import { Address } from '@monax/xylem/dist/types';

export function parseAddress(str: string): Address {
  return parse(Address, str);
}

export function parseAddressSafe(str?: string | null): Address | null {
  if (!str) return null;
  if (Address.decode(str.toLowerCase())._tag === 'Right') return parse(Address, str.toLowerCase());
  return null;
}
