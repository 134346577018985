import { userProfileStore } from '@/store/user';
import { userOrganizationsStore } from '@/store/user/organizations';
import { userSocialLinksStore } from '@/store/user/social-link';
import { addBreadcrumb } from '@/utils/sentry';
import { useError } from '../common/useError';
import { useAuthenticate } from './useAuthenticate';

export const useLoadUserProfile = () => {
  const { handleError } = useError();
  const { signout } = useAuthenticate();

  const clearUserProfile = () => {
    addBreadcrumb({ message: 'clearing user profile' });
    userProfileStore.getState().reset();
  };

  const loadUserProfile = async () => {
    try {
      const profile = await userProfileStore.getState().load();
      userSocialLinksStore.getState().load(profile);
      userOrganizationsStore.getState().load();
      return profile;
    } catch (err) {
      handleError(err, 'Failed to load user profile, clearing access token.', { disableToast: true });
      signout();
    }
  };

  return { loadUserProfile, clearUserProfile };
};
