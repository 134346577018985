import type { Address, ChainId } from '@monax/xylem/dist/types';
import { fetchEnsAddress, fetchEnsName } from '@wagmi/core';
import { parseAddressSafe } from '../address';
import { parseWagmiAddress } from '../wagmi';

type LookupResult<T> = LookupResultSuccess<T> | LookupResultFailure;

type LookupResultSuccess<T> = {
  value: T;
  success: true;
};

type LookupResultFailure = {
  value: string | undefined | null;
  success: false;
};

export const lookupName = async (chainId: ChainId | undefined, account: string): Promise<LookupResult<string>> => {
  if (!chainId) {
    return {
      success: false,
      value: account,
    };
  }

  // First check if this is an address, if not an address just return
  const address = parseAddressSafe(account);
  if (!address)
    return {
      success: false,
      value: account,
    };

  // Try resolve account name
  try {
    const name = await fetchEnsName({
      address: parseWagmiAddress(account),
      chainId: chainId,
    });
    if (name)
      return {
        success: true,
        value: name,
      };
  } catch {
    return {
      success: false,
      value: address,
    };
  }

  return {
    success: false,
    value: address,
  };
};

export const lookupAddress = async (
  chainId: ChainId | undefined,
  account: string | undefined,
): Promise<LookupResult<Address>> => {
  if (!chainId || !account)
    return {
      success: false,
      value: account,
    };

  // First check if this is an address, if so then return it
  let address = parseAddressSafe(account);
  if (address)
    return {
      success: true,
      value: address,
    };

  // Try resolve account name
  try {
    const resolvedAddress = await fetchEnsAddress({
      name: account,
      chainId: chainId,
    });

    // Check if we have a valid address
    address = parseAddressSafe(resolvedAddress);
    if (address)
      return {
        success: true,
        value: address,
      };
  } catch {
    return {
      success: false,
      value: account,
    };
  }

  return {
    success: false,
    value: account,
  };
};
