import { LegacyCard } from '@/components/card';
import { ConstrainedText, ImageWithSkeleton } from '@/components/common';
import { PictureIcon } from '@/components/icons/collection/PictureIcon';
import { CopyIcon } from '@/components/icons/menu/CopyIcon';
import { getCreatorToolsCreateStorefrontPath } from '@/components/routes';
import { useClipboard } from '@/hooks';
import { useNftCollectionsByContract } from '@/hooks/query/nft-collections';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { NftCollection } from '@monax/aspen-spec';
import { Address, ChainId } from '@monax/xylem/dist/types';
import React, { useMemo, useRef, useState } from 'react';
import { FaTwitter } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

type View = 'details' | 'invite';

type Props = {
  chainId: ChainId;
  contractAddress: Address;
  isOpen: boolean;
  onClose: () => void;
};

export const WalletCollectionModal: React.FC<Props> = ({ isOpen, chainId, contractAddress, onClose }) => {
  const [view, setView] = useState<View>('details');
  const { isLoading, data } = useNftCollectionsByContract({ chainId, contractAddress }, isOpen);
  const collection = useMemo(() => {
    return data ? data[0].nftCollection : null;
  }, [data]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setView('details');
        onClose();
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent w="900px" maxW="900px" h="428px" top="15%">
        <ModalCloseButton />
        <ModalBody p={0} borderRadius="lg" overflow="hidden">
          {view === 'details' && <DetailsView isLoading={isLoading} collection={collection} setView={setView} />}
          {view === 'invite' && <InviteView />}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

type CollectionDetailsProps = {
  isLoading: boolean;
  collection: NftCollection | null;
  setView: (view: View) => void;
};

const DetailsView: React.FC<CollectionDetailsProps> = ({ isLoading, collection, setView }) => {
  const navigate = useNavigate();

  return (
    <Box w="full" h="full">
      <ImageWithSkeleton
        queryIsLoading={isLoading}
        src={collection?.bannerUrl ?? ''}
        w="full"
        h="212px"
        borderTopRadius="lg"
      />
      <Box w="full" p={6} position="relative">
        <ImageWithSkeleton
          queryIsLoading={isLoading}
          src={collection?.imageUrl ?? ''}
          position="absolute"
          left={6}
          bottom={6}
          w="230px"
          h="250px"
          borderRadius="lg"
        />
        <Flex flexDir="column" ml="230px" pb={5} px={5} gap={4}>
          <Skeleton h={6} isLoaded={!isLoading}>
            <ConstrainedText noOfLines={1} variant="gradient" fontSize="4xl" fontWeight="bold">
              {collection?.name ?? ''}
            </ConstrainedText>
          </Skeleton>
          <Box bgColor="primaryLight" borderRadius="2xl" px={4} py={3}>
            <Text color="primary" fontSize="md" fontWeight="bold">
              This project hasn’t claimed their storefront yet
            </Text>
          </Box>
          <ButtonGroup>
            <Button
              variant="primary"
              size="xl"
              fontSize="lg"
              onClick={() => {
                const params =
                  collection?.chainId && collection.contractAddress
                    ? `?isClaiming=true&chainId=${collection.chainId}&contractAddress=${collection.contractAddress}`
                    : '';
                navigate(getCreatorToolsCreateStorefrontPath() + params);
              }}
            >
              Claim Storefront
            </Button>
            <Button variant="primary" size="xl" fontSize="lg" onClick={() => setView('invite')}>
              Invite Creator
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </Box>
  );
};

const firstParagraph = `I’ve been using Aspen as a collector to manage my project
memberships and subscriptions and think your project would benefit from setting up a storefront and using their revenue toolkits.`;
const secondParagraph = `Aspen exists to empower creators. The current marketplace structure doesn’t support a creator’s ability to
build a sustainable project. Aspen’s revenue toolkits are designed to bring balance back to the ecosystem.
Their royalty, subscription, minting, and marketing tools provide creators the ability to control how they
make money.`;
const thirdParagraph = `Claiming your storefront only takes a few minutes. Hope to see you there! Find out more here www.aspenft.io`;
const clipboardText = firstParagraph + '\n\n' + secondParagraph + '\n\n' + thirdParagraph;
const twitterText = encodeURIComponent(
  `I’m managing my memberships with Aspen\n\nTheir Storefronts supercharge revenue growth for Creators + set them up w/ subscriptions, royalty repayments and gated utility in minutes.\n\nConnect better with collectors and make sure your revenue is protected!`,
);
const twitterUrl = 'aspenft.io';

const InviteView: React.FC = () => {
  const { copy } = useClipboard();
  const hiddenLinkRef = useRef<HTMLAnchorElement>(null);

  const onCopy = () => {
    copy(clipboardText);
  };

  return (
    <Flex flexDir="column" alignItems="center" gap={2} w="full" h="full" px={8} pt={12} pb={6} overflow="auto">
      <PictureIcon w={8} h={8} aria-label="picture" />
      <Text fontWeight="bold">Invite a Creator</Text>
      <Text fontSize="md" color="textDisabled">
        We made it easy for you. Here’s some info about Aspen you can share with the project
      </Text>
      <LegacyCard variant="nested" my={2} pb={6} fontWeight="medium" color="textSubtle">
        <Text>{firstParagraph}</Text>
        <br />
        <Text>{secondParagraph}</Text>
        <br />
        <Text>{thirdParagraph}</Text>
      </LegacyCard>
      <ButtonGroup>
        <Button variant="primary" size="xl" onClick={() => hiddenLinkRef.current?.click()}>
          <Icon as={FaTwitter} mr={2} />
          Share on Twitter
        </Button>
        <Button variant="outline" size="xl" onClick={onCopy}>
          <CopyIcon boxSize={4} mr={2} />
          Copy to Clipboard
        </Button>
      </ButtonGroup>
      <a
        href={`https://twitter.com/intent/tweet?text=${twitterText}&url=${twitterUrl}`}
        hidden={true}
        target="_blank"
        rel="noreferrer"
        ref={hiddenLinkRef}
      />
    </Flex>
  );
};
