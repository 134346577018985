export const addUrlParams = (url: string, extraParams: ConstructorParameters<typeof URLSearchParams>[0]): string => {
  const [baseUrl, ...rest] = url.split('?');
  const params = new URLSearchParams(rest.join('?'));
  for (const [key, value] of new URLSearchParams(extraParams)) {
    params.set(key, value);
  }
  return `${baseUrl}${getUrlParams(params)}`;
};

export const getUrlParams = (params?: ConstructorParameters<typeof URLSearchParams>[0]): string => {
  const urlParams = new URLSearchParams(params);
  return params ? `?${urlParams.toString()}` : '';
};

export const twitterShareUrl = (text: string, url: string): string => {
  return `https://twitter.com/intent/tweet${getUrlParams({ text, url })}`;
};

export const isUrlParameters = (value?: string) => {
  return !!value?.match(new RegExp(/^(\?)([^=]+)\=([^\&]+)+?(&([^=&\s]+)\=([^\&\s]+))*/, 'g'));
};
