import { config } from '@/config';
import { Address, ChainId, DiscordId, NATIVE_TOKEN, UUIDFromString, isSameAddress } from '@monax/xylem/dist/types';
import { formatDuration } from 'date-fns';
import { ethers } from 'ethers';
import type { TFunction } from 'react-i18next';
import * as yup from 'yup';
import type { RequiredStringSchema } from 'yup/lib/string';
import type { AnyObject } from 'yup/lib/types';
import { parseAddressSafe } from './address';
import { lookupAddress } from './chain';
import { isERC20Contract } from './contracts';
import { OrderDurationForm } from './form';

export const MIN_ERC20_AMOUNT = 0.00001;

export const getAddressOrEnsSchema = (chainId: ChainId): RequiredStringSchema<string | undefined, AnyObject> => {
  return yup
    .string()
    .required()
    .test('checkValidNameOrAddress', 'Invalid Address or Name', async (value) => {
      // Check if valid Address
      const address = parseAddressSafe(value);
      if (address) return true;
      // If not address check if can lookup address
      const result = await lookupAddress(chainId, value);
      return result.success;
    });
};

export const getChainIdSchema = () => {
  return yup
    .mixed<ChainId>()
    .required()
    .test('checkValidChainId', 'Invalid Chain', (value) => {
      if (value === undefined) {
        return false;
      }
      const valid = config.contracts.supportedChainIds.indexOf(value) !== -1;
      return valid;
    });
};

export const getNullableNumberSchema = () => {
  return yup
    .number()
    .nullable()
    .transform((_, val) => {
      if (val === '' || val === null || val == undefined) return null;
      return parseFloat(val);
    });
};

export const getUUIDFromStringSchema = () => {
  return yup.mixed<UUIDFromString>().test('isValidUUID', 'Invalid UUID', (value) => {
    if (value === null) return true;
    return UUIDFromString.is(value);
  });
};

export const getNullableUUIDFromStringSchema = () => {
  return yup
    .mixed<UUIDFromString>()
    .nullable()
    .test('isValidUUID', 'Invalid UUID', (value) => {
      if (value === null || value === undefined) return true;
      return UUIDFromString.is(value);
    });
};

export const getOptionalAddressSchema = (): yup.SchemaOf<Address | undefined> => {
  return yup.mixed().test('isValidAddress', 'Invalid Address', (value) => {
    return !value || Address.is(value);
  }) as yup.SchemaOf<Address | undefined>;
};

export const getAddressSchema = (): yup.SchemaOf<Address> => {
  return yup
    .mixed()
    .required()
    .test('isValidAddress', 'Invalid Address', (value) => {
      return Address.is(value);
    }) as yup.SchemaOf<Address>;
};

export const getDurationSchema = () => {
  return yup.mixed<Duration>().test('is-duration', 'Invalid duration', (value) => {
    try {
      if (!value) return false;
      formatDuration(value);
      return true;
    } catch {
      return false;
    }
  });
};

export const getDiscordIdSchema = (): yup.SchemaOf<DiscordId> => {
  return yup
    .mixed()
    .required()
    .test('isValidDiscordId', 'Invalid Discord Id', (value) => {
      return DiscordId.is(value);
    }) as yup.SchemaOf<DiscordId>;
};

export const getConstSchema = <T extends string = string>(value: T): yup.SchemaOf<T> => {
  return yup.string().required().oneOf([value]) as unknown as yup.SchemaOf<T>;
};

export const getERC20CurrencySchema = (chainId: ChainId) => {
  return yup
    .mixed<Address>()
    .test('isEmptyOrValidAddress', 'Invalid Address', (value) => {
      return !value || Address.is(value);
    })
    .test('isEmptyOrERC20Token', 'Invalid ERC20 token', async (value) => {
      return !value || isSameAddress(value, NATIVE_TOKEN) || (await isERC20Contract(chainId, value));
    });
};

export const getEthUnitsStringSchema = (currencyDecimals: number) => {
  return yup.string().test('checkValidEth', 'Invalid ETH amount', (value) => {
    try {
      ethers.utils.parseUnits(`${value}`, currencyDecimals);
      return true;
    } catch {}

    return false;
  });
};

type ObjectWithProperty<K extends string = string> = { [key in K]: unknown };

function checkIfObjectHasKey<K extends string = string>(propertyName: K) {
  return (value: unknown): value is ObjectWithProperty<K> => {
    if (typeof value !== 'object' || value === null) return false;
    return value.hasOwnProperty(propertyName);
  };
}

export function checkUniqueArrayObjectsProperty<K extends string = string>(
  propertyName: K,
): (values: unknown[] | undefined) => boolean {
  return (values: unknown[] | undefined) => {
    if (!values || !Array.isArray(values)) return true;

    const unique = new Set(values.filter(checkIfObjectHasKey(propertyName)).map((v) => `${v[propertyName]}`));
    return values.length === unique.size;
  };
}

export const getOrderDurationFormSchema = (t: TFunction<'validation'[]>) => {
  const schema: yup.SchemaOf<OrderDurationForm> = yup.object().shape({
    hours: getOrderDurationNumberSchema(t),
    days: getOrderDurationNumberSchema(t),
    months: getOrderDurationNumberSchema(t).when(['hours', 'days'], {
      is: (hours: number, days: number) => {
        if (hours === 0 && days === 0) return true;
        return false;
      },
      then: yup.number().min(1, t('validation:number.min', { min: 1 })),
    }),
  });
  return schema;
};

export const getOrderDurationNumberSchema = (t: TFunction<'validation'[]>) => {
  const schema: yup.SchemaOf<number> = yup
    .number()
    .typeError(t('validation:mixed.enterAValidAmount'))
    .required()
    .min(0, t('validation:number.min', { min: 0 }))
    .max(1000000, t('validation:number.max', { max: 1000000 }));
  return schema;
};

export const MIN_NATIVE_TOKEN_AMOUNT = 0.00001;

export const getNativeTokenPriceSchema = (t: TFunction<'validation'[]>) => {
  return yup
    .number()
    .typeError(t('validation:mixed.enterAValidAmount'))
    .required()
    .min(MIN_NATIVE_TOKEN_AMOUNT, t('validation:number.min', { min: MIN_NATIVE_TOKEN_AMOUNT }))
    .max(1000000, t('validation:number.max', { max: 1000000 }));
};

export const getEnabledFields = <T extends string>(fields: Record<T, boolean>): T[] =>
  Object.keys(fields).filter((key) => fields[key as T]) as T[];
