import { getIdentityApi } from '@/utils/api-clients';
import { addBreadcrumb } from '@/utils/sentry';
import { UserProfileResponse, UserWalletResponse, WalletConnectorType } from '@monax/aspen-identity-sdk';
import { parse } from '@monax/xylem/dist/schema';
import { Address, UUIDFromString, isSameAddress } from '@monax/xylem/dist/types';
import { persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

type State = {
  userId: UUIDFromString | undefined;
  displayName: string | undefined;
  email: string | undefined;
  emailVerified: boolean;
  wallets: UserWalletResponse[];
  accountAddresses: Address[];
  selectedWalletAddress: Address | undefined;
  selectedWallet: UserWalletResponse | undefined;
};

type Actions = {
  load: () => Promise<UserProfileResponse>;
  updateEmail: (email: string, emailVerificationUrl: string) => Promise<void>;
  deleteEmail: () => Promise<void>;
  updateDisplayName: (displayName: string) => Promise<void>;
  resendVerificationEmail: (emailVerificationUrl: string) => Promise<void>;
  updateWallets: (wallets: UserWalletResponse[]) => Promise<void>;
  removeWallet: (walletId: string) => Promise<void>;
  renameWallet: (walletId: string, name: string, walletConnectorType: WalletConnectorType) => Promise<void>;
  verifyEmail: (email: string, emailToken: string) => Promise<void>;
  switchSelectedWalletAddress: (address: Address | undefined) => void;
  findWallet: (address: string | undefined) => UserWalletResponse | undefined;
  reset: () => void;
};

// define the initial state
const initialState: State = {
  userId: undefined,
  displayName: undefined,
  email: undefined,
  emailVerified: false,
  wallets: [],
  accountAddresses: [],
  selectedWalletAddress: undefined,
  selectedWallet: undefined,
};

export const userProfileStore = createWithEqualityFn<State & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,
      load: async () => {
        addBreadcrumb({ message: 'loading user profile' });

        const profile = await getIdentityApi().ProfileService.getSignedInUserProfile();

        if (!profile) throw new Error('No user profile found');

        const userId = parse(UUIDFromString, profile.id);
        const displayName = profile.displayName ?? '';
        const email = profile.email ?? '';
        const emailVerified = profile.emailVerified;
        const wallets = profile.wallets;

        const accountAddresses = wallets.map((a) => parse(Address, a.address));

        set({
          userId,
          displayName,
          email,
          emailVerified,
          wallets,
          accountAddresses,
        });

        // Make sure the selected account actually exists in the list of user account
        const selectedWalletAddress = get().selectedWalletAddress;
        get().switchSelectedWalletAddress(selectedWalletAddress);

        addBreadcrumb({
          message: 'user profile loaded',
          data: {
            userId,
            displayName,
            email,
            emailVerified,
            wallets,
            accountAddresses,
            selectedWalletAddress,
          },
        });

        return profile;
      },
      resendVerificationEmail: async (emailVerificationUrl: string) => {
        await getIdentityApi().ProfileService.resendVerificationEmail({
          requestBody: {
            email: get().email ?? '',
            emailVerificationUrl,
          },
        });
      },
      verifyEmail: async (email: string, emailToken: string) => {
        await getIdentityApi().ProfileService.verifyUserEmail({
          requestBody: { email: email, token: emailToken, sendWelcomeEmail: true },
        });
        await get().load();
      },
      updateEmail: async (email: string, emailVerificationUrl: string) => {
        await getIdentityApi().ProfileService.updateSignedInUserEmail({
          requestBody: {
            email,
            emailVerificationUrl,
          },
        });
        await get().load();
      },
      deleteEmail: async () => {
        await getIdentityApi().ProfileService.deleteSignedInUserEmail();
        await get().load();
      },
      updateDisplayName: async (displayName: string) => {
        await getIdentityApi().ProfileService.updateSignedInUserDisplayName({ requestBody: { displayName } });
        await get().load();
      },
      updateWallets: async (wallets: UserWalletResponse[]) => {
        await getIdentityApi().ProfileService.updateAllSignedInUserWallets({
          requestBody: wallets.map(({ id, walletConnectorType, name }) => ({ id, walletConnectorType, name })),
        });
        await get().load();
      },
      removeWallet: async (walletId: string) => {
        await getIdentityApi().ProfileService.deleteSignedInUserWallet({ id: walletId });
        await get().load();
      },
      renameWallet: async (walletId: string, name: string, walletConnectorType: WalletConnectorType) => {
        await getIdentityApi().ProfileService.updateSignedInUserWallet({
          id: walletId,
          requestBody: { name, walletConnectorType },
        });
        await get().load();
      },
      switchSelectedWalletAddress: (address: Address | undefined) => {
        const wallets = get().wallets;
        const wallet = wallets.find((a) => a.address === address) ?? wallets[0];
        set({ selectedWalletAddress: parse(Address, wallet.address), selectedWallet: wallet });
      },
      findWallet: (address: string | undefined) => {
        return get().wallets.find((a) => isSameAddress(a.address, address ?? undefined));
      },
      reset: () => {
        set(initialState);
      },
    }),
    {
      name: 'user-profile-store',
    },
  ),
  shallow,
);

export const mustGetUserId = (): UUIDFromString => {
  const userId = userProfileStore.getState().userId;
  if (!userId) throw new Error('User ID is not available');
  return userId;
};
