import { LegacyCard } from '@/components/card';
import { Loader } from '@/components/common';
import { FormToggleButton, FormTooltip } from '@/components/form';
import { useInputProps } from '@/components/form/context';
import { Box, BoxProps, HStack, Spacer, Text } from '@chakra-ui/react';
import React from 'react';
import { FieldValues, Path, PathValue, useFormContext, useWatch } from 'react-hook-form';

type Props<T extends FieldValues> = BoxProps & {
  name: Path<T>;
  label: string;
  tag?: string;
  tooltip?: string;
  isDisabled?: boolean;
  variant?: 'default' | 'gradient';
  dependencies?: Path<T>[];
  isLoading?: boolean;
  defaultToggleValues?: { on: PathValue<T, Path<T>>; off: PathValue<T, Path<T>> }; // TODO: this doesn't enforce types properly, we would need a second generic
};

function FormToggleCard<T extends FieldValues>({
  name,
  label,
  tag,
  tooltip,
  isDisabled,
  variant,
  dependencies,
  children,
  isLoading,
  defaultToggleValues,
  ...boxProps
}: React.PropsWithChildren<Props<T>>) {
  const { isDisabled: isReallyDisabled } = useInputProps({ name, isDisabled });

  const { setValue } = useFormContext<T>();

  const value = useWatch({ name });

  return (
    <LegacyCard variant="nested" py={2.5} mb={4} w="full" {...boxProps}>
      <HStack w="full">
        <Text fontWeight="bold" fontSize="lg">
          {label}
        </Text>
        {tag && <Text fontSize="lg">{tag}</Text>}
        <FormTooltip tooltip={tooltip} />
        <Spacer />
        <LegacyCard variant="nested" p={0.5}>
          {isLoading && <Loader />}
          {!isLoading && (
            <HStack>
              <FormToggleButton
                onClick={() => {
                  const val = defaultToggleValues ? defaultToggleValues.on : (true as PathValue<T, Path<T>>);
                  setValue(name, val, { shouldDirty: true });
                }}
                isActive={!!value}
                isDisabled={isReallyDisabled}
                variant={variant}
              >
                Yes
              </FormToggleButton>
              <FormToggleButton
                onClick={() => {
                  const val = defaultToggleValues ? defaultToggleValues.off : (false as PathValue<T, Path<T>>);
                  setValue(name, val, { shouldDirty: true });
                  dependencies?.forEach((dep) =>
                    setValue(dep, undefined as PathValue<T, Path<T>>, {
                      shouldDirty: true,
                    }),
                  );
                }}
                isActive={!value}
                isDisabled={isReallyDisabled}
              >
                No
              </FormToggleButton>
            </HStack>
          )}
        </LegacyCard>
      </HStack>
      {value && children ? <Box pt={2}>{children}</Box> : undefined}
    </LegacyCard>
  );
}

export { FormToggleCard };
