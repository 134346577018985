import { accordionAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleFunction, PartsStyleObject, SystemStyleFunction } from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';
import { semanticTokens } from '../colors';

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/accordion.ts

const baseStyleContainer: SystemStyleFunction = () => {
  return {
    backgroundColor: 'cardBackground',
    position: 'relative',
    borderRadius: 'md',
    border: 'none',
    mb: 3,
  };
};

const baseStyleIcon: SystemStyleFunction = (props) => {
  return {
    fontSize: '1.5em',
    color: mode('gray.600', 'gray.600')(props),
  };
};

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  sm: {
    container: {
      fontSize: 'md',
    },

    button: {
      h: 9,
      fontSize: 'md',
      fontWeight: 'medium',
    },
  },
  md: {
    container: {
      fontSize: 'md',
    },
    button: {
      h: 12,
      fontSize: 'md',
      fontWeight: 'medium',
    },
  },
  lg: {
    container: {
      fontSize: 'lg',
    },
    button: {
      h: 16,
      fontSize: 'lg',
      fontWeight: 'medium',
    },
  },
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  container: baseStyleContainer(props),
  icon: baseStyleIcon(props),
});

const nestedStyleContainer: SystemStyleFunction = () => {
  return {
    backgroundColor: 'cardBackgroundNested',
  };
};

const nestedVariant: PartsStyleFunction<typeof parts> = (props) => ({
  container: nestedStyleContainer(props),
});

const outlineVariant: PartsStyleFunction<typeof parts> = (props) => ({
  container: {
    bg: 'transparent',
    border: `1px solid ${mode('blackAlpha.400', 'whiteAlpha.300')(props)}`,
    boxShadow: 'none',
  },
});

const gradientVariant: PartsStyleFunction<typeof parts> = (props) => ({
  container: {
    position: 'relative',
    bg: 'transparent',
    boxShadow: 'none',
    borderRadius: 'full',
    textDecoration: 'none',
    _before: {
      content: `""`,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: 'full',
      border: '2px solid transparent',
      background: `${mode(
        semanticTokens.primaryGradient.default,
        semanticTokens.primaryGradient._dark,
      )(props)} border-box`,
      WebkitMask: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'destination-out',
      maskComposite: 'exclude',
    },
  },
  panel: {
    zIndex: 9999999,
    position: 'relative',
    bg: 'transparent',
    boxShadow: 'none',
    borderRadius: '2xl',
    textDecoration: 'none',
    _before: {
      content: `""`,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: '2xl',
      border: '2px solid transparent',
      borderColor: undefined,
      background: `${mode(
        semanticTokens.primaryGradient.default,
        semanticTokens.primaryGradient._dark,
      )(props)} border-box`,
      WebkitMask: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'destination-out',
      maskComposite: 'exclude',
    },
  },
});

const defaultProps = {
  size: 'md',
};

export const Accordion = {
  parts: parts.keys,
  sizes,
  defaultProps,
  baseStyle,
  variants: {
    nested: nestedVariant,
    outline: outlineVariant,
    gradient: gradientVariant,
  },
};
