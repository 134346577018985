import { WalletConnectorType } from '@monax/aspen-identity-sdk';
import { useCallback } from 'react';

export const useWalletName = () => {
  const getWalletName = useCallback((connectorType: WalletConnectorType): string => {
    switch (connectorType) {
      case WalletConnectorType.INJECTED:
        return 'Injected Wallet';
      case WalletConnectorType.COINBASE_WALLET:
        return 'Coinbase Wallet';
      case WalletConnectorType.META_MASK:
        return 'MetaMask Wallet';
      case WalletConnectorType.WALLET_CONNECT:
        return 'Wallet Connect';
      case WalletConnectorType.MAGIC_LINK:
        return 'Email Wallet';
    }
  }, []);

  return { getWalletName };
};
