import { cardAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/styled-system';
import { _defaultLinearGradient } from '../colors';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const $padding = cssVar('card-padding');
const $radius = cssVar('card-radius');
const $border = cssVar('card-border-width', '0');
const $borderColor = cssVar('card-border-color');

// REF: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/card.ts

const sizes = {
  sm: definePartsStyle({
    container: {
      [$radius.variable]: 'radii.base',
      [$padding.variable]: 'space.3',
    },
  }),
  md: definePartsStyle({
    container: {
      [$radius.variable]: 'radii.lg',
      [$padding.variable]: 'space.6',
    },
  }),
  lg: definePartsStyle({
    container: {
      [$radius.variable]: 'radii.xl',
      [$padding.variable]: 'space.8',
    },
  }),
};

const variants = {
  outline: definePartsStyle({
    container: {
      [$border.variable]: '1px',
      [$borderColor.variable]: 'colors.chakra-border-color',
    },
  }),
  dashed: definePartsStyle({
    container: {
      [$border.variable]: '1px',
      [$borderColor.variable]: 'colors.chakra-border-color',
      borderStyle: 'dashed',
    },
  }),
  gradient: definePartsStyle({
    container: {
      // Gradient borders with radius are not supported, so this is a workaround base off the following:
      // https://dev.to/afif/border-with-gradient-and-radius-387f
      // NOTE: the border radius is harded coded to 16px, so it currently doesnt respect the sizes defined above
      _before: {
        content: '""',
        position: 'absolute',
        inset: 0,
        'border-radius': '16px',
        border: `1px solid transparent`,
        background: `${_defaultLinearGradient} border-box`,
        '-webkit-mask': 'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
        '-webkit-mask-composite': 'xor',
        'mask-composite': 'exclude',
      },
    },
  }),
};

export const Card = defineMultiStyleConfig({
  variants,
  sizes,
  defaultProps: {
    variant: 'outline',
    size: 'md',
  },
});
