import { config } from '@/config';
import { accessTokenStore } from '@/store/user';
import {
  ApplicationsService,
  OpenAPI,
  ProfileService,
  RolesService,
  UsersService,
  WalletService,
} from '@monax/aspen-identity-sdk';

export type IdentityApiClient = {
  ApplicationsService: typeof ApplicationsService;
  ProfileService: typeof ProfileService;
  RolesService: typeof RolesService;
  UsersService: typeof UsersService;
  WalletService: typeof WalletService;
};

export const getIdentityApi = (): IdentityApiClient => {
  OpenAPI.BASE = config.identityApiUrl;
  OpenAPI.TOKEN = (accessTokenStore.getState().token || '').replace('Bearer ', '');

  return { ApplicationsService, ProfileService, RolesService, UsersService, WalletService };
};
