import { ColorMode, extendTheme, ThemeConfig } from '@chakra-ui/react';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/montserrat/100.css';
import '@fontsource/montserrat/200.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/900.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/900-italic.css';
import { colors, semanticTokens } from './colors';
import { Card } from './components';
import {
  Accordion,
  Alert,
  Avatar,
  Badge,
  Button,
  CalendarComponents,
  Checkbox,
  Divider,
  Drawer,
  FormError,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  LegacyCard,
  Link,
  Menu,
  Modal,
  NumberInput,
  Popover,
  Progress,
  Spinner,
  Switch,
  Table,
  Tabs,
  Text,
  Textarea,
} from './legacy-components';
import { Skeleton } from './legacy-components/Skeleton';

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const components = {
  Card,
  // Legacy components
  Button,
  Drawer,
  Link,
  Spinner,
  Switch,
  Menu,
  Textarea,
  Divider,
  Heading,
  Modal,
  Badge,
  Table,
  Accordion,
  Popover,
  Progress,
  FormLabel,
  Alert,
  Avatar,
  Icon,
  Image,
  Input,
  NumberInput,
  Checkbox,
  LegacyCard,
  Tabs,
  FormError,
  Text,
  Skeleton,
  ...CalendarComponents,
};

export const themeBase = {
  config: config,
  breakpoints: {
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  },
  fonts: {
    body: 'Inter, Montserrat',
    heading: 'Poppins, Montserrat',
  },
  fontSizes: {
    xs: '0.5rem', // 8px
    sm: '0.625rem', // 10px
    md: '0.75rem', // 12px
    lg: '0.875rem', // 14px
    xl: '1rem', // 16px
    '2xl': '1.125rem', // 18px
    '3xl': '1.25rem', // 20px
    '4xl': '1.375rem', // 22px
    '5xl': '1.75rem', // 28px
    '6xl': '2rem', // 32px
    '7xl': '3rem', // 48px
    '8xl': '4rem', // 64px
    '9xl': '4.5rem', // 72px
    '10xl': '5.625rem', // 90px
  },
  // TODO[dvn]: migrate codebase to new design system font sizes
  // source: https://www.figma.com/file/kL1rldrUNgEYwBxSo4WLr3/%F0%9F%8C%B2-Design-System-(Chakra-UI-Figma-Kit)?node-id=1%3A1273&mode=dev
  // fontSizes: {
  //   xs: '0.75rem', // 12px
  //   sm: '0.875rem', // 14px
  //   md: '1rem', // 16px
  //   lg: '1.125rem', // 18px
  //   xl: '1.25rem', // 20px
  //   '2xl': '1.5rem', // 24px
  //   '3xl': '1.875rem', // 30px
  //   '4xl': '2.25rem', // 36px
  //   '5xl': '3rem', // 48px
  //   '6xl': '3.75rem', // 60px
  // },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  radii: {
    none: '0',
    sm: '0.4rem', // 6.4px
    base: '0.625rem', // 10px
    md: '0.75rem', // 12px
    lg: '1rem', // 16
    xl: '1.125rem', // 18px
    '2xl': '1.5rem', // 24px
    '3xl': '2rem', // 32px
    '4xl': '2.5rem', //40px
    full: '9999px',
  },
  styles: {
    global: ({ colorMode }: { colorMode: ColorMode }) => ({
      'html, body': {
        color: colorMode === 'dark' ? 'white' : 'black',
        backgroundColor: colorMode === 'dark' ? 'black' : 'white',
      },
    }),
  },
  colors: colors,
  semanticTokens: {
    colors: semanticTokens,
  },
  textStyles: {
    lg_LineHeight: {
      lineHeight: '0.875rem', // 14px
    },
    xl_LineHeight: {
      lineHeight: '1.5rem', // 24px
    },
  },
  // for example used when setting input height (h=12)
  space: {
    px: '1px',
    0.5: '0.125rem', // 2px
    1: '0.25rem', // 4px
    1.5: '0.375rem', // 6px
    2: '0.5rem', // 8px
    2.5: '0.625rem', // 10px
    3: '0.75rem', // 12px
    3.5: '0.875rem', // 14px
    4: '1rem', // 16px
    5: '1.25rem', // 20px
    6: '1.5rem', // 24px
    7: '1.75rem', // 28px
    8: '2rem', // 32px
    9: '2.25rem', // 36px
    10: '2.5rem', // 40px
    12: '3rem', // 48px
    14: '3.5rem', // 56px
    16: '4rem', // 64px
    20: '5rem', // 80px
    24: '6rem', // 96px
    28: '7rem', // 112px
    32: '8rem', // 128px
    36: '9rem', // 144px
    40: '10rem', // 160px
    44: '11rem', // 176px
    48: '12rem', // 192px
    52: '13rem', // 208px
    56: '14rem', // 224px
    60: '15rem', // 240px
    64: '16rem', // 256px
    72: '18rem', // 288px
    80: '20rem', // 320px
    96: '24rem', // 384px
  },
  components,
};

export const theme = extendTheme(themeBase);
