import type { ComponentMultiStyleConfig } from '@chakra-ui/react';

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/modal.ts

const parts = ['overlay', 'dialogContainer', 'dialog', 'header', 'closeButton', 'body', 'footer'];

export const Modal: ComponentMultiStyleConfig = {
  parts,
  baseStyle: ({ colorMode }) => ({
    dialog: {
      bg: colorMode === 'dark' ? 'black' : 'white',
    },
  }),
};
