import { progressAnatomy as parts } from '@chakra-ui/anatomy';
import { PartsStyleFunction } from '@chakra-ui/react';

const gradientVariant: PartsStyleFunction<typeof parts> = () => ({
  filledTrack: { background: 'primaryGradient' },
});

export const Progress = {
  parts: parts.keys,
  variants: {
    gradient: gradientVariant,
  },
};
