import { useWalletName } from '@/hooks';
import { parseAddress } from '@/utils/address';
import { MagicSupportedChain, isMagicSupportedChain } from '@/utils/chain';
import { addBreadcrumb } from '@/utils/sentry';
import { toast } from '@/utils/toast';
import { wagmiConnectorIdToWalletConnectorType } from '@/utils/wagmi';
import { WalletConnectorType } from '@monax/aspen-identity-sdk';
import { parse } from '@monax/xylem/dist/schema';
import { Address, Chain, ChainId, ChainNames } from '@monax/xylem/dist/types';
import { Signer } from 'ethers';
import React, { createContext, useContext } from 'react';
import {
  ConnectorAlreadyConnectedError,
  useAccount,
  useConnect,
  useDisconnect,
  useNetwork,
  useProvider,
  useSigner,
} from 'wagmi';

type Web3Ethers = {
  account: ReturnType<typeof useAccount>;
  connect: ReturnType<typeof useConnect>;
  disconnect: ReturnType<typeof useDisconnect>;
  network: ReturnType<typeof useNetwork>;
  signer: ReturnType<typeof useSigner>;
  provider: ReturnType<typeof useProvider>;
  // Shortcuts
  accountAddress?: Address;
  chainId?: ChainId;
  requireSigner: () => Signer;
};

// Defaults
const Web3EthersContext = createContext<Web3Ethers>({
  account: {} as ReturnType<typeof useAccount>,
  connect: {} as ReturnType<typeof useConnect>,
  disconnect: {} as ReturnType<typeof useDisconnect>,
  network: {} as ReturnType<typeof useNetwork>,
  signer: {} as ReturnType<typeof useSigner>,
  provider: {} as ReturnType<typeof useProvider>,
  requireSigner: () => {
    throw new Error(`not implemented`);
  },
});

export type Provider = ReturnType<typeof useProvider>;

export const useWeb3EthersContext = (): Web3Ethers => useContext(Web3EthersContext);

export const Web3EthersProvider = ({ children }: React.PropsWithChildren) => {
  const { getWalletName } = useWalletName();

  const account = useAccount();
  const connect = useConnect({
    onMutate: ({ connector, chainId }) => {
      const connectorType = wagmiConnectorIdToWalletConnectorType(connector.id);
      if (connectorType === WalletConnectorType.MAGIC_LINK && chainId && !isMagicSupportedChain(chainId)) {
        const supportedChainsString = Object.values(Chain)
          .filter((id) => isMagicSupportedChain(id))
          .map((id) => ChainNames[id as MagicSupportedChain])
          .join(', ');

        throw new Error(
          `Unsupported chain for ${getWalletName(connectorType)}. Supported networks: ${supportedChainsString}.`,
        );
      }
    },
    onError: (error, variables, context) => {
      console.error(error, variables, context);
      addBreadcrumb({
        category: 'error',
        data: {
          name: error.name,
          message: error.message,
          cause: error.cause,
          variables,
          context,
        },
      });

      // Dont display these types errors to user
      if (error instanceof ConnectorAlreadyConnectedError) return;

      toast({
        status: 'error',
        description: error.message,
      });
    },
  });
  const disconnect = useDisconnect();
  const network = useNetwork();
  const signer = useSigner();

  const provider = useProvider();

  const contextValue: Web3Ethers = {
    account,
    connect,
    disconnect,
    network,
    signer,
    provider,
    // Shortcuts
    accountAddress: account?.address ? parseAddress(account.address) : undefined,
    chainId: ChainId.is(network.chain?.id ?? 0) ? parse(ChainId, network.chain?.id) : undefined,
    requireSigner: () => {
      if (!signer.data) throw new Error(`signer is required`);
      return signer.data;
    },
  };

  return <Web3EthersContext.Provider value={contextValue}>{children}</Web3EthersContext.Provider>;
};
