import { config } from '@/config';
import { useAsyncEffect } from '@/hooks';
import { userProfileStore } from '@/store/user';
import { trackEventStore } from '@/utils/segment';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import React, { useEffect } from 'react';
import { browserName, browserVersion, isMobile } from 'react-device-detect';
import { v4 } from 'uuid';
import { useStore } from 'zustand';

const growthbook = new GrowthBook({
  apiHost: config.integrations.growthBookApiHost,
  clientKey: config.integrations.growthBookClientKey,
  enableDevMode: config.appEnv === 'local',
});

const deviceId = (() => {
  let deviceId = localStorage.getItem('deviceId');
  if (!deviceId) localStorage.setItem('deviceId', (deviceId = v4()));
  return deviceId;
})();

// there is no automatic type generation for GrowthBook attributes
// please keep up to date with https://ff-admin.aspenft.io/attributes
export const updateFeatureAttributes = () => {
  const userId = userProfileStore.getState().userId;
  const attributes = {
    id: userId ?? '',
    loggedIn: !!userId,
    deviceId: deviceId,
    sessionId: trackEventStore.getState().sessionId,
    browser: browserName.toLowerCase(),
    browserVersion: browserVersion,
    isMobile: isMobile,
    url: window.location.href,
    urlPath: window.location.pathname,
    appVersion: config.appVersion,
  };
  console.debug('feature flags attributes', attributes);
  growthbook.setAttributes(attributes);
};

export const FeatureFlags: React.FC<React.PropsWithChildren> = ({ children }) => {
  const sessionId = useStore(trackEventStore, (t) => t.sessionId);
  const userId = useStore(userProfileStore, (t) => t.userId);
  useEffect(() => {
    if (growthbook.ready) updateFeatureAttributes();
  }, [sessionId, userId]);

  useAsyncEffect(async () => {
    // Load features asynchronously when the app renders
    await growthbook.loadFeatures({ autoRefresh: true, timeout: 5000, skipCache: true });

    // `growthbook.ready` doesn't trigger a re-render, so we need to manually set the attributes
    updateFeatureAttributes();
  }, []);

  return <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>;
};
