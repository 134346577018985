import * as Sentry from '@sentry/react';
import React, { Suspense } from 'react';
import { Loader } from './Loader';
import { LoaderErrorMessage } from './LoaderErrorMessage';

type Props = {
  fallback?: React.ReactNode;
};

export const SuspenseLoader: React.FC<React.PropsWithChildren<Props>> = ({ fallback, children }) => {
  return (
    <Sentry.ErrorBoundary fallback={<LoaderErrorMessage />} showDialog>
      <Suspense fallback={fallback ?? <Loader pt={16} />}>{children}</Suspense>
    </Sentry.ErrorBoundary>
  );
};
