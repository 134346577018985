import type { InputSize } from '@/components/form/types';
import type { ChakraStylesConfig, GroupBase, SelectedOptionStyle } from 'chakra-react-select';
import { Variant } from 'chakra-react-select/dist/types/types';
import type { SelectOption } from '../types';

// https://github.com/csandman/chakra-react-select

export const reactSelectDefaults = <Option = SelectOption, IsMulti extends boolean = false>(size: InputSize = 'md') => {
  const chakraStyles: ChakraStylesConfig<Option, IsMulti, GroupBase<Option>> = {
    option: (provided) => ({
      ...provided,
      fontSize: 'md',
      _hover: {
        bg: 'primary.500',
      },
    }),
    control: (provided) => ({
      ...provided,
      height: size === 'sm' ? 9 : 10,
    }),
  };

  const selectedOptionStyle: SelectedOptionStyle = 'check';
  const variant: Variant = 'filled';

  return {
    chakraStyles: chakraStyles,
    variant,
    selectedOptionStyle,
  };
};
