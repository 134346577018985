import { config } from '@/config';
import { getChainName } from '@/utils/chain';
import { ChainId } from '@monax/xylem/dist/types';
import { persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

export type ChainInfo = {
  id: ChainId;
  title: string;
  url: string;
};

type State = {
  currentChainId: ChainId;
  chainList: ChainInfo[];
  connectWalletDialogIsOpen: boolean;
  onConnectWalletCancel: VoidFunction | null;
};

type Actions = {
  load: () => void;
  getChainList: (chainIds: ChainId[]) => ChainInfo[];
  setCurrentChainId: (chainId: ChainId) => void;
  setConnectWalletDialogIsOpen: (isOpen: boolean) => void;
  setOnConnectWalletCancel: (voidFunc: VoidFunction | null) => void;
  reset: () => void;
};

const initialState: State = {
  currentChainId: config.contracts.supportedChainIds[0],
  chainList: [],
  connectWalletDialogIsOpen: false,
  onConnectWalletCancel: null,
};

export const userWalletStore = createWithEqualityFn<State & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,
      load: () => {
        const chainList = get().getChainList(Array.from(config.contracts.supportedChainIds));
        set({ chainList });
      },
      setCurrentChainId: (chainId: ChainId) => {
        set({
          currentChainId: chainId,
        });
      },
      setConnectWalletDialogIsOpen: (isOpen: boolean) => {
        set({ connectWalletDialogIsOpen: isOpen });
      },
      setOnConnectWalletCancel: (voidFunc: VoidFunction | null) => {
        set({ onConnectWalletCancel: voidFunc });
      },
      getChainList: (chainIds: ChainId[]): ChainInfo[] => {
        return chainIds.map((chainId) => {
          const nodeUrl = config.contracts.nodeUrls[chainId];
          if (!nodeUrl) throw new Error(`No node url for chain ${chainId}`);
          return {
            id: chainId,
            title: getChainName(chainId),
            url: nodeUrl,
          };
        });
      },
      reset: () => {
        set(initialState);
      },
    }),
    {
      partialize(state) {
        return {
          currentChainId: state.currentChainId,
        };
      },
      name: 'user-wallet-store',
    },
  ),
  shallow,
);
