import { config } from '@/config';
import { oidcClient } from '@/constants/oidc';
import { useWeb3EthersContext } from '@/contexts/Web3EthersContext';
import { accessTokenStore } from '@/store/user';
import { userSocialLinksStore } from '@/store/user/social-link';
import { userWalletStore } from '@/store/user/wallet';
import { getIdentityApi } from '@/utils/api-clients';
import { resetSegmentEventSession } from '@/utils/segment';
import { addBreadcrumb } from '@/utils/sentry';
import { toast } from '@/utils/toast';
import { useBoolean } from '@chakra-ui/react';
import { ApiError, WalletConnectorType } from '@monax/aspen-identity-sdk';
import type { Address } from '@monax/xylem/dist/types';
import axios from 'axios';
import { getUnixTime } from 'date-fns';
import { useEffect } from 'react';
import { useSignMessage } from 'wagmi';
import { useStore } from 'zustand';
import { useError } from '../common';

// https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#:~:text=Maximum%20delay%20value,the%20timeout%20being%20executed%20immediately.
const TIMEOUT_LIMIT_IN_SECONDS = 24 * 86400;

export const useAuthenticate = () => {
  const { provider } = useWeb3EthersContext();

  const { handleError } = useError();

  const [hasSignedMessage, setHasSignedMessage] = useBoolean();
  const [isAuthenticating, setIsAuthenticating] = useBoolean();

  const tokenStore = useStore(accessTokenStore);

  const chainId = useStore(userWalletStore, (s) => s.currentChainId);

  const { signMessageAsync } = useSignMessage();

  useEffect(() => {
    const { expiresAt: tokenExpiresAt, signinResponse, handleSignIn } = tokenStore;

    if (!tokenExpiresAt) return;

    const tokenExpiresInSeconds = tokenExpiresAt - getUnixTime(new Date());
    const tokenExpiresInMilliseconds = tokenExpiresInSeconds * 1000;

    if (tokenExpiresInSeconds > TIMEOUT_LIMIT_IN_SECONDS) return;

    if (tokenExpiresInSeconds <= 0) {
      signout();
      return;
    }

    const refreshTimeout = setTimeout(async () => {
      if (!signinResponse?.refresh_token) return;

      const tokenResponse = await oidcClient.useRefreshToken({
        state: {
          scope: '', //scope is encoded in the refresh_token
          refresh_token: signinResponse.refresh_token,
          session_state: signinResponse.session_state,
          profile: signinResponse.profile,
          data: undefined,
        },
      });

      handleSignIn(tokenResponse);

      addBreadcrumb({ message: 'Refresh token successful', data: { signinResponse: signinResponse } });
    }, tokenExpiresInMilliseconds - 60000); // 1min before token expires

    const resetTimeout = setTimeout(() => {
      signout();
    }, tokenExpiresInMilliseconds);

    return () => {
      if (resetTimeout) clearTimeout(resetTimeout);
      if (refreshTimeout) clearTimeout(refreshTimeout);
    };
  }, [tokenStore]);

  const signout = async (): Promise<void> => {
    accessTokenStore.getState().reset();
    userSocialLinksStore.getState().reset();
    resetSegmentEventSession();

    try {
      const signoutResponse = await axios.post(
        `${config.identityApiUrl}/connect/logout`,
        {},
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          withCredentials: true,
        },
      );

      if (signoutResponse.status !== 200) {
        addBreadcrumb({
          category: 'error',
          message: 'Failed to signout properly',
          data: { signoutResponse },
        });
      }
    } catch (err) {
      handleError(err, 'Failed to signout properly', { disableToast: true });
    }
  };

  const authenticateAddress = async (
    account: Address,
    walletConnectorType: WalletConnectorType,
  ): Promise<string | null> => {
    if (!provider) return null;

    const identityClient = getIdentityApi();

    setIsAuthenticating.on();
    setHasSignedMessage.off();
    try {
      if (!tokenStore.token) {
        addBreadcrumb({ message: 'Requesting Wallet Service signpad', data: { account } });

        const { signpad, nonce } = await identityClient.WalletService.getIdentitySignpad({ address: account });

        addBreadcrumb({ message: 'Nonce received', data: { account, nonce } });
        addBreadcrumb({ message: 'Requesting user to sign signpad', data: { account, signpad } });

        const signature = await signMessageAsync({ message: signpad });

        tokenStore.updateSignature(signature);

        setHasSignedMessage.on();

        addBreadcrumb({ message: 'Signpad signed', data: { account, signpad, signature } });
        addBreadcrumb({ message: 'Siging in', data: { account, signature, walletConnectorType } });

        const signInRequest = await oidcClient.createSigninRequest({
          extraQueryParams: {
            connection: 'CryptoWallet',
            chainId: chainId,
            connectorType: walletConnectorType,
            address: account,
            nonce: nonce,
            signature: signature,
            redirectMode: 'clientCode',
            prompt: 'login',
          },
        });

        const [requestUri, queryString] = signInRequest.url.split('?');
        const authParams = new URLSearchParams(queryString);

        const authorizationResponse = await axios.post(requestUri, authParams, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          withCredentials: true,
        });

        if (authorizationResponse.status != 200) {
          //handle validation errors - invalid login attempt somehow
          toast({
            status: 'error',
            description: 'Invalid login attempt',
          });
          addBreadcrumb({ message: 'Invalid logging attempt', data: authorizationResponse });
          return null;
        }

        const signinResponse = await oidcClient.processSigninResponse(authorizationResponse.data.redirectUri);
        tokenStore.handleSignIn(signinResponse);

        addBreadcrumb({
          message: 'User logged in with identity server',
          data: signinResponse,
        });

        return signinResponse.access_token;
      } else {
        addBreadcrumb({ message: 'Requesting Profile Service signpad', data: { account } });

        const { signpad, nonce } = await identityClient.ProfileService.getSignpadForLinkingUserWallet({
          address: account,
        });

        addBreadcrumb({ message: 'Nonce received', data: { account, nonce } });
        addBreadcrumb({ message: 'Requesting user to sign signpad', data: { account, signpad } });

        const signature = await signMessageAsync({ message: signpad });

        tokenStore.updateSignature(signature);

        addBreadcrumb({ message: 'Signpad signed', data: { account, signpad, signature } });
        addBreadcrumb({ message: 'Linking account', data: { account, signature, walletConnectorType } });

        await identityClient.ProfileService.addSignedInUserWallet({
          requestBody: {
            address: account,
            verification: { signpadNonce: nonce, signpadSignature: signature, chainId: chainId.toString() },
            walletConnectorType,
          },
        });

        addBreadcrumb({ message: 'Account linked successfully', data: { account } });

        return tokenStore.token;
      }
    } catch (err) {
      let message = 'Authentication Failed';

      if (err instanceof ApiError) {
        const errMessage = err.body.errors?.Address?.join(', ');
        if (err.status == 400 && message) message = errMessage;
      }

      handleError(err, message, { context: account });
      return null;
    } finally {
      setIsAuthenticating.off();
    }
  };

  return { isAuthenticating, signout, hasSignedMessage, authenticateAddress };
};
