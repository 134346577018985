import { Input, useForm } from '@/components/form';
import { RedemptionIcon } from '@/components/icons/menu/RedemptionIcon';
import { useTranslation } from '@/featureflags';
import { handleError } from '@/hooks';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { EditRedemptionConfig } from '@monax/aspen-spec';
import { UUIDFromString } from '@monax/xylem/dist/types';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useStore } from 'zustand';
import { redemptionConfigStore } from '../store/redemptionConfigStore';
import { CreateRedemptionConfigForm } from '../types';
import { getCreateRedemptionConfigFormSchema } from '../validation';

type Props = {
  storefrontId: UUIDFromString;
  isOpen: boolean;
  onClose: () => void;
};

export const CreateRedemptionConfigDialog: React.FC<Props> = ({ storefrontId, isOpen, onClose }) => {
  const { t } = useTranslation(['tooltips']);

  const { createRedemptionConfiguration } = useStore(redemptionConfigStore, (s) => ({
    createRedemptionConfiguration: s.createRedemptionConfiguration,
    load: s.load,
  }));

  const methods = useForm<CreateRedemptionConfigForm>(getCreateRedemptionConfigFormSchema(), { name: '' });

  const onSubmit = async (data: CreateRedemptionConfigForm) => {
    try {
      const config: EditRedemptionConfig = {
        storefrontId,
        name: data.name,
        description: '',
        image: '',
        requireEmail: false,
        requireShippingAddress: false,
        tokenRange: null,
        webhookId: null,
        subscriptionVoucherCampaignId: null,
      };

      await createRedemptionConfiguration(config);

      onClose();
    } catch (error) {
      handleError(error, 'Error creating redemption', { captureException: true });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="xl" p={7}>
        <ModalCloseButton />
        <VStack py={6}>
          <RedemptionIcon height="8" width="8" />
          <Text color="text" fontWeight="bold" fontSize="xl">
            Create new redemption
          </Text>
          <Text color="textSubtle" fontWeight="medium" fontSize="lg">
            What would you like to call your redemption collection?
          </Text>
        </VStack>

        <ModalBody pb={3} p={0}>
          <FormProvider {...methods}>
            <form id="create-redemption-to-webhook" onSubmit={methods.handleSubmit(onSubmit)}>
              <Input<CreateRedemptionConfigForm>
                name="name"
                placeholder="Enter redemption name"
                label="Redemption title"
                tooltip={t('tooltips:redemption.name')}
              />
            </form>
          </FormProvider>
        </ModalBody>

        <ModalFooter p={0}>
          <Button
            w="full"
            variant="primary"
            type="submit"
            form="create-redemption-to-webhook"
            isLoading={methods.formState.isSubmitting || methods.formState.isValidating}
          >
            Create redemption
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
