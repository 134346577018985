import { avatarAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/avatar.ts

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(avatarAnatomy.keys);

const containerMd = defineStyle({
  h: 10,
  minH: 10,
  w: 10,
  minW: 10,
  fontSize: 'lg',
});

const sizes = {
  md: definePartsStyle({ container: containerMd }),
};

export const Avatar = defineMultiStyleConfig({ sizes });
