import { ComponentStyleConfig, defineStyle } from '@chakra-ui/react';

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/heading.ts

export const Heading: ComponentStyleConfig = {
  baseStyle: () => ({
    fontFamily: 'heading',
    fontWeight: 'bold',
  }),
  // source: https://www.figma.com/file/kL1rldrUNgEYwBxSo4WLr3/%F0%9F%8C%B2-Design-System-(Chakra-UI-Figma-Kit)?node-id=1%3A1223&mode=dev
  sizes: {
    '4xl': defineStyle({
      fontSize: ['3rem', null, '4.5rem'], // [48px, null, 72px]
      lineHeight: 1,
    }),
    '3xl': defineStyle({
      fontSize: ['2.5rem', null, '3.75rem'], // [40px, null, 60px]
      lineHeight: 1,
    }),
    '2xl': defineStyle({
      fontSize: ['2.25rem', null, '3rem'], // [36px, null, 48px]
      lineHeight: [1.2, null, 1],
    }),
    xl: defineStyle({
      fontSize: ['2rem', null, '2.25rem'], // [32px, null, 36px]
      lineHeight: [1.33, null, 1.2],
    }),
    lg: defineStyle({
      fontSize: ['1.5rem', null, '2rem'], // [24px, null, 32px]
      lineHeight: [1.33, null, 1.2],
    }),
    md: defineStyle({
      fontSize: ['1.25rem', null, '1.5rem'], // [20px, null, 24px]
      lineHeight: 1.2,
    }),
    sm: defineStyle({
      fontSize: ['1.125rem', null, '1.25rem'], // [18px, null, 20px]
      lineHeight: 1.2,
    }),
    xs: defineStyle({
      fontSize: ['1rem', null, '1.125rem'], // [16px, null, 18px]
      lineHeight: 1.2,
    }),
  },
  defaultProps: {
    size: 'md',
  },
  variants: {
    gradient: (props) => {
      return {
        background: props.background ?? 'linear-gradient(296deg, #D65C30 -46.54%, #7026D9 113.16%)',
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      };
    },
  },
};
