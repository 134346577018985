import { useWeb3EthersContext } from '@/contexts/Web3EthersContext';
import { userProfileStore } from '@/store/user';
import { UserWalletResponse } from '@monax/aspen-identity-sdk';
import { isSameAddress } from '@monax/xylem/dist/types';
import { useStore } from 'zustand';

export const useIsConnectedAccount = () => {
  const { accountAddress } = useWeb3EthersContext();

  const selectedWallet = useStore(userProfileStore, (s) => s.selectedWallet);

  const isConnectedAccount = (userAccount?: UserWalletResponse | null): boolean => {
    if (!selectedWallet) {
      return false;
    }
    return isSameAddress(userAccount?.address || selectedWallet?.address, accountAddress);
  };
  return { isConnectedAccount };
};
