import { LegacyCard } from '@/components/card';
import { Loader } from '@/components/common';
import { useAsyncEffect } from '@/hooks';
import { userOrganizationsStore } from '@/store/user/organizations';
import { AddIcon } from '@chakra-ui/icons';
import { Button, Center, Text, useDisclosure } from '@chakra-ui/react';
import { UUIDFromString } from '@monax/xylem/dist/types';
import React, { useState } from 'react';
import { useStore } from 'zustand';
import { redemptionConfigStore } from '../store/redemptionConfigStore';
import { CreateRedemptionConfigDialog } from './CreateRedemptionConfigDialog';
import { StorefrontRedemptionConfigList } from './StorefrontRedemptionConfigList';

export const StorefrontRedemptions: React.FC = () => {
  const createRedemptionDisclosure = useDisclosure();
  const [createStorefrontId, setCreateStorefrontId] = useState<UUIDFromString>();

  const organizationId = useStore(userOrganizationsStore, (u) => u.selectedOrganizationId);

  const { load, storefronts, isLoading } = useStore(redemptionConfigStore, (s) => ({
    load: s.load,
    storefronts: s.storefronts,
    isLoading: s.isLoading,
  }));

  const onCreate = async (storefrontId: UUIDFromString) => {
    setCreateStorefrontId(storefrontId);
    createRedemptionDisclosure.onOpen();
  };

  useAsyncEffect(async () => {
    await load(organizationId);
  }, [organizationId]);

  if (isLoading) return <Loader />;

  return (
    <>
      {createStorefrontId && (
        <CreateRedemptionConfigDialog
          storefrontId={createStorefrontId}
          isOpen={createRedemptionDisclosure.isOpen}
          onClose={createRedemptionDisclosure.onClose}
        />
      )}
      {storefronts.length === 0 && <Center>There are no deployed storefronts</Center>}
      {storefronts.map((storefront) => (
        <LegacyCard mb={3} key={storefront.id}>
          <Text fontSize="lg" mb={3} fontWeight="bold" color="primary">
            {storefront.name}
          </Text>
          <StorefrontRedemptionConfigList key={storefront.id} storefront={storefront} />
          <Button
            textTransform="uppercase"
            w="full"
            variant="secondary"
            size="lg"
            onClick={() => onCreate(storefront.id)}
            leftIcon={<AddIcon color="primary" />}
          >
            Add new collection redemption
          </Button>
        </LegacyCard>
      ))}
    </>
  );
};
