import { Icon } from '@chakra-ui/react';
import React from 'react';
import { FaUser } from 'react-icons/fa';

type Props = {
  active?: boolean;
};

export const MarketingIcon: React.FC<Props> = ({ active }) => {
  return <Icon as={FaUser} textColor={active ? 'white' : 'ActiveBorder'} />;
};
