import { userOrganizationsStore } from '@/store/user/organizations';
import { AddIcon } from '@chakra-ui/icons';
import {
  Button,
  Divider,
  Link,
  Menu,
  MenuButton,
  MenuList,
  Skeleton,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { useStore } from 'zustand';
import { CreateOrganizationDialog } from './CreateOrganizationDialog';
import { OrganizationMenuItem } from './OrganizationMenuItem';

export const UserOrganizationsMenu: React.FC = () => {
  const createOrganizationDisclosure = useDisclosure();

  const { organizations, selectedOrganization, isSwitching, switchSelectedOrganizationId } = useStore(
    userOrganizationsStore,
    (s) => ({
      organizations: s.organizations,
      selectedOrganization: s.selectedOrganization,
      isSwitching: s.isSwitching,
      switchSelectedOrganizationId: s.switchSelectedOrganizationId,
    }),
  );

  return (
    <>
      <CreateOrganizationDialog
        isOpen={createOrganizationDisclosure.isOpen}
        onClose={createOrganizationDisclosure.onClose}
      />
      <Skeleton isLoaded={!isSwitching}>
        <>
          {selectedOrganization && (
            <Menu variant="nested" size="xl">
              <MenuButton>{selectedOrganization.name}</MenuButton>
              <MenuList>
                {organizations.map((o) => (
                  <OrganizationMenuItem
                    key={o.organizationId}
                    organization={o}
                    isSelected={o.organizationId === selectedOrganization.id}
                    onClick={() => switchSelectedOrganizationId(o.organizationId)}
                  />
                ))}
                <Divider size="lg" borderColor="cardBackgroundNested" />
                <VStack>
                  <Button
                    w="90%"
                    mt={2}
                    textTransform="uppercase"
                    variant="secondary"
                    size="md"
                    leftIcon={<AddIcon />}
                    onClick={() => createOrganizationDisclosure.onOpen()}
                  >
                    Create new organization
                  </Button>
                  <Text mt={2} fontSize="lg" alignItems="center" textAlign="center">
                    Learn more about Organizations{' '}
                    <Link
                      color="primary"
                      fontSize="lg"
                      isExternal
                      href="https://help.aspenft.io/en/articles/7793536-organizations-and-roles"
                    >
                      here
                    </Link>
                  </Text>
                </VStack>
              </MenuList>
            </Menu>
          )}
        </>
      </Skeleton>
    </>
  );
};
