import { popoverAnatomy as parts } from '@chakra-ui/anatomy';
import type { ComponentMultiStyleConfig } from '@chakra-ui/react';
import type { PartsStyleFunction, SystemStyleFunction } from '@chakra-ui/theme-tools';
import { cssVar, mode } from '@chakra-ui/theme-tools';

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/popover.ts

const $popperBg = cssVar('popper-bg');
const $arrowShadowColor = cssVar('popper-arrow-shadow-color');

const baseStyleContent: SystemStyleFunction = (props) => {
  const bg = mode('gray.50', 'white')(props);
  const shadowColor = mode('gray.300', 'whiteAlpha.300')(props);

  return {
    [$popperBg.variable]: `colors.${bg}`,
    bg: $popperBg.reference,
    [$arrowShadowColor.variable]: `colors.${shadowColor}`,
    borderColor: bg,
  };
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  content: baseStyleContent(props),
  popper: {
    // zIndex: 9999,
  },
});

export const Popover: ComponentMultiStyleConfig = {
  parts: parts.keys,
  baseStyle,
};
