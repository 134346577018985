import { Box, HStack, Spacer } from '@chakra-ui/react';
import React from 'react';

type Props = {
  addOn?: React.ReactNode;
};

export const PageTitle: React.FC<React.PropsWithChildren<Props>> = ({ children, addOn }) => {
  return (
    <HStack fontSize="2xl" fontWeight="medium" w="full" pb={4}>
      <Box w="full">{children}</Box>
      {addOn && (
        <>
          <Spacer />
          {addOn}
        </>
      )}
    </HStack>
  );
};
