import { userOrganizationsStore } from '@/store/user/organizations';
import { Button, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'zustand';
import { getCreatorToolsOrganizationsPath } from '../routes';

export const PendingUserInvitationsCard: React.FC = () => {
  const userInvitations = useStore(userOrganizationsStore, (s) => s.invitations);
  const navigate = useNavigate();

  return (
    <>
      {userInvitations.length > 0 && (
        <Button
          onClick={() => navigate(getCreatorToolsOrganizationsPath('members'))}
          variant="secondary"
          cursor="pointer"
          size="xl"
        >
          <Text fontSize="xl">Pending invitations - {userInvitations.length}</Text>
        </Button>
      )}
    </>
  );
};
