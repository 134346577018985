/* eslint-disable @typescript-eslint/ban-ts-comment */
import { tableAnatomy as parts } from '@chakra-ui/anatomy';
import type { ComponentMultiStyleConfig } from '@chakra-ui/react';
import type { PartsStyleFunction, PartsStyleInterpolation, PartsStyleObject } from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/table.ts

const variantSimple: PartsStyleFunction<typeof parts> = (props) => {
  const mainColor = mode('black', 'white')(props);
  const altColor = mode('white', 'black')(props);

  return {
    th: {
      color: mainColor,
      background: altColor,
      textTransform: 'capitalize',
    },
  };
};

const variantNested: PartsStyleFunction<typeof parts> = (props) => {
  const altColor = 'cardBackgroundNested';

  return {
    table: {
      borderCollapse: 'separate',
      borderSpacing: '0 0.5em',
    },
    th: {
      textTransform: 'capitalize',
    },
    tbody: {
      tr: {
        td: {
          background: altColor,
        },
      },
    },
  };
};

const variantStripe: PartsStyleFunction<typeof parts> = () => {
  const altColor = 'cardBackground';

  return {
    th: {
      color: 'primary',
      borderColor: altColor,
      background: altColor,
    },
    td: {
      borderColor: altColor,
    },
    tr: {
      borderColor: altColor,
    },
    tbody: {
      tr: {
        '&:nth-of-type(even)': {
          'th, td': {
            borderColor: altColor,
          },
          td: {
            background: altColor,
          },
        },
        '&:nth-of-type(odd)': {
          'th, td': {
            borderColor: 'inherit',
          },
          td: {
            background: 'inherit',
          },
        },
      },
    },
  };
};

const variantBorderless: PartsStyleFunction<typeof parts> = (props) => {
  const textColor = mode('black', 'white')(props);
  const backgroundColor = mode('white', 'black')(props);
  const borderColor = mode('gray.100', 'gray.700')(props);

  return {
    th: {
      borderBottom: '1px',
      borderColor: borderColor,
      color: textColor,
      background: backgroundColor,
      textTransform: 'capitalize',
    },
  };
};

const variants: Record<string, PartsStyleInterpolation> = {
  simple: variantSimple,
  striped: variantStripe,
  nested: variantNested,
  borderless: variantBorderless,
};

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  sm: {
    th: {
      fontSize: 'sm',
    },
    td: {
      fontSize: 'sm',
    },
    // td: {
    //   lineHeight: 6, // Allows us to have an xs button in the row without it effecting the height
    // },
  },
  md: {
    th: {
      fontSize: 'md',
    },
    td: {
      fontSize: 'md',
    },
  },
  lg: {
    th: {
      fontSize: 'lg',
    },
    td: {
      fontSize: 'lg',
    },
  },
};

// const basesizes: Record<string, PartsStyleObject<typeof parts>> = {
//   sm: {
//     th: {
//       px: '4',
//       py: '1',
//       lineHeight: '4',
//       fontSize: 'xs',
//     },
//     td: {
//       px: '4',
//       py: '2',
//       fontSize: 'sm',
//       lineHeight: '4',
//     },
//     caption: {
//       px: '4',
//       py: '2',
//       fontSize: 'xs',
//     },
//   },
//   md: {
//     th: {
//       px: '6',
//       py: '3',
//       lineHeight: '4',
//       fontSize: 'xs',
//     },
//     td: {
//       px: '6',
//       py: '4',
//       lineHeight: '5',
//     },
//     caption: {
//       px: '6',
//       py: '2',
//       fontSize: 'sm',
//     },
//   },
//   lg: {
//     th: {
//       px: '8',
//       py: '4',
//       lineHeight: '5',
//       fontSize: 'sm',
//     },
//     td: {
//       px: '8',
//       py: '5',
//       lineHeight: '6',
//     },
//     caption: {
//       px: '6',
//       py: '2',
//       fontSize: 'md',
//     },
//   },
// };

const defaultProps = {
  size: 'md',
};

export const Table: ComponentMultiStyleConfig = {
  parts: parts.keys,
  variants,
  sizes: sizes,
  defaultProps,
};
