import { LegacyCard } from '@/components/card/LegacyCard';
import { ConstrainedText, Loader } from '@/components/common';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, HStack } from '@chakra-ui/react';
import { Storefront } from '@monax/aspen-spec';
import React from 'react';
import { useStore } from 'zustand';
import { redemptionConfigStore } from '../store/redemptionConfigStore';
import { StorefrontRedemptionConfig } from './StorefrontRedemptionConfig';

type Props = {
  storefront: Storefront;
};

export const StorefrontRedemptionConfigList: React.FC<Props> = ({ storefront }) => {
  const { storefrontRedemptionConfigs, setSelectedRedemptionConfigId, getRedemptionConfigIndex } = useStore(
    redemptionConfigStore,
    (s) => ({
      storefrontRedemptionConfigs: s.storefrontRedemptionConfigs,
      selectedRedemptionConfigId: s.selectedRedemptionConfigId,
      setSelectedRedemptionConfigId: s.setSelectedRedemptionConfigId,
      getRedemptionConfigIndex: s.getRedemptionConfigIndex,
    }),
  );

  const redemptions = storefrontRedemptionConfigs[storefront.id];
  if (!redemptions) return <Loader />;

  return (
    <Accordion index={getRedemptionConfigIndex(storefront.id)} w="full" variant="nested">
      {redemptions.map((redemption) => (
        <AccordionItem key={redemption.id} mb={3}>
          <LegacyCard variant="nested">
            <AccordionButton onClick={() => setSelectedRedemptionConfigId(redemption.id)}>
              <HStack w="full" justifyContent="space-between" fontSize="lg">
                <ConstrainedText noOfLines={1} fontWeight="bold" color="primary" textAlign="left">
                  {redemption.name || 'Redemption name'}
                </ConstrainedText>
                <AccordionIcon boxSize={5} />
              </HStack>
            </AccordionButton>
            <AccordionPanel pb={4}>
              <StorefrontRedemptionConfig storefront={storefront} redemption={redemption} />
            </AccordionPanel>
          </LegacyCard>
        </AccordionItem>
      ))}
    </Accordion>
  );
};
