import logoDark from '@/assets/logo/aspen-dark.svg';
import logoLight from '@/assets/logo/aspen-light.svg';
import { getRootPath } from '@/components/routes';
import { Image, ImageProps, useColorMode } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

export const AspenLogo: React.FC<ImageProps> = (props) => {
  const { colorMode } = useColorMode();
  return (
    <Link to={getRootPath()}>
      <Image src={colorMode === 'dark' ? logoLight : logoDark} width="9.25rem" minW="9.25rem" h="2.75rem" {...props} />
    </Link>
  );
};
