import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { StoreApi } from 'zustand';
import { useStoreWithEqualityFn } from 'zustand/traditional';

type SetURLSearchParams = ReturnType<typeof useSearchParams>[1];

export type BaseTabsState<T> = {
  searchParamName: string;
  defaultTab: T;
  setURLSearchParams?: SetURLSearchParams;
  urlSearchParams?: URLSearchParams;
};

export type BaseTabsActions<T> = {
  initSearchParamsStore: (urlSearchParams: URLSearchParams, setURLSearchParams: SetURLSearchParams) => void;
  mustGetSetURLSearchParams: () => SetURLSearchParams;
  mustGetURLSearchParams: () => URLSearchParams;
  onUrlSearchParamsChange: (urlSearchParams: URLSearchParams) => void;
  setTabURLSearchParam: (newTab: T | null, apply?: boolean, clearOtherParams?: boolean) => URLSearchParams;
};

export const getBaseTabsActions = <T>(
  set: (partial: Partial<BaseTabsState<T> & BaseTabsActions<T>>) => void,
  get: () => BaseTabsState<T> & BaseTabsActions<T>,
) => {
  return {
    initSearchParamsStore: (urlSearchParams: URLSearchParams, setURLSearchParams: SetURLSearchParams) => {
      set({ setURLSearchParams });
      get().onUrlSearchParamsChange(urlSearchParams);
    },
    mustGetSetURLSearchParams: () => {
      const setURLSearchParams = get().setURLSearchParams;
      if (!setURLSearchParams) throw new Error('SetURLSearchParams not initialized');
      return setURLSearchParams;
    },
    mustGetURLSearchParams: () => {
      const urlSearchParams = get().urlSearchParams;
      if (!urlSearchParams) throw new Error('urlSearchParams not initialized');
      return urlSearchParams;
    },
    setTabURLSearchParam: (newTab: string | null, apply = true, clearOtherParams?: boolean) => {
      const urlSearchParams = clearOtherParams ? new URLSearchParams() : get().mustGetURLSearchParams();

      urlSearchParams.set(get().searchParamName, newTab ?? '');
      if (apply) {
        const setURLSearchParams = get().mustGetSetURLSearchParams();
        setURLSearchParams(urlSearchParams);
      }
      return urlSearchParams;
    },
  } as const;
};

export type BaseStoreApi<T> = StoreApi<BaseTabsState<T> & BaseTabsActions<T>>;

// Overload signatures
export function useBaseTabsStore<T, S extends BaseStoreApi<T>, U>(
  ...args: Parameters<typeof useStoreWithEqualityFn<S, U>>
): ReturnType<typeof useStoreWithEqualityFn<S, U>>;

export function useBaseTabsStore<T, S extends BaseStoreApi<T>>(
  ...args: Parameters<typeof useStoreWithEqualityFn<S>>
): ReturnType<typeof useStoreWithEqualityFn<S>>;

// Implementation
export function useBaseTabsStore<T, S extends BaseStoreApi<T>, U>(
  ...args: Parameters<typeof useStoreWithEqualityFn<S>>
): ReturnType<typeof useStoreWithEqualityFn<S>> | ReturnType<typeof useStoreWithEqualityFn<S, U>> {
  const [urlSearchParams, setURLSearchParams] = useSearchParams();
  const { initSearchParamsStore, onUrlSearchParamsChange } = args[0].getState();

  useEffect(() => {
    initSearchParamsStore(urlSearchParams, setURLSearchParams);
  }, [urlSearchParams, setURLSearchParams]);

  // This effect makes sure the value in the url remains the same as the store value.
  useEffect(() => {
    onUrlSearchParamsChange(urlSearchParams);
  }, [urlSearchParams]);

  return useStoreWithEqualityFn(...args);
}
