import copyIconDark from '@/assets/collection/copy-dark.svg';
import copyIconLight from '@/assets/collection/copy-light.svg';
import { ImageProps, useColorMode } from '@chakra-ui/react';
import React from 'react';
import { Icon } from './Icon';

type Props = {
  cursor?: string;
} & ImageProps;

export const CopyIcon: React.FC<Props> = ({ cursor = '', height = '5', width = '5', ...imageProps }) => {
  const { colorMode } = useColorMode();

  return (
    <Icon
      src={colorMode === 'light' ? copyIconDark : copyIconLight}
      height={height}
      width={width}
      cursor={cursor}
      {...imageProps}
    />
  );
};
