import { Center, CenterProps, Spinner, SpinnerProps, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { AspenLoader } from './AspenLoader';

export type LoaderProps = CenterProps & {
  loaderSize?: SpinnerProps['size'];
  loadingText?: string;
};

export const Loader: React.FC<LoaderProps> = ({ loaderSize = 'xl', loadingText, ...rest }) => {
  return (
    <Center h="full" {...rest}>
      <VStack w="full">
        {loaderSize !== 'xl' && <Spinner size={loaderSize} />}
        {loaderSize === 'xl' && <AspenLoader />}
        {loadingText && <Text color="textSubtle">{loadingText}</Text>}
      </VStack>
    </Center>
  );
};
