import { Startup } from '@/components/routes/Startup';
import { ConnectWalletDrawer } from '@/components/wallet';
import { theme } from '@/theme';
import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { IntercomProvider } from 'react-use-intercom';
import { WagmiConfig } from 'wagmi';
import { initLocalization } from '../i18n/config';
import { config, isDevEnv } from './config';
import { Web3EthersProvider } from './contexts/Web3EthersContext';
import { FeatureFlags } from './featureflags';
import { getWagmiClient } from './utils/wagmi/wagmi-client';

initLocalization();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: !isDevEnv(),
    },
  },
});

export const App: React.FC = () => {
  return (
    <FeatureFlags>
      <QueryClientProvider client={queryClient}>
        <WagmiConfig client={getWagmiClient()}>
          <ChakraProvider theme={theme}>
            <Web3EthersProvider>
              <HelmetProvider>
                <IntercomProvider appId={config.integrations.intercomAppId ?? ''} autoBoot={false}>
                  <Startup />
                  <ConnectWalletDrawer />
                </IntercomProvider>
              </HelmetProvider>
            </Web3EthersProvider>
          </ChakraProvider>
        </WagmiConfig>
        {/* Manually enable this when needed */}
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </FeatureFlags>
  );
};
