import { FormLabel as FormLabelCUI, HStack, Text } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import type { FieldValues, Path } from 'react-hook-form';
import { FormTooltip } from './FormTooltip';

type Props<T extends FieldValues> = {
  name: Path<T>;
  label?: string | ReactNode;
  tag?: string;
  tooltip?: string;
};

function FormLabel<T extends FieldValues>({ name, label, tag, tooltip }: Props<T>) {
  return (
    <FormLabelCUI
      visibility={!label ? 'hidden' : undefined}
      h={!label ? 0 : undefined}
      m={!label ? 0 : undefined}
      htmlFor={name}
    >
      <HStack>
        <Text>{label}</Text>
        {tag && <Text fontWeight="thin">{tag}</Text>}
        <FormTooltip tooltip={tooltip} />
      </HStack>
    </FormLabelCUI>
  );
}

export { FormLabel };
