import { getHandleSocialLinkAddedPath } from '@/components/routes';
import { config } from '@/config';
import { getIdentityApi } from '@/utils/api-clients';
import { upperCaseFirstLetter } from '@/utils/string';
import { UserProfileResponse } from '@monax/aspen-identity-sdk';
import { capitalize } from 'lodash';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

export type ExternalLoginError =
  | 'Unknown'
  | 'AccessDeniedByUser'
  | 'LoginAlreadyLinked'
  | 'LoginLinkedToOtherUser'
  | 'UserNotSignedIn';

export type Provider = 'discord';

type State = {
  discordId: string | null;
};

type Actions = {
  reset: () => void;
  load: (profile?: UserProfileResponse) => void;
  addExternalLogin: (provider: Provider, openDrawer: boolean) => void;
  removeExternalLogin: (provider: Provider) => Promise<void>;
};

// define the initial state
const initialState: State = {
  discordId: null,
};

export const userSocialLinksStore = createWithEqualityFn<State & Actions>()(
  (set, get) => ({
    ...initialState,
    addExternalLogin: (provider: Provider, openDrawer: boolean) => {
      const addHiddenFieldToForm = (key: string, value: string, form: HTMLFormElement): void => {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', key);
        hiddenField.setAttribute('value', value);
        form.appendChild(hiddenField);
      };

      const returnUrl = new URL(getHandleSocialLinkAddedPath(), window.location.origin);

      returnUrl.searchParams.set('provider', provider);
      returnUrl.searchParams.set('openUserProfileDrawer', openDrawer.toString());
      returnUrl.searchParams.set('userProfileDefaultTab', 'settings');
      returnUrl.searchParams.set('redirectPath', window.location.pathname + window.location.search);

      const form = document.createElement('form');
      form.setAttribute('method', 'POST');
      form.setAttribute('action', `${config.identityApiUrl}/account/addExternalLogin`);
      addHiddenFieldToForm('provider', provider, form);
      addHiddenFieldToForm('returnUrl', returnUrl.href, form);

      document.body.appendChild(form);
      form.submit();
    },
    removeExternalLogin: async (provider: Provider) => {
      await getIdentityApi().ProfileService.deleteSignedInUserExternalLogin({ provider });
      get().load();
    },
    load: async (profile?: UserProfileResponse) => {
      get().reset();
      const result = profile ?? (await getIdentityApi().ProfileService.getSignedInUserProfile());
      for (const login of result.logins) {
        if (login.loginProvider === 'discord') set({ discordId: login.username });
      }
    },
    reset: () => {
      set(initialState);
    },
  }),
  shallow,
);

export const getExternalLoginErrorMessage = (provider: Provider, error: ExternalLoginError): string => {
  const providerCapitalized = capitalize(provider);
  switch (error) {
    case 'Unknown':
      return 'Failed to load';
    case 'AccessDeniedByUser':
      return `User rejected the access to his ${providerCapitalized} profile`;
    case 'LoginAlreadyLinked':
      return `${upperCaseFirstLetter(providerCapitalized)} account already linked`;
    case 'LoginLinkedToOtherUser':
      return `${upperCaseFirstLetter(providerCapitalized)} account already linked to other Aspen user profile`;
    case 'UserNotSignedIn':
      return `There is no login session on the identity server's side`;
  }
};
