import { ComponentMultiStyleConfig, ComponentSingleStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const Calendar: ComponentMultiStyleConfig = {
  parts: ['calendar', 'months'],

  baseStyle: () => ({
    calendar: {
      position: 'relative',
      w: 'min-content',
      rounded: 'md',
      shadow: 'lg',
      bg: 'cardBackgroundNested',
    },

    months: {
      p: 4,
      w: '100%',
      gridTemplateColumns: '1fr 1fr',
    },
  }),
};

const CalendarControl: ComponentMultiStyleConfig = {
  parts: ['controls', 'button'],

  baseStyle: () => ({
    controls: {
      position: 'absolute',
      p: 4,
      w: '100%',
      justifyContent: 'space-between',
    },

    button: {
      h: 6,
      px: 2,
      lineHeight: 0,
      fontSize: 'md',
      rounded: 'md',

      _hover: {
        bgColor: 'cardBackgroundNested',
      },
    },
  }),
};

const CalendarDay: ComponentSingleStyleConfig = {
  baseStyle: () => ({
    bgColor: 'transparent',
    borderRadius: 'full',
    fontWeight: 'medium',

    _hover: {
      bgColor: 'cardBackgroundNested',
    },

    _disabled: {
      color: mode('gray.400', 'gray.600'),
      _hover: {
        cursor: 'initial',
        bgColor: 'transparent',
      },
    },
  }),

  sizes: {
    sm: {
      w: 9,
      h: 9,
      p: 5,
      fontSize: 'lg',
    },
  },

  variants: {
    selected: {
      bgColor: 'primary',
      color: 'white',
      fontWeight: 'bold',

      _hover: {
        bgColor: 'primary',
      },
    },

    outside: (props) => ({
      color: mode('gray.500', 'gray.400')(props),
    }),

    today: {
      color: 'primary',
    },

    range: {
      bgColor: 'pink.200',
      color: 'white',

      _hover: {
        bgColor: 'pink.100',
      },

      _disabled: {
        _hover: {
          bgColor: 'pink.300',
        },
      },
    },
  },

  defaultProps: {
    size: 'sm',
  },
};

const CalendarMonth: ComponentMultiStyleConfig = {
  parts: ['month', 'name', 'week', 'weekday', 'days'],

  baseStyle: {
    name: {
      h: 8,
      fontSize: 'xl',
      fontWeight: 'semibold',
      lineHeight: 6,
      textAlign: 'center',
      textTransform: 'capitalize',
    },

    week: {
      gridTemplateColumns: 'repeat(7, 1fr)',
      pt: 7,
      pb: 1,
    },

    weekday: {
      fontSize: 'md',
      fontWeight: 'medium',
      textAlign: 'center',
      textTransform: 'uppercase',
    },

    days: {
      gridTemplateColumns: 'repeat(7, 1fr)',
    },
  },

  defaultProps: {
    name: {
      as: 'h2',
    },
  },
};

export const CalendarComponents = {
  Calendar,
  CalendarMonth,
  CalendarDay,
  CalendarControl,
};
