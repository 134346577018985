import { QUERY_KEY } from '@/constants';
import { userOrganizationsStore } from '@/store/user/organizations';
import { getAspenApi } from '@/utils/api-clients';
import { useQuery } from 'react-query';
import { useStore } from 'zustand';

export const useWebhooksForOrganizationQuery = () => {
  const organizationId = useStore(userOrganizationsStore, (u) => u.selectedOrganizationId);
  const result = useQuery([QUERY_KEY.webhooksWebhooksForOrganization, organizationId], async () => {
    if (!organizationId) return [];
    return await getAspenApi().getWebhooksForOrganization({
      parameters: {
        organizationId,
      },
    });
  });
  return result;
};
