import { menuAnatomy as parts } from '@chakra-ui/anatomy';
import type {
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleFunction,
  SystemStyleInterpolation,
} from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';
import { sizes as buttonSizes } from './Button';

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/menu.ts

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  xl: {
    button: {
      ...buttonSizes.xl,
    },
    list: {
      fontSize: 'lg',
    },
    item: {
      px: buttonSizes.xl.px,
    },
  },
  lg: {
    button: {
      ...buttonSizes.lg,
    },
    list: {
      fontSize: 'md',
    },
  },
  md: {
    button: {
      ...buttonSizes.md,
    },
    list: {
      fontSize: 'md',
    },
  },
  sm: {
    button: {
      ...buttonSizes.sm,
    },
    list: {
      fontSize: 'md',
    },
  },
};

const baseStyleButton: SystemStyleFunction = () => {
  return {
    fontWeight: 'semibold',
    borderRadius: 'md',
    bg: 'cardBackground',
  };
};

const baseStyleList: SystemStyleFunction = () => {
  return {
    bg: 'cardBackground',
    borderColor: 'cardBackgroundNested',
    zIndex: 2,
  };
};

const itemStyleList: SystemStyleFunction = () => {
  return {
    py: '2',
    bg: 'cardBackground',
    _hover: {
      bg: 'blackAlpha.200',
      _dark: {
        bg: 'whiteAlpha.200',
      },
    },
  };
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  button: baseStyleButton(props),
  list: baseStyleList(props),
  item: itemStyleList(props),
});

const variants: Record<string, SystemStyleInterpolation> = {
  compact: {
    button: {
      px: 3,
      h: 8,
    },
  },
  nested: (props) => ({
    button: {
      px: { base: 3, md: 4, lg: 5 },
      backgroundColor: mode('rgba(0, 0, 0, 0.05)', 'rgba(255, 255, 255, 0.07)')(props),
    },
  }),
};

const defaultProps = {
  size: 'md',
};

export const Menu = {
  parts: parts.keys,
  sizes,
  variants,
  baseStyle,
  defaultProps,
};
