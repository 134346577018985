import type { ComponentStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { colors } from '../colors';

export const LegacyCard: ComponentStyleConfig = {
  // The styles all Cards have in common
  baseStyle: (props) => ({
    backgroundColor: 'cardBackground',
    padding: 4,
    borderRadius: 'xl',
    boxShadow: 'xl',
    fontSize: 'md',
    borderWidth: 3,
    borderColor: props.colorScheme === 'active' ? 'primary' : 'transparent',
  }),
  variants: {
    nested: () => ({
      padding: 3,
      backgroundColor: 'cardBackgroundNested',
      boxShadow: 'none',
    }),
    gradient: () => ({
      background: 'primaryGradient',
      borderWidth: 0,
      color: 'white',
    }),
    notice: () => ({
      background: 'rgba(112, 95, 248, 0.15)',
      color: 'primary',
      borderRadius: 'full',
    }),
    alert: () => ({
      background: 'orange.50',
      color: 'orange.500',
      fontWeight: 500,
      border: 0,
    }),
    outline: (props) => ({
      bg: 'transparent',
      border: `1px solid ${mode(colors.blackAlpha[400], colors.whiteAlpha[300])(props)}`,
      boxShadow: 'none',
    }),
  },
};
