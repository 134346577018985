import { getBlockchain } from '@monax/aspen-spec';
import {
  Address,
  ChainId,
  NATIVE_ETH_DECIMALS,
  NATIVE_TOKEN,
  ZERO_ADDRESS,
  isSameAddress,
} from '@monax/xylem/dist/types';
import { ReadContractConfig, prepareWriteContract, readContract, writeContract } from '@wagmi/core';
import { BigNumber, Signer } from 'ethers';
import { erc20ABI } from 'wagmi';
import { getNativeCurrencyName } from '../chain';
import { parseWagmiAddress } from '../wagmi';

export const erc20BalanceOf = async (
  chainId: ChainId,
  contractAddress: Address,
  accountAddress: Address,
): Promise<BigNumber> => {
  const data = await readContract({
    chainId,
    address: parseWagmiAddress(contractAddress),
    abi: erc20ABI,
    functionName: 'balanceOf',
    args: [parseWagmiAddress(accountAddress)],
  });
  return data;
};

export const erc20Decimals = async (chainId: ChainId, contractAddress: Address): Promise<number> => {
  if (isSameAddress(contractAddress, NATIVE_TOKEN)) {
    return NATIVE_ETH_DECIMALS;
  }

  const data = await readContract({
    chainId,
    address: parseWagmiAddress(contractAddress),
    abi: erc20ABI,
    functionName: 'decimals',
  });
  return data;
};

export const erc20Symbol = async (chainId: ChainId, contractAddress: Address): Promise<string> => {
  if (isSameAddress(contractAddress, NATIVE_TOKEN)) return getNativeCurrencyName(getBlockchain(chainId));

  const data = await readContract({
    chainId,
    address: parseWagmiAddress(contractAddress),
    abi: erc20ABI,
    functionName: 'symbol',
  });
  return data;
};

export const erc20Allowance = async (
  chainId: ChainId,
  contractAddress: Address,
  owner: Address,
  spender: Address,
): Promise<BigNumber> => {
  const data = await readContract({
    chainId,
    address: parseWagmiAddress(contractAddress),
    abi: erc20ABI,
    functionName: 'allowance',
    args: [parseWagmiAddress(owner), parseWagmiAddress(spender)],
  });
  return data;
};

export const erc20Approve = async (
  chainId: ChainId,
  contractAddress: Address,
  signer: Signer,
  spender: Address,
  value: BigNumber,
) => {
  const config = await prepareWriteContract({
    signer,
    chainId,
    address: parseWagmiAddress(contractAddress),
    abi: erc20ABI,
    functionName: 'approve',
    args: [parseWagmiAddress(spender), value],
  });
  return await writeContract(config);
};

// Most ERC20 implementations dont implement ERC165, thus calling `supportsInterface` is not feasible
// source: https://github.com/OpenZeppelin/openzeppelin-contracts/issues/3575#issuecomment-1193764676
export const isERC20Contract = async (
  chainId: ChainId,
  contractAddress: Address,
  accountAddress = ZERO_ADDRESS,
): Promise<boolean> => {
  const address = parseWagmiAddress(contractAddress);
  const account = parseWagmiAddress(accountAddress);

  const functionCalls: ReadContractConfig<typeof erc20ABI>[] = [
    {
      chainId,
      address,
      abi: erc20ABI,
      functionName: 'allowance',
      args: [account, account],
    },
    {
      chainId,
      address,
      abi: erc20ABI,
      functionName: 'balanceOf',
      args: [account],
    },
    {
      chainId,
      address,
      abi: erc20ABI,
      functionName: 'decimals',
    },
    {
      chainId,
      address,
      abi: erc20ABI,
      functionName: 'name',
    },
    {
      chainId,
      address,
      abi: erc20ABI,
      functionName: 'symbol',
    },
    {
      chainId,
      address,
      abi: erc20ABI,
      functionName: 'totalSupply',
    },
  ];

  try {
    await Promise.all(functionCalls.map((c) => readContract(c)));
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
