import { config } from '@/config';
import { OidcClient, UserManager } from 'oidc-client-ts';

const oidcConfig = {
  authority: config.identityApiUrl,
  client_id: 'aspen-app',
  redirect_uri: window.location.origin,
  response_type: 'code',
  scope: 'openid profile identity:profile.write aspen_api offline_access',
  post_logout_redirect_uri: window.location.origin,
};

export const oidcClient = new OidcClient(oidcConfig);
export const userManager = new UserManager(oidcConfig);
