import type { SystemStyleFunction } from '@chakra-ui/theme-tools';

const variantClickable: SystemStyleFunction = () => {
  return {
    cursor: 'pointer',
  };
};

const variants = {
  clickable: variantClickable,
};

export const Image = {
  variants,
};
