import { getAspenApi } from '@/utils/api-clients';
import {
  EditPublishedRedemptionConfig,
  EditRedemptionConfig,
  RedemptionConfig,
  Storefront,
  StorefrontFileType,
  SubscriptionsWithStorefrontData,
  UploadedStorefrontFile,
} from '@monax/aspen-spec';
import { UUIDFromString } from '@monax/xylem/dist/types';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

type State = {
  organizationId: UUIDFromString | undefined;
  selectedRedemptionConfigId: UUIDFromString | undefined;
  storefronts: Storefront[];
  storefrontRedemptionConfigs: Record<UUIDFromString, RedemptionConfig[]>;
  isUploading: boolean;
  subscriptions: SubscriptionsWithStorefrontData;
  isLoading: boolean;
};

type Actions = {
  mustGetOrganizationId: () => UUIDFromString;
  load: (organizationId?: UUIDFromString) => Promise<void>;
  createRedemptionConfiguration: (redemption: EditRedemptionConfig) => Promise<void>;
  updateRedemptionConfiguration: (redemption: EditRedemptionConfig, configurationId: UUIDFromString) => Promise<void>;
  updatePublishedRedemptionConfiguration: (
    redemption: EditPublishedRedemptionConfig,
    storefrontId: UUIDFromString,
    configurationId: UUIDFromString,
  ) => Promise<void>;
  publishRedemptionConfiguration: (storefrontId: UUIDFromString, configurationId: UUIDFromString) => Promise<void>;
  unpublishRedemptionConfiguration: (storefrontId: UUIDFromString, configurationId: UUIDFromString) => Promise<void>;
  uploadFile: (
    storefrontId: UUIDFromString,
    storefrontFileType: StorefrontFileType,
    file: File,
  ) => Promise<UploadedStorefrontFile | null>;
  setSelectedRedemptionConfigId: (redemptionConfigId: UUIDFromString) => void;
  getRedemptionConfigIndex: (storefrontId: UUIDFromString) => number;
};

const initialState: State = {
  organizationId: undefined,
  selectedRedemptionConfigId: undefined,
  storefronts: [],
  storefrontRedemptionConfigs: {},
  isUploading: false,
  subscriptions: [],
  isLoading: false,
};

export const redemptionConfigStore = createWithEqualityFn<State & Actions>()(
  (set, get) => ({
    ...initialState,
    mustGetOrganizationId: () => {
      const organizationId = get().organizationId;
      if (!organizationId) throw new Error('OrganizationId not initialized');
      return organizationId;
    },
    load: async (organizationId?: UUIDFromString) => {
      const id = organizationId ?? get().mustGetOrganizationId();
      set({ organizationId: id });

      // Is initial load
      if (organizationId) set({ isLoading: true });

      const storefronts = await getAspenApi().getStorefronts({
        parameters: { isDeployed: true, organizationId: id },
      });

      await Promise.all(
        storefronts.map(async (storefront) => {
          const redemptionConfigurations = await getAspenApi().getRedemptionConfigurations({
            parameters: { storefrontId: storefront.id },
          });

          set((state) => ({
            storefrontRedemptionConfigs: {
              ...state.storefrontRedemptionConfigs,
              [storefront.id]: redemptionConfigurations,
            },
          }));
        }),
      );

      const subscriptions = await getAspenApi().getSubscriptionsByOrganization({
        parameters: { organizationId: id },
      });

      set({ storefronts, isLoading: false, subscriptions });
    },
    createRedemptionConfiguration: async (redemption: EditRedemptionConfig): Promise<void> => {
      const created = await getAspenApi().createRedemptionConfiguration({
        parameters: { storefrontId: redemption.storefrontId },
        body: redemption,
      });

      await get().load();

      set({ selectedRedemptionConfigId: created.id });
    },
    updateRedemptionConfiguration: async (
      redemption: EditRedemptionConfig,
      configurationId: UUIDFromString,
    ): Promise<void> => {
      await getAspenApi().updateRedemptionConfiguration({
        parameters: { storefrontId: redemption.storefrontId, configurationId },
        body: redemption,
      });
      await get().load();
    },
    updatePublishedRedemptionConfiguration: async (
      redemption: EditPublishedRedemptionConfig,
      storefrontId: UUIDFromString,
      configurationId: UUIDFromString,
    ): Promise<void> => {
      await getAspenApi().updatePublishedRedemptionConfiguration({
        parameters: { storefrontId, configurationId },
        body: redemption,
      });
      await get().load();
    },
    publishRedemptionConfiguration: async (
      storefrontId: UUIDFromString,
      configurationId: UUIDFromString,
    ): Promise<void> => {
      await getAspenApi().publishRedemptionConfiguration({
        parameters: { storefrontId, configurationId },
      });
      await get().load();
    },
    unpublishRedemptionConfiguration: async (
      storefrontId: UUIDFromString,
      configurationId: UUIDFromString,
    ): Promise<void> => {
      await getAspenApi().unpublishRedemptionConfiguration({
        parameters: { storefrontId, configurationId },
      });
      await get().load();
    },
    uploadFile: async (storefrontId: UUIDFromString, storefrontFileType: StorefrontFileType, file: File) => {
      try {
        set({ isUploading: true });
        const result = await getAspenApi().uploadStorefrontFile({
          parameters: { storefrontId },
          body: {
            type: storefrontFileType,
            file: file,
          },
        });
        return result;
      } finally {
        set({ isUploading: false });
      }
    },
    setSelectedRedemptionConfigId: (redemptionConfigId: UUIDFromString) => {
      const selectedRedemptionConfigId = get().selectedRedemptionConfigId;
      set({
        selectedRedemptionConfigId: redemptionConfigId === selectedRedemptionConfigId ? undefined : redemptionConfigId,
      });
    },
    getRedemptionConfigIndex: (storefrontId: UUIDFromString): number => {
      const selectedRedemptionConfigId = get().selectedRedemptionConfigId;
      const redemptionConfigs = get().storefrontRedemptionConfigs[storefrontId];
      if (!redemptionConfigs) return -1;
      return redemptionConfigs.findIndex((r) => r.id === selectedRedemptionConfigId);
    },
  }),
  shallow,
);
