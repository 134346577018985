import { QUERY_KEY } from '@/constants';
import { useAspenApiClient } from '@/hooks/common';
import { NftCollectionStorefronts } from '@monax/aspen-spec';
import { Address, ChainId } from '@monax/xylem/dist/types';
import { useQuery } from 'react-query';

type QueryParams = {
  chainId: ChainId;
  contractAddress: Address;
};

export const useNftCollectionsByContract = (params: QueryParams, enabled = true) => {
  const { getApiClient } = useAspenApiClient();

  const result = useQuery<NftCollectionStorefronts>(
    [QUERY_KEY.nftCollections, ...Object.values(params)],
    async () => {
      return await getApiClient().request(
        'getNftCollectionsByContract',
        null,
        { chainId: params.chainId, contractAddress: params.contractAddress },
        null,
      );
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: enabled,
      retry: false,
    },
  );

  return result;
};
