import { QuestionIcon } from '@chakra-ui/icons';
import { Tooltip } from '@chakra-ui/react';
import React from 'react';

type Props = {
  tooltip?: string;
};

const FormTooltipBase: React.FC<Props> = ({ tooltip }) => {
  if (!tooltip) return null;

  return (
    <Tooltip label={tooltip} placement="top">
      <QuestionIcon color="primary" boxSize={2.5} />
    </Tooltip>
  );
};

export const FormTooltip = React.memo(FormTooltipBase);
