import { config } from '@/config';
import { parse } from '@monax/xylem/dist/schema';
import { ChainId } from '@monax/xylem/dist/types';
import { configureChains, createClient } from 'wagmi';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { wagmiChains } from './chains';

export type CryptoWalletConnector =
  | MetaMaskConnector
  | CoinbaseWalletConnector
  | WalletConnectConnector
  | InjectedConnector;

const { provider, webSocketProvider } = configureChains(wagmiChains, [
  jsonRpcProvider({
    priority: 0,
    rpc: (chain) => {
      const url = config.contracts.nodeUrls[parse(ChainId, chain.id)] ?? '';
      return { http: url, webSocket: url.replace('https', 'wss') };
    },
  }),
]);

// TODO: upgrade to latest version of wagmi
export const getWagmiClient = () =>
  createClient({
    autoConnect: true,
    provider,
    webSocketProvider,
  });
