import { WalletConnectorType } from '@monax/aspen-identity-sdk';
import { Address } from 'wagmi';
import { assertUnreachable } from '../types';

export const ConnectorNames = {
  metamaskConnector: 'MetaMask',
  coinbaseWalletConnector: 'Coinbase Wallet',
  walletConnectConnector: 'WalletConnectLegacy',
  magicLinkConnector: 'Magic',
};

export const getConnectorFor = async (connectorType: WalletConnectorType) => {
  switch (connectorType) {
    case WalletConnectorType.META_MASK:
      return (await import('./connectors/metamask-connector')).getMetaMaskConnector();
    case WalletConnectorType.COINBASE_WALLET:
      return (await import('./connectors/coinbase-wallet-connector')).getCoinbaseWalletConnector();
    case WalletConnectorType.WALLET_CONNECT:
      return (await import('./connectors/wallet-connect-connector')).getWalletConnectConnector();
    case WalletConnectorType.INJECTED:
      return (await import('./connectors/injected-connector')).getInjectedConnector();
    case WalletConnectorType.MAGIC_LINK:
      return (await import('./connectors/magic-link-connector')).getMagicLinkConnector();
  }

  assertUnreachable(connectorType);
};

export const parseWagmiAddress = (address: string): Address => {
  return address as Address;
};

export const wagmiConnectorIdToWalletConnectorType = (connectorId: string): WalletConnectorType => {
  switch (connectorId) {
    case 'coinbaseWallet':
      return WalletConnectorType.COINBASE_WALLET;
    case 'injected':
      return WalletConnectorType.INJECTED;
    case 'metaMask':
      return WalletConnectorType.META_MASK;
    case 'walletConnect':
    case 'walletConnectLegacy':
      return WalletConnectorType.WALLET_CONNECT;
    case 'magic':
      return WalletConnectorType.MAGIC_LINK;
  }

  throw new Error('Unknown wagmi connector id: ' + connectorId);
};
