import { PlacementWithLogical, ResponsiveValue, Text, TextProps, Tooltip } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';

type Props = Omit<Omit<TextProps, 'children'>, 'noOfLines'> & {
  placement?: PlacementWithLogical;
  children: string;
  noOfLines: ResponsiveValue<number>;
};

/**
 * Renders the chakra-ui Tooltip only if the trigger text is overflowing.
 */
export const ConstrainedText: React.FC<React.PropsWithChildren<Props>> = ({
  placement = 'top',
  children,
  ...textProps
}) => {
  const textRef = useRef<HTMLParagraphElement>(null);
  const [isOverflown, setIsOverflown] = useState(false);

  useEffect(() => {
    if (!textRef.current) return;
    const element = textRef.current;
    // This allows to detect if there's overflown text.
    setIsOverflown(element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight);
  }, []);

  return (
    <Tooltip label={children} placement={placement} isDisabled={!isOverflown}>
      <Text ref={textRef} {...textProps}>
        {children}
      </Text>
    </Tooltip>
  );
};
