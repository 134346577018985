import { parse } from '@monax/xylem/dist/schema';
import { Hex, StringInteger } from '@monax/xylem/dist/types';

export function shortenString(str: string | undefined | null, maxLength = 13): string {
  if (!str) return '';
  const joiner = '...';
  if (str.length <= maxLength) return str;
  const pad = (maxLength - joiner.length) / 2;
  return str.substring(0, pad) + joiner + str.substring(str.length - pad);
}

export function parseHex(str: unknown): Hex {
  return parse(Hex, str);
}

export function parseStringInteger(str: unknown): StringInteger {
  return parse(StringInteger, str);
}

export function upperCaseFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
