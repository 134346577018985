import { Box, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

type Props = {
  onClick: () => void;
  isActive: boolean;
  isDisabled?: boolean;
  variant?: 'default' | 'gradient';
  borderRadius?: 'sm' | 'md';
};

export const FormToggleButton: React.FC<React.PropsWithChildren<Props>> = ({
  onClick,
  isActive,
  isDisabled,
  variant,
  children,
  borderRadius,
}) => {
  const cardBackgroundNestedContent = useColorModeValue('blackAlpha.200', 'whiteAlpha.200');

  const activeBg = variant === 'gradient' ? 'primaryGradient' : 'primary';

  return (
    <Box
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      px={4}
      py={0.5}
      onClick={() => {
        if (isDisabled) return;
        onClick();
      }}
      bg={isActive ? (isDisabled ? cardBackgroundNestedContent : activeBg) : undefined}
      borderRadius={borderRadius ?? 'md'}
      color={isActive ? 'white' : undefined}
    >
      {children}
    </Box>
  );
};
