import { ImageWithSkeleton } from '@/components/common';
import { VerifiedBadgeIcon } from '@/components/icons/contracts/VerifiedBadgeIcon';
import { Button, HStack, Text } from '@chakra-ui/react';
import { OwnedNftCollectionStorefront } from '@monax/aspen-spec';
import React from 'react';

type Props = {
  collection: OwnedNftCollectionStorefront;
  onItemClick: (collection: OwnedNftCollectionStorefront) => void;
};

export const StorefrontAndCollectionSearchItem: React.FC<Props> = ({ collection, onItemClick }) => {
  const {
    nftCollection: { imageUrl, name },
    storefrontId,
  } = collection;

  return (
    <Button
      variant="unstyled"
      size="lg"
      onClick={() => onItemClick(collection)}
      _hover={{ bg: 'cardBackgroundNested' }}
    >
      <HStack>
        <ImageWithSkeleton src={imageUrl} w={8} h={8} borderRadius="base" />
        <Text fontWeight="bold" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
          {name}
        </Text>
        {storefrontId && <VerifiedBadgeIcon fill="gradient" />}
      </HStack>
    </Button>
  );
};
