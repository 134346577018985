import { useTranslation } from '@/featureflags';
import { userWalletStore } from '@/store/user/wallet';
import { Box, BoxProps, Button, Center, HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useStore } from 'zustand';
import { Props as CardProps, LegacyCard } from '../card';
import { ChainSelector } from './ChainSelector';

type Props = {
  description?: string;
  boxProps?: BoxProps;
  cardProps?: CardProps;
};

export const ConnectWallet: React.FC<Props> = ({ description, boxProps, cardProps }) => {
  const { t } = useTranslation(['components']);
  const setConnectWalletDialogIsOpen = useStore(userWalletStore, (s) => s.setConnectWalletDialogIsOpen);

  return (
    <Box h="full" p={8} {...boxProps}>
      <LegacyCard boxShadow="none" h="full" {...cardProps}>
        <VStack h="full" alignItems="center" justifyContent="center" spacing={6}>
          <Text fontSize="5xl"> {t('components:wallet.noWalletConnected')}</Text>
          <Text fontSize="xl" w="40%" textAlign="center">
            {description ? description : t('components:wallet.noWalletConnectedDescription')}
          </Text>
          <HStack>
            <Center pt={4}>
              <ChainSelector />
            </Center>
            <Center pt={4}>
              <Button variant="primary" size="lg" onClick={() => setConnectWalletDialogIsOpen(true)}>
                {t('components:wallet.connectWallet')}
              </Button>
            </Center>
          </HStack>
        </VStack>
      </LegacyCard>
    </Box>
  );
};
