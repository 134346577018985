import { userProfileStore } from '@/store/user';
import { useFeatureIsOn as useFeatureIsOnGB, useFeatureValue as useFeatureValueGB } from '@growthbook/growthbook-react';
import { Address, UUID, isSameAddress } from '@monax/xylem/dist/types';
import { useEffect, useState } from 'react';
import { useTranslation as useTranslationOG } from 'react-i18next';
import { useStore } from 'zustand';
import { I18nNamspace } from '../../i18n/config';
import { AppFeatures } from './app-features';
import { BooleanAppFeatures, NumberAppFeatures, StringAppFeatures } from './types';

export const useFeatureIsOn = useFeatureIsOnGB<Pick<AppFeatures, BooleanAppFeatures>>;
export const useFeatureValue = <
  K extends keyof Pick<AppFeatures, BooleanAppFeatures | NumberAppFeatures | StringAppFeatures>,
>(
  id: K,
  fallback: AppFeatures[K],
): AppFeatures[K] => {
  return useFeatureValueGB(id, fallback) as AppFeatures[K];
};

export const useFeatureImportCollectionAllowImpersonation = () => {
  const [canImpersonate, setCanImpersonate] = useState<boolean>(false);

  const value = useFeatureValueGB('import-collection-allow-impersonation', { addresses: [] as Address[] });
  const accountAddresses = useStore(userProfileStore, (s) => s.accountAddresses);

  useEffect(() => {
    const isAllowed = accountAddresses.some((a) => value.addresses.some((b) => isSameAddress(a, b)));
    setCanImpersonate(isAllowed);
  }, [value, accountAddresses]);

  return canImpersonate;
};

type CustomCopy = {
  regular?: Record<string, string>;
  storefrontPage?: Record<UUID, Record<string, string>>;
};

export const useTranslation = (ns: I18nNamspace[], opts?: Partial<{ storefrontId: UUID }>) => {
  const { storefrontId } = opts || {};
  const { t } = useTranslationOG([...ns, ...(storefrontId ? ['storefront-page'] : [])]);
  const { regular, storefrontPage } = useFeatureValueGB('custom-copy', {}) as CustomCopy;

  const copy = {
    ...regular,
    ...(storefrontId ? storefrontPage?.[storefrontId] || {} : {}),
  };

  return {
    t: (label: string, options?: any) => t(copy[label] || label, options),
  };
};
