import { decode } from 'blurhash';
import { useQuery, UseQueryResult } from 'react-query';

export const useBlurhashQuery = ({
  hash,
  height,
  width,
  punch,
}: {
  hash: string;
  height: number;
  width: number;
  punch: number;
}): UseQueryResult<Uint8ClampedArray> => {
  const result = useQuery(['blurhash', hash, height, width, punch], () => decode(hash, width, height, punch), {
    cacheTime: Infinity,
    staleTime: Infinity,
    retry: false,
  });
  return result;
};
