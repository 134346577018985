import { alertAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleObject } from '@chakra-ui/theme-tools';

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/alert.ts

const baseStyle: PartsStyleObject<typeof parts> = {
  container: {
    borderRadius: 'md',
  },
  icon: {
    w: 4,
    h: 5,
  },
};

const defaultProps = {
  variant: 'left-accent',
};

export const Alert = {
  parts: parts.keys,
  baseStyle,
  defaultProps,
};
