import { BaseTabsActions, BaseTabsState, getBaseTabsActions, useBaseTabsStore } from '@/store';
import { EqualityFn, Selector } from '@/types/zustand';
import { StoreApi, useStore } from 'zustand';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

export type UserProfileTabKey = 'wallets' | 'settings';

export type TabData = {
  readonly index: number;
  readonly key: UserProfileTabKey;
  readonly label: string;
  readonly description: string;
};

export const ALL_TABS: TabData[] = [
  {
    index: 0,
    key: 'wallets',
    label: 'Wallets',
    description: '',
  },
  {
    index: 1,
    key: 'settings',
    label: 'Settings',
    description: '',
  },
];

type State = {
  tabs: TabData[];
  activeTabKey: UserProfileTabKey;
} & BaseTabsState<UserProfileTabKey>;

type Actions = {
  /** Sets the given tab as active only if it exists */
  setActiveTabIndex: (activeTabIndex: number) => void;
  setActiveTabKey: (activeTabKey: UserProfileTabKey) => void;
} & BaseTabsActions<UserProfileTabKey>;

const initialState: State = {
  tabs: ALL_TABS,
  activeTabKey: 'wallets',
  defaultTab: 'wallets',
  searchParamName: 'tab',
};

export const userProfileTabsStore = createWithEqualityFn<State & Actions>()(
  (set, get) => ({
    ...initialState,
    ...getBaseTabsActions<UserProfileTabKey>(set, get),
    setActiveTabIndex: (activeTabIndex: number) => {
      const activeTab = get().tabs.find((t) => t.index === activeTabIndex);
      if (activeTab) {
        set({ activeTabKey: activeTab.key });
      }
    },
    setActiveTabKey: (activeTabKey: UserProfileTabKey) => {
      const tabs = get().tabs;
      if (tabs.find((t) => t.key === activeTabKey)) {
        set({ activeTabKey });
      }
    },
    onUrlSearchParamsChange: (urlSearchParams: URLSearchParams) => {
      const newTab = urlSearchParams.get(get().searchParamName);
      const tabs = get().tabs;
      const activeTabKey = tabs.find((t) => t.key === newTab)?.key ?? get().defaultTab;
      set({ urlSearchParams, activeTabKey });
    },
  }),
  shallow,
);

type TabStoreApi = StoreApi<Actions & State>;
type TabStoreSelector<T> = Selector<TabStoreApi, T>;
type TabStoreEqualityFn<T> = EqualityFn<TabStoreApi, T>;

// Overload signatures
export function useTabsStore(): ReturnType<typeof useStore<TabStoreApi>>;
export function useTabsStore<T>(
  selector: TabStoreSelector<T>,
  equalityFn?: TabStoreEqualityFn<T>,
): ReturnType<typeof useStore<TabStoreApi, T>>;
// Implementation
export function useTabsStore<T>(selector?: TabStoreSelector<T>, equalityFn?: TabStoreEqualityFn<T>) {
  return selector && equalityFn
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useBaseTabsStore<UserProfileTabKey, TabStoreApi, T>(userProfileTabsStore, selector, equalityFn)
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useBaseTabsStore<UserProfileTabKey, TabStoreApi>(userProfileTabsStore);
}
