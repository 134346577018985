import '@/theme/styles.css';
import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { RecoilRoot, useRecoilSnapshot } from 'recoil';
import { App } from './App';
import { config } from './config';

if (config.integrations.sentryDNS) {
  Sentry.init({
    dsn: config.integrations.sentryDNS,
    environment: config.appEnv,
    release: `v${config.appVersion}`,
    integrations: [
      new Sentry.Integrations.HttpContext(),
      new Sentry.Integrations.Breadcrumbs({
        console: true,
        dom: true,
        fetch: false,
        history: false,
        sentry: false,
        xhr: true,
      }),
    ],
    tracesSampleRate: 0.25,
    normalizeDepth: 6,
  });
  Sentry.setTags({
    component: 'aspen-app',
  });
}

const DebugObserver: React.FC = () => {
  const snapshot = useRecoilSnapshot();
  useEffect(() => {
    if (config.appEnv === 'production') return;
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      console.debug('state change ->', node.key, snapshot.getLoadable(node).contents);
    }
  }, [snapshot]);
  return null;
};

const container = document.getElementById('root') as Element;
const root = createRoot(container);

root.render(
  <RecoilRoot>
    <DebugObserver />
    <App />
  </RecoilRoot>,
);
