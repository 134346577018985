import type { SystemStyleObject } from '@chakra-ui/react';
import type { SystemStyleInterpolation } from '@chakra-ui/theme-tools';
import { Input } from './Input';

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/textarea.ts

const variants: Record<string, SystemStyleInterpolation> = {
  filled: (props) => Input.variants.filled(props).field ?? {},
};

const sizes: Record<string, SystemStyleObject> = {
  xs: Input.sizes.xs.field ?? {},
  sm: Input.sizes.sm.field ?? {},
  md: Input.sizes.md.field ?? {},
  lg: Input.sizes.lg.field ?? {},
};

const defaultProps = {
  variant: 'filled',
};

export const Textarea = {
  variants,
  sizes,
  defaultProps,
};
