// `__JSON_CONFIG__` global is injected by ../server/server.ts in production,
// and ../vite.config.ts in development.
export const config = {
  ...__JSON_CONFIG__,
  social: {
    twitter: { url: 'https://twitter.com/aspenft' }, // TODO separate twitter account?
    discord: { url: 'https://discord.gg/aspenft' },
    telegram: { url: 'https://monax.io' }, // TODO
  },
  homepage: 'https://monax.io', // TODO separate info page?
} as const;

export const isDevEnv = () => {
  return config.appEnv === 'local';
};

// For debugging ---------------------------------
if (typeof window !== 'undefined') {
  window.config = config;
}
// ------------------------------------------------
