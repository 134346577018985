import { useEffect, useState } from 'react';

export type RefType = React.MutableRefObject<HTMLDivElement | undefined | null>;

export const useAtScrollTop = <T extends RefType>(ref?: T, refAccessor?: (ref: T) => RefType | undefined) => {
  const accessor = refAccessor ?? (() => ref);
  // Assume pages load at the top, because on the first render the ref will be null.
  const [atScrollTop, setAtScrollTop] = useState(true);

  useEffect(() => {
    if (!ref) return;

    const currentRef = accessor(ref)?.current;
    if (!currentRef) return;

    const handleScroll = () => {
      setAtScrollTop(currentRef.scrollTop === 0);
    };

    handleScroll();
    currentRef.addEventListener('scroll', handleScroll);
    return () => {
      currentRef.removeEventListener('scroll', handleScroll);
    };
  }, [ref, atScrollTop]);

  return atScrollTop;
};
