import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

/** Basically useEffect but NOT on first render */
export const useDidUpdateEffect = (effect: EffectCallback, deps?: DependencyList) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) return effect();
    else didMountRef.current = true;
  }, deps);
};
