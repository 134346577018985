import { getAspenApi } from '@/utils/api-clients';
import { MemberRoles, Organization, Organizations, UserInvitation } from '@monax/aspen-spec';
import { Address, UUIDFromString } from '@monax/xylem/dist/types';
import { addBreadcrumb } from '@sentry/react';
import { persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import { UseCase, hasUseCase } from './utils';

type State = {
  userRoleInSelectedOrganization: MemberRoles | undefined;
  selectedOrganizationId: UUIDFromString | undefined;
  selectedOrganization: Organization | undefined;
  organizations: Organizations;
  invitations: UserInvitation[];
  isLoading: boolean;
  isSwitching: boolean;
  isAccepting: boolean;
  isDeclining: boolean;
};

type Actions = {
  load: () => Promise<void>;
  switchSelectedOrganizationId: (organizationId: UUIDFromString | undefined) => Promise<void>;
  createOrganization: (name: string) => Promise<Organization>;
  editOrganizationName: (organizationId: UUIDFromString, name: string) => Promise<void>;
  removeUserFromOrganization: (organizationId: UUIDFromString, userId: UUIDFromString) => Promise<void>;
  getOrganization: (organizationId: UUIDFromString) => Promise<Organization>;
  updateUserRoleForOrganization: (
    organizationId: UUIDFromString,
    userId: UUIDFromString,
    role: MemberRoles,
  ) => Promise<void>;
  inviteUserToOrganization: (organizationId: UUIDFromString, address: Address, role: MemberRoles) => Promise<void>;
  acceptInvitationToOrganization: (organizationId: UUIDFromString, inviteId: UUIDFromString) => Promise<void>;
  declineInvitationToOrganization: (organizationId: UUIDFromString, inviteId: UUIDFromString) => Promise<void>;
  hasUseCaseInOrganization: (organizationId: UUIDFromString, useCase: UseCase) => boolean;
  createGasWallet: (organizationId: UUIDFromString) => Promise<void>;
};

// define the initial state
const initialState: State = {
  userRoleInSelectedOrganization: undefined,
  selectedOrganizationId: undefined,
  selectedOrganization: undefined,
  organizations: [],
  invitations: [],
  isLoading: false,
  isSwitching: false,
  isAccepting: false,
  isDeclining: false,
};

export const userOrganizationsStore = createWithEqualityFn<State & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,
      load: async () => {
        addBreadcrumb({ message: 'loading user organizations' });

        const organizations = await getAspenApi().getOrganizationsForUser();
        const invitations = await getAspenApi().getUserInvitesForOrganizations();

        set({ organizations, invitations });

        // Make sure the selected organization actually exists in the list of organizations
        const selectedOrganizationId = get().selectedOrganizationId;
        get().switchSelectedOrganizationId(selectedOrganizationId);

        addBreadcrumb({
          message: 'user organizations loaded',
          data: {
            selectedOrganizationId,
            organizations,
          },
        });
      },
      switchSelectedOrganizationId: async (organizationId: UUIDFromString | undefined) => {
        set({ isSwitching: true });
        try {
          let organizations = get().organizations;
          // This can happen if we try to switch networks when `load` hasn't finished yet
          if (organizations.length === 0) {
            await get().load();
            organizations = get().organizations;
          }

          const organizationInfo = organizations.find((a) => a.organizationId === organizationId) ?? organizations[0];

          set({ selectedOrganizationId: organizationInfo.organizationId });

          const { organization, requesterRole } = await getAspenApi().getOrganization({
            parameters: { organizationId: organizationInfo.organizationId },
          });

          set({ selectedOrganization: organization, userRoleInSelectedOrganization: requesterRole });
        } finally {
          set({ isSwitching: false });
        }
      },
      createOrganization: async (name: string): Promise<Organization> => {
        const organization = await getAspenApi().request('createOrganization', null, null, {
          name,
          information: null,
          members: [],
        });

        await get().load();
        await get().switchSelectedOrganizationId(organization.id);

        return organization;
      },
      editOrganizationName: async (organizationId: UUIDFromString, name: string): Promise<void> => {
        await getAspenApi().request('updateOrganizationName', { organizationId }, null, {
          name,
        });
        await get().load();
      },
      removeUserFromOrganization: async (organizationId: UUIDFromString, userId: UUIDFromString): Promise<void> => {
        await getAspenApi().request('removeUserFromOrganization', { organizationId, userId }, null, null);
        await get().load();
      },
      getOrganization: async (organizationId: UUIDFromString): Promise<Organization> => {
        return (await getAspenApi().getOrganization({ parameters: { organizationId } })).organization;
      },
      updateUserRoleForOrganization: async (
        organizationId: UUIDFromString,
        userId: UUIDFromString,
        role: MemberRoles,
      ): Promise<void> => {
        set({ isLoading: true });
        try {
          await getAspenApi().request('updateUserRoleForOrganization', { organizationId, userId }, null, {
            userId,
            role,
          });
          await get().load();
        } finally {
          set({ isLoading: false });
        }
      },
      inviteUserToOrganization: async (
        organizationId: UUIDFromString,
        address: Address,
        role: MemberRoles,
      ): Promise<void> => {
        await getAspenApi().request('inviteUserToOrganization', { organizationId }, null, {
          userAddress: address,
          role,
          userId: null,
          userEmail: null,
        });
        await get().load();
      },
      acceptInvitationToOrganization: async (
        organizationId: UUIDFromString,
        inviteId: UUIDFromString,
      ): Promise<void> => {
        try {
          set({ isAccepting: true });
          await getAspenApi().request('acceptInvitationToOrganization', { organizationId, inviteId }, null, null);
          await get().load();
        } finally {
          set({ isAccepting: false });
        }
      },
      declineInvitationToOrganization: async (
        organizationId: UUIDFromString,
        inviteId: UUIDFromString,
      ): Promise<void> => {
        try {
          set({ isDeclining: true });
          await getAspenApi().request('removeInvitationFromOrganization', { organizationId, inviteId }, null, null);
          await get().load();
        } finally {
          set({ isDeclining: false });
        }
      },
      hasUseCaseInOrganization: (organizationId: UUIDFromString, useCase: UseCase) => {
        const organizations = get().organizations;
        const role = organizations.find((a) => a.organizationId === organizationId)?.role;

        return role ? hasUseCase(role, useCase) : false;
      },
      createGasWallet: async (organizationId: UUIDFromString): Promise<void> => {
        await getAspenApi().request('createGasWallet', { organizationId }, null, null);
        await get().load();
      },
    }),
    {
      partialize(state) {
        return {
          selectedOrganizationId: state.selectedOrganizationId,
          selectedOrganization: state.selectedOrganization,
          invitations: state.invitations,
        };
      },
      name: 'user-organizations-store',
    },
  ),
  shallow,
);
