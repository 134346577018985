import { createContext, useContext } from 'react';
import type { InputSize } from '../types';

type FieldsMode = 'none' | 'enabled' | 'disabled';

type FormConfig = {
  inputSize?: InputSize;
  fields?: string[];
  fieldsMode?: FieldsMode;
};

export const FormConfigContext = createContext<FormConfig>({
  inputSize: undefined, // Defaults set in theme. eg pkg/app/src/theme/components/Input.ts
  fields: [],
  fieldsMode: 'none',
});

type InputProps = {
  name: string;
  size?: InputSize;
  isDisabled?: boolean;
};

export const useInputProps = (props: InputProps) => {
  const config = useContext(FormConfigContext);
  const fields = config.fields || [];
  // for indexed props
  const wildcardName = props.name.replace(/.[0-9]+./, '.0.').replace(/.[0-9]+$/, '.0');

  let enabled: boolean;

  switch (config.fieldsMode) {
    default:
    case 'none':
      enabled = !props.isDisabled;
      break;
    case 'enabled':
      enabled = !props.isDisabled && (fields.includes(props.name) || fields.includes(wildcardName));
      break;
    case 'disabled':
      enabled = !props.isDisabled && !(fields.includes(props.name) || fields.includes(wildcardName));
      break;
  }

  return {
    size: props.size ?? config.inputSize,
    isDisabled: !enabled,
    cursor: enabled ? 'pointer' : 'not-allowed',
  };
};
