import React, { useEffect, useState } from 'react';
import { MediaDisplay, ObjectFit } from './MediaDisplay';
import { PdfViewMode } from './PdfViewer';

type Props = {
  blob?: Blob;
  contentType?: string;
  height?: number | string;
  hasError?: boolean;
  objectFit?: ObjectFit;
  pdfViewMode?: PdfViewMode;
  onPlay?: () => void;
  blurhash?: string | null;
  showProcessing?: boolean;
};

const MediaBase: React.FC<Props> = ({
  blob,
  contentType,
  height,
  hasError,
  objectFit,
  pdfViewMode,
  onPlay,
  blurhash,
  showProcessing = false,
}) => {
  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    if (blob) setUrl(URL.createObjectURL(blob));
  }, [blob]);

  return (
    <MediaDisplay
      url={url}
      contentType={contentType}
      height={height}
      hasError={hasError}
      objectFit={objectFit}
      pdfViewMode={pdfViewMode}
      onPlay={onPlay}
      blurhash={blurhash}
      showProcessing={showProcessing}
    />
  );
};

export const Media = React.memo(MediaBase);
