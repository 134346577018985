import { Box, BoxProps, useStyleConfig } from '@chakra-ui/react';
import React from 'react';

export type CardVariant = 'default' | 'nested' | 'gradient' | 'notice' | 'alert' | 'outline';

export type Props = {
  variant?: CardVariant;
  cardRef?: React.LegacyRef<HTMLDivElement>;
  colorScheme?: 'active';
} & BoxProps;

// ref: https://chakra-ui.com/docs/theming/component-style#usestyleconfig-api
// styles here: ./theme/components/Legacy.ts
export const LegacyCard: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { variant, colorScheme, cardRef, children, ...rest } = props;

  const styles = useStyleConfig('LegacyCard', { variant, colorScheme });

  // Pass the computed styles into the `__css` prop
  return (
    <Box ref={cardRef} __css={styles} {...rest}>
      {children}
    </Box>
  );
};
