import { drawerAnatomy as parts } from '@chakra-ui/anatomy';
import type { ComponentMultiStyleConfig } from '@chakra-ui/react';
import type { PartsStyleFunction } from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';

// hhttps://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/drawer.ts
const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  parts: ['dialog, header, footer, overlay, closeButton'],
  dialogContainer: {
    pointerEvents: 'none',
  },
  dialog: {
    pointerEvents: 'auto',
    bg: mode('white', 'black')(props),
  },
  header: {
    fontWeight: 'normal',
    textAlign: 'center',
    pb: 10,
  },
  closeButton: {
    position: 'absolute',
    top: 6,
    right: 6,
    rounded: 'full',
    boxSize: 10,
    placeSelf: 'end',
    color: { base: mode('gray.400', 'gray.600')(props), md: mode('black', 'white')(props) },
    bg: { md: mode('gray.100', 'gray.800')(props) },
    _hover: {
      bg: { md: mode('gray.200', 'gray.700')(props) },
    },
    '.chakra-icon': {
      h: 3,
      w: 3,
    },
  },
});

// Fix for not being able to interact with the rest of the page when a drawer is open:
// https://github.com/chakra-ui/chakra-ui/issues/2893#issuecomment-780143150
const variantNftCart: PartsStyleFunction<typeof parts> = (props) => {
  return {
    parts: ['dialog, dialogContainer'],
    dialog: {
      pointerEvents: 'auto',
      borderTop: '1px solid',
      borderColor: mode('#E4E4E4', '#343434')(props),
      background: mode('whiteAlpha.800', 'rgba(23, 23, 23, 0.9)')(props),
      backdropFilter: 'blur(16px)',
    },
    dialogContainer: {
      pointerEvents: 'none',
    },
  };
};

const variants = {
  nftCart: variantNftCart,
};

export const Drawer: ComponentMultiStyleConfig = {
  parts: parts.keys,
  baseStyle,
  variants,
};
