import { getUrlParams } from '@/utils/url';
import type { TabKey as OrganizationTabKey } from '@/views/CreatorTools/Organizations/hooks/useTabsStore';
import { getAppStorefrontIdPath, getAppStorefrontSlugPath } from '@monax/aspen-spec';
import type { ChainId, UUIDFromString } from '@monax/xylem/dist/types';

const encoder = (encode: boolean, value: string | number | boolean) => (encode ? encodeURIComponent(value) : value);
const getParams = (params?: ConstructorParameters<typeof URLSearchParams>[0]): string => {
  const urlParams = new URLSearchParams(params);
  return [...urlParams.keys()].length > 0 ? `?${urlParams.toString()}` : '';
};

export type PortfolioTabName = 'home' | 'folders' | 'all-nfts';

export const getRootPath = (): string => '/';

export const getPortfolioPath = (tab?: PortfolioTabName) => {
  return `/portfolio${tab ? getUrlParams({ tab }) : ''}`;
};
export const getStorefrontSlugPath = getAppStorefrontSlugPath;
export const getStorefrontIdPath = getAppStorefrontIdPath;

export const getStorefrontPath = (
  storefrontId: string | UUIDFromString,
  storefrontSlug: string | undefined | null,
  params?: Parameters<typeof getAppStorefrontIdPath>['1'],
  encode = true,
) => {
  return storefrontSlug
    ? getStorefrontSlugPath(storefrontSlug, params, encode)
    : getStorefrontIdPath(storefrontId, params, encode);
};

export type NftDetailsPathParams = {
  tab: 'details' | 'history' | 'redeem';
};
export const getNftDetailsPath = (
  chainId: string | number | ChainId,
  contractAddress: string,
  tokenId: string,
  params?: Partial<NftDetailsPathParams>,
  encode = true,
): string => {
  const encoder = encode ? encodeURIComponent : (value: string | number): string => String(value);
  const pathParts = [chainId, 'contract', contractAddress, 'token', tokenId, 'details'];
  return `/${pathParts.map(encoder).join('/')}${getParams(params)}`;
};

export const getHandleSocialLinkAddedPath = (): string => `/handle-social-link-added`;

export const getHandleEmailAddedPath = (): string => `/handle-email-added`;
export const getEmailVerificationPath = (): string => `/email-verification`;

export const getInternalComponentsPath = (): string => `/_internal/components`;
export const getOASPath = (): string => `/oas`;

export const getMagicLinkCallbackPath = (origin?: string) => {
  const params = origin ? getUrlParams({ origin }) : '';

  return `/magic-link-callback${params}`;
};

// Creator Tools -----------------------------------------------------------
export const BASE_CREATOR_TOOLS_PATH = `creator-tools`;
export const getCreatorToolsStorefrontsPath = (): string => `/${BASE_CREATOR_TOOLS_PATH}/storefronts`;
export const getCreatorToolsEditStorefrontPath = (storefrontId: string, encode = true): string =>
  `/${BASE_CREATOR_TOOLS_PATH}/storefronts/${encoder(encode, storefrontId)}`;
export const getCreatorToolsCreateStorefrontPath = (): string => `/${BASE_CREATOR_TOOLS_PATH}/create-storefront`;
export const getCreatorToolsMarketingPath = (): string => `/${BASE_CREATOR_TOOLS_PATH}/marketing`;
export const getCreatorToolsMembershipsPath = (): string => `/${BASE_CREATOR_TOOLS_PATH}/memberships`;
export const getCreatorToolsCreateMembershipListPath = (storefrontId: string, encode = true): string =>
  `/${BASE_CREATOR_TOOLS_PATH}/storefronts/${encoder(encode, storefrontId)}/create-list`;
export const getCreatorToolsEditMembershipListPath = (storefrontId: string, listId: string, encode = true): string =>
  `/${BASE_CREATOR_TOOLS_PATH}/storefronts/${encoder(encode, storefrontId)}/lists/${encoder(encode, listId)}`;
export const getCreatorToolsRoyaltiesPath = (): string => `/${BASE_CREATOR_TOOLS_PATH}/royalties`;
export const getCreatorToolsSubscriptionsPath = (): string => `/${BASE_CREATOR_TOOLS_PATH}/subscriptions`;
export const getCreatorToolsRedemptionPath = (): string => `/${BASE_CREATOR_TOOLS_PATH}/redemption`;
export const getCreatorToolsOrganizationsPath = (tab?: OrganizationTabKey) => {
  const params = tab ? getUrlParams({ tab }) : '';
  return `/${BASE_CREATOR_TOOLS_PATH}/organizations${params}`;
};
export const getCreatorToolsEditWebhookPath = (webhookId: string, encode = true): string =>
  `/${BASE_CREATOR_TOOLS_PATH}/organizations/webhooks/${encoder(encode, webhookId)}`;
// ------------------------------------------------------------------------
