import { LegacyCard } from '@/components/card';
import { Text } from '@chakra-ui/react';
import React from 'react';

type Props = {
  title: string;
  description: string;
  children: React.ReactNode;
};

export const ActionCard: React.FC<Props> = ({ title, description, children }) => {
  return (
    <LegacyCard variant="nested">
      <Text fontWeight="bold" fontSize="lg" mb={3}>
        {title}
      </Text>
      <Text color="textSubtle" mb={3}>
        {description}
      </Text>
      {children}
    </LegacyCard>
  );
};
