import { parseAddress } from '@/utils/address';
import { erc20Symbol } from '@/utils/contracts';
import { Address, Chain, ChainId, ZERO_ADDRESS } from '@monax/xylem/dist/types';

type TokenSymbol = 'WETH' | 'APN' | 'BNB' | 'USDT' | 'USDC';

type TokenData = {
  symbol: TokenSymbol;
  address: Address;
  decimals: number;
};

type TokenMap = Record<ChainId, Record<TokenSymbol, TokenData>>;

export const getErc20TokenSymbol = async (chainId: ChainId, address: Address): Promise<string | undefined> => {
  const token = Object.values(ERC20_TOKENS[chainId]).find(({ address: tokenAddress }) => tokenAddress === address);
  if (token) return token.symbol;

  try {
    const symbol = await erc20Symbol(chainId, address);
    return symbol;
  } catch {}

  return undefined;
};

const DefaultTokenNonsense: Record<TokenSymbol, TokenData> = {
  APN: {
    symbol: 'APN',
    address: ZERO_ADDRESS,
    decimals: 18,
  },
  WETH: {
    symbol: 'WETH',
    address: ZERO_ADDRESS,
    decimals: 18,
  },
  BNB: {
    symbol: 'BNB',
    address: ZERO_ADDRESS,
    decimals: 18,
  },
  USDT: {
    symbol: 'USDT',
    address: ZERO_ADDRESS,
    decimals: 6,
  },
  USDC: {
    symbol: 'USDC',
    address: ZERO_ADDRESS,
    decimals: 6,
  },
} as const;

export const ERC20_TOKENS: TokenMap = {
  [Chain.Canto]: {
    APN: {
      symbol: 'APN',
      address: ZERO_ADDRESS,
      decimals: 18,
    },
    WETH: {
      symbol: 'WETH',
      address: parseAddress('0x826551890Dc65655a0Aceca109aB11AbDbD7a07B'),
      decimals: 18,
    },
    BNB: {
      symbol: 'BNB',
      address: ZERO_ADDRESS,
      decimals: 18,
    },
    USDT: {
      symbol: 'USDT',
      address: ZERO_ADDRESS,
      decimals: 6,
    },
    USDC: {
      symbol: 'USDC',
      address: ZERO_ADDRESS,
      decimals: 6,
    },
  },
  [Chain.Goerli]: {
    APN: {
      symbol: 'APN',
      address: ZERO_ADDRESS,
      decimals: 18,
    },
    WETH: {
      symbol: 'WETH',
      address: parseAddress('0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6'),
      decimals: 18,
    },
    BNB: {
      symbol: 'BNB',
      address: ZERO_ADDRESS,
      decimals: 18,
    },
    USDT: {
      symbol: 'USDT',
      address: ZERO_ADDRESS,
      decimals: 6,
    },
    USDC: {
      symbol: 'USDC',
      address: ZERO_ADDRESS,
      decimals: 6,
    },
  },
  [Chain.Mainnet]: {
    APN: {
      symbol: 'APN',
      address: parseAddress('0x87946dc43c7881c1c16081afeb4474e224450add'),
      decimals: 18,
    },
    WETH: {
      symbol: 'WETH',
      address: parseAddress('0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'),
      decimals: 18,
    },
    BNB: {
      symbol: 'BNB',
      address: parseAddress('0xB8c77482e45F1F44dE1745F52C74426C631bDD52'),
      decimals: 18,
    },
    USDT: {
      symbol: 'USDT',
      address: parseAddress('0xdAC17F958D2ee523a2206206994597C13D831ec7'),
      decimals: 6,
    },
    USDC: {
      symbol: 'USDC',
      address: parseAddress('0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'),
      decimals: 6,
    },
  },
  [Chain.Mumbai]: {
    APN: {
      symbol: 'APN',
      address: ZERO_ADDRESS,
      decimals: 18,
    },
    WETH: {
      symbol: 'WETH',
      address: parseAddress('0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889'),
      decimals: 18,
    },
    BNB: {
      symbol: 'BNB',
      address: ZERO_ADDRESS,
      decimals: 18,
    },
    USDT: {
      symbol: 'USDT',
      address: ZERO_ADDRESS,
      decimals: 6,
    },
    USDC: {
      symbol: 'USDC',
      address: ZERO_ADDRESS,
      decimals: 6,
    },
  },
  [Chain.Palm]: {
    APN: {
      symbol: 'APN',
      address: ZERO_ADDRESS,
      decimals: 18,
    },
    WETH: {
      symbol: 'WETH',
      address: parseAddress('0xF98cABF0a963452C5536330408B2590567611a71'),
      decimals: 18,
    },
    BNB: {
      symbol: 'BNB',
      address: ZERO_ADDRESS,
      decimals: 18,
    },
    USDT: {
      symbol: 'USDT',
      address: ZERO_ADDRESS,
      decimals: 6,
    },
    USDC: {
      symbol: 'USDC',
      address: ZERO_ADDRESS,
      decimals: 6,
    },
  },
  [Chain.PalmTestnet]: {
    APN: {
      symbol: 'APN',
      address: ZERO_ADDRESS,
      decimals: 18,
    },
    WETH: {
      symbol: 'WETH',
      address: parseAddress('0x07598803d5675D9f931Fc5a0Db19D08A98624274'),
      decimals: 18,
    },
    BNB: {
      symbol: 'BNB',
      address: ZERO_ADDRESS,
      decimals: 18,
    },
    USDT: {
      symbol: 'USDT',
      address: ZERO_ADDRESS,
      decimals: 6,
    },
    USDC: {
      symbol: 'USDC',
      address: ZERO_ADDRESS,
      decimals: 6,
    },
  },
  [Chain.Polygon]: {
    APN: {
      symbol: 'APN',
      address: ZERO_ADDRESS,
      decimals: 18,
    },
    WETH: {
      symbol: 'WETH',
      address: parseAddress('0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270'),
      decimals: 18,
    },
    BNB: {
      symbol: 'BNB',
      address: ZERO_ADDRESS,
      decimals: 18,
    },
    USDT: {
      symbol: 'USDT',
      address: ZERO_ADDRESS,
      decimals: 6,
    },
    USDC: {
      symbol: 'USDC',
      address: ZERO_ADDRESS,
      decimals: 6,
    },
  },
  // UNSUPPORTED
  [Chain.BSC]: DefaultTokenNonsense,
  [Chain.Hardhat]: DefaultTokenNonsense,
  [Chain.Harmony]: DefaultTokenNonsense,
  [Chain.Kovan]: DefaultTokenNonsense,
  [Chain.Localhost]: DefaultTokenNonsense,
  [Chain.Moonriver]: DefaultTokenNonsense,
  [Chain.Rinkeby]: DefaultTokenNonsense,
  [Chain.Ropsten]: DefaultTokenNonsense,
  [Chain.xDai]: DefaultTokenNonsense,
  [Chain.CantoTestnet]: DefaultTokenNonsense,
  [Chain.Sepolia]: {
    APN: {
      symbol: 'APN',
      address: ZERO_ADDRESS,
      decimals: 18,
    },
    WETH: {
      symbol: 'WETH',
      address: parseAddress('0xD0dF82dE051244f04BfF3A8bB1f62E1cD39eED92'),
      decimals: 18,
    },
    BNB: {
      symbol: 'BNB',
      address: ZERO_ADDRESS,
      decimals: 18,
    },
    USDT: {
      symbol: 'USDT',
      address: ZERO_ADDRESS,
      decimals: 6,
    },
    USDC: {
      symbol: 'USDC',
      address: ZERO_ADDRESS,
      decimals: 6,
    },
  },
  [Chain.Holesky]: DefaultTokenNonsense,
  [Chain.Base_Goerli]: DefaultTokenNonsense,
  [Chain.Base_Mainnet]: DefaultTokenNonsense,
};
