import { AspenLogo, CollectionsIcon, SubscriptionsIcon } from '@/components/icons/menu';
import { CreateIcon } from '@/components/icons/menu/CreateIcon';
import { MarketingIcon } from '@/components/icons/menu/MarketingIcon';
import { MembershipIcon } from '@/components/icons/menu/MembershipIcon';
import { RedemptionIcon } from '@/components/icons/menu/RedemptionIcon';
import { RoyaltiesIcon } from '@/components/icons/menu/RoyaltiesIcon';
import {
  getCreatorToolsCreateStorefrontPath,
  getCreatorToolsMarketingPath,
  getCreatorToolsMembershipsPath,
  getCreatorToolsOrganizationsPath,
  getCreatorToolsRedemptionPath,
  getCreatorToolsRoyaltiesPath,
  getCreatorToolsStorefrontsPath,
  getCreatorToolsSubscriptionsPath,
} from '@/components/routes/paths';
import { config } from '@/config';
import { useTranslation } from '@/featureflags';
import { AddIcon, Icon } from '@chakra-ui/icons';
import { Box, Center, Flex, HStack, Link, Spacer, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { FaDiscord, FaTwitter } from 'react-icons/fa';
import { Link as RLink } from 'react-router-dom';

const creatorToolsCollectionsPath = getCreatorToolsStorefrontsPath();
const creatorToolsCreateCollectionsPath = getCreatorToolsCreateStorefrontPath();
const creatorToolsMarketingPath = getCreatorToolsMarketingPath();
const creatorToolsMembershipsPath = getCreatorToolsMembershipsPath();
const creatorToolsRoyaltiesPath = getCreatorToolsRoyaltiesPath();
const creatorToolsSubscriptionsPath = getCreatorToolsSubscriptionsPath();
const creatorToolsRedemptionPath = getCreatorToolsRedemptionPath();
const creatorToolsOrganizationsPath = getCreatorToolsOrganizationsPath();

export const SIDEBAR_WIDTH = '200px';

export const Sidebar: React.FC = () => {
  const { t } = useTranslation(['components']);

  const border = useColorModeValue('blackAlpha.400', 'whiteAlpha.500');

  return (
    <VStack h="full" borderRight="2px" borderColor={border} width={SIDEBAR_WIDTH}>
      <Flex h="full" px={8} py={5} minWidth="max-content" direction="column" justifyContent="space-between">
        <VStack h="full" align="stretch" spacing={8}>
          <Center>
            <AspenLogo />
          </Center>
          <VStack spacing={3} width="100%" align="stretch">
            <CreatorToolsMenu />
          </VStack>
          <VStack flexGrow={1} justifyContent="flex-end" spacing="0" align="stretch">
            <Text color="primary" fontWeight="medium" fontSize="md">
              {t('components:layout.navigation.getInTouch')}
            </Text>
            <HStack>
              <Link href={config.social.discord.url} isExternal>
                <Icon as={FaDiscord} aria-label="Discord Contact" color="text" size={20} />
              </Link>
              <Link href={config.social.twitter.url} isExternal>
                <Icon as={FaTwitter} aria-label="Twitter Contact" color="text" size={20} />
              </Link>
            </HStack>
            <Text color="primary" fontWeight="medium" fontSize="md" pt="7">
              <Link href="https://help.aspenft.io/" isExternal>
                HELP CENTER
              </Link>
            </Text>
          </VStack>
        </VStack>
      </Flex>
      <Spacer />
      <Footer />
    </VStack>
  );
};

type SidebarButtonProps = {
  to?: string;
  href?: string;
  icon?: ReactNode;
  addOn?: ReactNode;
};

const SidebarButton: React.FC<React.PropsWithChildren<SidebarButtonProps>> = ({
  children,
  to = '',
  href,
  icon,
  addOn,
}) => {
  const {
    location: { pathname },
  } = window;
  const active = pathname === to;
  return (
    <Link
      as={to ? RLink : undefined}
      to={to}
      href={href}
      target={href ? '_blank' : undefined}
      _hover={{ textDecoration: 'none' }}
    >
      <Box background={active ? 'primary' : undefined} borderRadius="md" p={1} pl={2}>
        <HStack spacing={3}>
          {icon ? icon : ''}
          <Text fontSize="md" color="text">
            {children}
          </Text>
          {addOn ? addOn : ''}
        </HStack>
      </Box>
    </Link>
  );
};

const CreatorToolsMenu: React.FC = () => {
  const {
    location: { pathname },
  } = window;

  return (
    <>
      <MenuSection>Actions</MenuSection>
      <SidebarButton
        to={creatorToolsCreateCollectionsPath}
        icon={<CreateIcon active={pathname === creatorToolsCreateCollectionsPath} />}
      >
        Create
      </SidebarButton>
      <SidebarButton
        to={creatorToolsCollectionsPath}
        icon={<CollectionsIcon active={pathname === creatorToolsCollectionsPath} />}
      >
        Storefronts
      </SidebarButton>
      <SidebarButton
        to={creatorToolsMembershipsPath}
        icon={<MembershipIcon active={pathname === creatorToolsMembershipsPath} />}
      >
        Memberships
      </SidebarButton>
      <SidebarButton
        to={creatorToolsMarketingPath}
        icon={<MarketingIcon active={pathname === creatorToolsMarketingPath} />}
      >
        Marketing
      </SidebarButton>
      <SidebarButton
        to={creatorToolsRoyaltiesPath}
        icon={<RoyaltiesIcon variant={pathname === creatorToolsRoyaltiesPath ? 'light' : 'gray'} />}
      >
        Royalties
      </SidebarButton>
      <SidebarButton
        to={creatorToolsSubscriptionsPath}
        icon={<SubscriptionsIcon active={pathname === creatorToolsSubscriptionsPath} />}
      >
        Subscriptions
      </SidebarButton>

      <SidebarButton
        to={creatorToolsRedemptionPath}
        icon={<RedemptionIcon active={pathname === creatorToolsRedemptionPath} />}
      >
        Redemptions
      </SidebarButton>

      <SidebarButton
        to={creatorToolsOrganizationsPath}
        icon={<AddIcon color={pathname === creatorToolsOrganizationsPath ? 'white' : 'gray'} />}
      >
        Organizations
      </SidebarButton>
    </>
  );
};

const MenuSection: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Text color="primary" fontWeight="medium" fontSize="md" textTransform="uppercase">
      {children}
    </Text>
  );
};

const Footer: React.FC = () => {
  const { t } = useTranslation(['components']);

  return (
    <Box w="full" backgroundColor="primary" px={4} py={5}>
      <Link color="text" fontSize="sm" href={config.homepage}>
        {t('components:layout.navigation.copyright')}
      </Link>
    </Box>
  );
};
