import { SelectOption } from '@/components/form';
import { HandleError } from '@/hooks';
import { getAspenApi } from '@/utils/api-clients';
import { TokenRange, VoucherCampaigns } from '@monax/aspen-spec';
import { parse } from '@monax/xylem/dist/schema';
import { Address, ChainId, UUIDFromString } from '@monax/xylem/dist/types';

const getTotalSupply = async (
  chainId: ChainId | null,
  contractAddress: Address | null,
  handleError: HandleError,
): Promise<bigint | null> => {
  if (!chainId || !contractAddress) {
    handleError(null, 'No collections found for the specified contract');
    return null;
  }

  const response = await getAspenApi().request(
    'getNftCollectionsByContract',
    null,
    { chainId, contractAddress: parse(Address, contractAddress) },
    null,
  );

  if (response.length === 0) {
    return null;
  }

  return BigInt(response[0].nftCollection.totalSupply);
};

export const getTokenRange = async (
  chainId: ChainId | null,
  contractAddress: Address | null,
  redemptionTokenRange: TokenRange | null,
): Promise<TokenRange | null> => {
  let tokenRange: TokenRange | null = redemptionTokenRange;

  if (!tokenRange) {
    if (chainId && contractAddress) {
      tokenRange = {
        chainId,
        contractAddress,
        tokenIdMin: BigInt(0),
        tokenIdMax: BigInt(Number.MAX_SAFE_INTEGER),
      };
    }
  }
  return tokenRange;
};

export type VoucherOption = SelectOption<UUIDFromString>;

export const mapVoucherCampaignsOptions = (voucherCampaigns: VoucherCampaigns): VoucherOption[] => {
  return voucherCampaigns.map((campaign) => {
    return {
      label: campaign.name,
      value: campaign.id,
    };
  });
};
