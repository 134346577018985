import { inputAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleFunction, PartsStyleObject, SystemStyleObject } from '@chakra-ui/theme-tools';
import { getColor, mode } from '@chakra-ui/theme-tools';

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/input.ts

const size: Record<string, SystemStyleObject> = {
  md: {
    fontSize: 'lg',
    px: 4,
    h: 10,
    borderRadius: 'md',
  },

  sm: {
    fontSize: 'md',
    px: 3,
    h: 9,
    borderRadius: 'md',
  },

  xs: {
    fontSize: 'sm',
    px: 2,
    h: 7,
    borderRadius: 'sm',
  },
};

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  lg: {
    field: size.lg,
    addon: size.lg,
  },
  md: {
    field: size.md,
    addon: size.md,
  },
  sm: {
    field: size.sm,
    addon: size.sm,
  },
  xs: {
    field: size.xs,
    addon: size.xs,
  },
};

function getDefaults(props: Record<string, string>) {
  const { focusBorderColor, errorBorderColor } = props;
  return {
    focusBorderColor: focusBorderColor || mode('blue.500', 'blue.300')(props),
    errorBorderColor: errorBorderColor || mode('red.500', 'red.500')(props),
  };
}

const variantFilled: PartsStyleFunction<typeof parts> = (props) => {
  const { theme } = props;
  const { focusBorderColor, errorBorderColor } = getDefaults(props);

  return {
    field: {
      _invalid: {
        borderColor: getColor(theme, errorBorderColor),
      },
      _focus: {
        bg: 'transparent',
        borderColor: getColor(theme, focusBorderColor),
      },
    },
    addon: {
      bg: 'transparent',
    },
  };
};

const variants = {
  filled: variantFilled,
};

const defaultProps = {
  variant: 'filled',
};

export const Input = {
  parts: parts.keys,
  sizes,
  variants,
  defaultProps,
};
