import { themeBase } from '@/theme';
import { UseToastOptions } from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/toast';

const defaultOptions: UseToastOptions = {
  variant: 'solid',
  position: 'bottom',
  isClosable: true,
  containerStyle: {
    fontSize: 'lg',
  },
};

// The main reason we need standalone toasts is to that we have call toasts from zustand stores
// Dont use this toast in components or hooks, use the `hooks/common/useToast` hook instead
export const { ToastContainer, toast } = createStandaloneToast({ theme: themeBase, defaultOptions });
