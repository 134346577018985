import { isDevEnv } from '@/config';
import type { ChainId } from '@monax/xylem/dist/types';
import {
  addBreadcrumb as addBreadcrumbSentry,
  Breadcrumb,
  captureException as captureExceptionSentry,
  setTags,
} from '@sentry/react';
import type { CaptureContext } from '@sentry/types';
import { isError } from 'lodash';

export function addBreadcrumb(breadcrumb: Breadcrumb): void {
  console.debug(breadcrumb.message, { ...breadcrumb.data });
  if (isDevEnv()) {
    return;
  }
  addBreadcrumbSentry(breadcrumb);
}

export function captureException(error: Error | unknown, captureContext?: CaptureContext | undefined): void {
  if (isError(error)) console.debug(error.message, { ...captureContext });

  if (isDevEnv()) {
    return;
  }
  captureExceptionSentry(error, captureContext);
}

export function updateChainIdTag(chainId: ChainId): void {
  setTags({
    chainId,
  });
}
