import { PageHeader, PageLayout } from '@/components/layout';
import { useFeatureIsOn } from '@/featureflags';
import { Text } from '@chakra-ui/react';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { StorefrontRedemptions } from './components/StorefrontRedemptions';

export const RedemptionPage: React.FC = () => {
  const header = <PageHeader />;
  const supported = useFeatureIsOn('redeem-to-webhook');

  return (
    <PageLayout header={header}>
      <Helmet>
        <title>Creator Tools Redemption | Aspen</title>
      </Helmet>
      {supported ? (
        <>
          <Text fontSize="2xl" fontWeight="bold" mb={3}>
            Redemption
          </Text>
          <StorefrontRedemptions />
        </>
      ) : (
        <Text fontSize="6xl" fontWeight="bold">
          Coming Soon
        </Text>
      )}
    </PageLayout>
  );
};

export default RedemptionPage;
