import pictureIconOutlineDark from '@/assets/collection/picture-icon-outline-dark.svg';
import pictureIconOutline from '@/assets/collection/picture-icon-outline.svg';
import { Image, ImageProps, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

export const PictureIcon: React.FC<ImageProps> = ({ height = '5', width = '5', ...imageProps }) => {
  const icon = useColorModeValue(pictureIconOutlineDark, pictureIconOutline);

  return <Image src={icon} height={height} width={width} {...imageProps} />;
};
