import { SocialLoginProvider } from '@/components/wallet/types';
import { config } from '@/config';
import { MagicSupportedChain, isMagicSupportedChain } from '@/utils/chain';
import { getMagicLinkConnector } from '@/utils/wagmi/connectors/magic-link-connector';
import { Chain } from '@monax/xylem/dist/types';
import { persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

type State = {
  chainId: MagicSupportedChain;
  email?: string;
  provider?: SocialLoginProvider;
};

type Actions = {
  setEmail: (email: string) => void;
  setSocialLoginProvider: (provider: SocialLoginProvider) => void;
  setChainId: (chainId: MagicSupportedChain) => void;
  reset: () => void;
};

const defaultChain = config.contracts.supportedChainIds[0];
const initialState: State = {
  chainId: isMagicSupportedChain(defaultChain) ? defaultChain : Chain.Mainnet,
  email: undefined,
  provider: undefined,
};

export const magicLinkStore = createWithEqualityFn<State & Actions>()(
  persist(
    (set) => ({
      ...initialState,
      setEmail: (email: string) => {
        set({ email });
      },
      setSocialLoginProvider: (provider: SocialLoginProvider) => {
        set({ provider });
      },
      setChainId: (chainId: MagicSupportedChain) => {
        set({ chainId });
      },
      reset: () => {
        const magic = getMagicLinkConnector().getMagic();
        magic.user.logout();
        set(initialState);
      },
    }),
    {
      name: 'magic-link-store',
    },
  ),
  shallow,
);
