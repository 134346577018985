import { config } from '@/config';
import { Blockchain } from '@monax/aspen-spec';
import { parse } from '@monax/xylem/dist/schema';
import { Chain, ChainId, ChainNames } from '@monax/xylem/dist/types';
import { captureException } from '../sentry';

export function getNativeCurrencyName(blockchain: Blockchain) {
  if (blockchain === 'ETHEREUM') return 'ETH';
  if (blockchain === 'POLYGON') return 'MATIC';
  if (blockchain === 'PALM') return 'PALM';
  if (blockchain === 'CANTO') return 'CANTO';
  if (blockchain === 'BASE') return 'BASE';
  throw new Error(`getNativeCurrencyName: unhandled chainId: ${blockchain}`);
}

function etherscanNetworkPrefix(chainId: ChainId): string {
  switch (chainId) {
    case Chain.Mainnet:
      return ``;
    case Chain.Ropsten:
    case Chain.Kovan:
    case Chain.Rinkeby:
    case Chain.Goerli:
      return getChainName(chainId).toLocaleLowerCase() + '.';
    default:
      return ``;
  }
}

export function getEtherscanBaseUrl(chainId: ChainId): string {
  return `https://${etherscanNetworkPrefix(chainId)}etherscan.io`;
}

export function getExplorerAddressLink(address: string, chainId: ChainId): string {
  switch (chainId) {
    case Chain.Mainnet:
    case Chain.Ropsten:
    case Chain.Kovan:
    case Chain.Rinkeby:
    case Chain.Goerli:
      return `${getEtherscanBaseUrl(chainId)}/address/${address}`;
    case Chain.BSC:
      return `https://bscscan.com/address/${address}`;
    case Chain.xDai:
      return `https://blockscout.com/poa/xdai/address/${address}/transactions`;
    case Chain.Polygon:
      return `https://polygonscan.com/address/${address}/transactions`;
    case Chain.Mumbai:
      return `https://mumbai.polygonscan.com/address/${address}/transactions`;
    case Chain.Harmony:
      return `https://explorer.harmony.one/address/${address}`;
    case Chain.Moonriver:
      return `https://blockscout.moonriver.moonbeam.network/address/${address}/transactions`;
    case Chain.Palm:
      return `https://explorer.palm.io/address/${address}`;
    case Chain.PalmTestnet:
      return `https://explorer.palm-uat.xyz/address/${address}`;
    case Chain.Canto:
      return `https://evm.explorer.canto.io/address/${address}`;
    case Chain.Base_Mainnet:
      return `https://basescan.org/address/${address}`;
    case Chain.Base_Goerli:
      return `https://goerli.basescan.org/address/${address}`;
    default:
      return ``;
  }
}

export function getExplorerTransactionLink(transactionHash: string, chainId: ChainId): string {
  switch (chainId) {
    case Chain.Mainnet:
    case Chain.Ropsten:
    case Chain.Kovan:
    case Chain.Rinkeby:
    case Chain.Goerli:
      return `${getEtherscanBaseUrl(chainId)}/tx/${transactionHash}`;
    case Chain.BSC:
      return `https://bscscan.com/tx/${transactionHash}`;
    case Chain.xDai:
      return `https://blockscout.com/poa/xdai/tx/${transactionHash}/internal-transactions`;
    case Chain.Polygon:
      return `https://polygonscan.com/tx/${transactionHash}`;
    case Chain.Mumbai:
      return `https://mumbai.polygonscan.com/tx/${transactionHash}`;
    case Chain.Harmony:
      return `https://explorer.harmony.one/tx/${transactionHash}`;
    case Chain.Moonriver:
      return `https://blockscout.moonriver.moonbeam.network/tx/${transactionHash}/internal-transactions`;
    case Chain.Palm:
      return `https://explorer.palm.io/tx/${transactionHash}`;
    case Chain.PalmTestnet:
      return `https://explorer.palm-uat.xyz/tx/${transactionHash}`;
    case Chain.Canto:
      return `https://evm.explorer.canto.io/tx/${transactionHash}`;
    case Chain.Base_Mainnet:
      return `https://basescan.org/tx/${transactionHash}`;
    case Chain.Base_Goerli:
      return `https://goerli.basescan.org/tx/${transactionHash}`;
    default:
      return ``;
  }
}

export function getExplorerSiteName(chainId: ChainId): string {
  switch (chainId) {
    case Chain.Mainnet:
      return 'Etherscan';
    case Chain.Ropsten:
    case Chain.Kovan:
    case Chain.Rinkeby:
    case Chain.Goerli:
      return `${getChainName(chainId)} Etherscan`;
    case Chain.BSC:
      return 'Bscscan';
    case Chain.xDai:
      return 'Blockscout';
    case Chain.Polygon:
      return 'Polygonscan';
    case Chain.Mumbai:
      return 'Mumbai Polygonscan';
    case Chain.Harmony:
      return 'Harmony Explorer';
    case Chain.Moonriver:
      return 'Moonriver Blockscout';
    case Chain.Palm:
      return 'Palm Explorer';
    case Chain.PalmTestnet:
      return 'Palm Testnet Explorer';
    case Chain.Canto:
      return 'Canto Block Explorer';
    default:
      captureException(new Error('No explorer name for the given chain'), {
        level: 'fatal',
        tags: { chainId },
      });
      return ``;
  }
}

export function parseChainId(chainId: string | number): ChainId {
  return parse(ChainId, typeof chainId === 'string' ? parseInt(chainId) : chainId);
}

export function getChainName(chainId?: ChainId): string {
  switch (chainId) {
    case Chain.Mainnet:
      return 'Ethereum'; //UI Override for Mainnet
    default:
      return chainId ? ChainNames[chainId] : 'Unknown';
  }
}

export const isChainSupportedForReservoir = (chainId: ChainId): boolean => {
  const chainIds = config.reservoirUrls.map((r) => r.chainId);
  return chainIds.some((id) => id === chainId);
};
