import { mapRoleToViewRole } from '@/hooks/user';
import { CheckIcon } from '@chakra-ui/icons';
import { HStack, MenuItem, Spacer, Text, VStack } from '@chakra-ui/react';
import { OrganizationInformation } from '@monax/aspen-spec';
import React from 'react';
import { LegacyCard } from '../card';

type Props = {
  organization: OrganizationInformation;
  isSelected?: boolean;
  onClick?: () => void;
};

export const OrganizationMenuItem: React.FC<React.PropsWithChildren<Props>> = ({
  organization,
  isSelected,
  onClick,
}) => {
  return (
    <MenuItem onClick={() => onClick && onClick()} bg={isSelected ? 'cardBackgroundNested' : 'transparent'}>
      <HStack alignItems="center" spacing={8} w="full">
        <VStack alignItems="flex-start" spacing={1}>
          <Text fontWeight={isSelected ? 'semibold' : 'normal'}>{organization.organizationName}</Text>
          <LegacyCard background="primaryLight" variant="nested" py={0.5}>
            <Text color="primary" fontSize="sm">
              {mapRoleToViewRole(organization.role)}
            </Text>
          </LegacyCard>
        </VStack>
        <Spacer />
        {isSelected ? (
          <HStack>
            <Text color="green.500" fontWeight="bold">
              Active
            </Text>
            <CheckIcon color="green.500" />
          </HStack>
        ) : (
          <Text color="primary"> Switch</Text>
        )}
      </HStack>
    </MenuItem>
  );
};
