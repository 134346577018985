import { useWeb3EthersContext } from '@/contexts/Web3EthersContext';
import { userWalletStore } from '@/store/user/wallet';
import { isMagicSupportedChain } from '@/utils/chain';
import { getChainName } from '@/utils/chain/chain';
import { wagmiConnectorIdToWalletConnectorType } from '@/utils/wagmi';
import { Menu, MenuGroup, MenuList } from '@chakra-ui/react';
import { WalletConnectorType } from '@monax/aspen-identity-sdk';
import { getChainTypeFromChainId } from '@monax/aspen-spec';
import type { ChainId } from '@monax/xylem/dist/types';
import React from 'react';
import { useStore } from 'zustand';
import { BlockchainIcon } from '../icons/chain/BlockchainIcon';
import { StyledMenuItem } from '../menu';
import { MenuButton } from '../menu/MenuButton';

type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  width?: string;
  justifyContent?: string;
};

export const ChainSelector: React.FC<Props> = ({ size = 'lg', width, justifyContent }) => {
  const { currentChainId, chainList, setCurrentChainId } = useStore(userWalletStore, (s) => ({
    currentChainId: s.currentChainId,
    chainList: s.chainList,
    setCurrentChainId: s.setCurrentChainId,
  }));
  const {
    account: { connector: activeConnector },
  } = useWeb3EthersContext();

  const isSelectedChain = (chainId: ChainId): boolean => {
    return chainId === currentChainId;
  };

  const mainnets = chainList.filter((info) => {
    const isMainnet = getChainTypeFromChainId(info.id) === 'mainnet';
    const isConnectedWithMagicWallet =
      activeConnector && wagmiConnectorIdToWalletConnectorType(activeConnector.id) === WalletConnectorType.MAGIC_LINK;

    return isConnectedWithMagicWallet ? isMainnet && isMagicSupportedChain(info.id) : isMainnet;
  });
  const testnets = chainList.filter((info) => {
    const isTestnet = getChainTypeFromChainId(info.id) === 'testnet';
    const isConnectedWithMagicWallet =
      activeConnector && wagmiConnectorIdToWalletConnectorType(activeConnector.id) === WalletConnectorType.MAGIC_LINK;

    return isConnectedWithMagicWallet ? isTestnet && isMagicSupportedChain(info.id) : isTestnet;
  });

  return (
    <Menu size={size} variant="nested">
      <MenuButton w={width} justifyContent={justifyContent} startIcon={<BlockchainIcon chainId={currentChainId} />}>
        {getChainName(currentChainId)}
      </MenuButton>
      <MenuList>
        <MenuGroup title="Mainnets">
          {mainnets.map((chain) => (
            <StyledMenuItem
              key={chain.id}
              onClick={() => setCurrentChainId(chain.id)}
              isSelected={isSelectedChain(chain.id)}
              icon={<BlockchainIcon chainId={chain.id} />}
            >
              {chain.title}
            </StyledMenuItem>
          ))}
        </MenuGroup>
        {testnets.length > 0 && (
          <MenuGroup title="Testnets">
            {testnets.map((chain) => (
              <StyledMenuItem
                key={chain.id}
                onClick={() => setCurrentChainId(chain.id)}
                isSelected={isSelectedChain(chain.id)}
                icon={<BlockchainIcon chainId={chain.id} />}
              >
                {chain.title}
              </StyledMenuItem>
            ))}
          </MenuGroup>
        )}
      </MenuList>
    </Menu>
  );
};
