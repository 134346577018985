import { QUERY_KEY } from '@/constants';
import { getAspenApi } from '@/utils/api-clients';
import { UUIDFromString } from '@monax/xylem/dist/types';
import { useQuery } from 'react-query';

export const useGetVoucherCampaignsQuery = (subscriptionId?: UUIDFromString | null) => {
  const result = useQuery([QUERY_KEY.subscriptionVoucherCampaigns, subscriptionId], () => {
    if (!subscriptionId) return null;
    return getAspenApi().request('getVoucherCampaigns', { subscriptionId }, null, null);
  });

  return result;
};
