import aspenLogo from '@/assets/logo/aspen-logo.svg';
import { MotionBox } from '@/components/framer-motion/MotionBox';
import { colors } from '@/theme/colors';
import { Box, keyframes, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

export const AspenLoader: React.FC = () => {
  const background = useColorModeValue(colors.white, colors.black);

  return (
    <Box
      m={20}
      position="relative"
      h="100px"
      w="100px"
      borderRadius="50%"
      _after={{
        content: `""`,
        background,
        backgroundImage: `url(${aspenLogo})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        position: 'absolute',
        top: 2,
        left: 2,
        right: 2,
        bottom: 2,
        borderRadius: '50%',
      }}
    >
      <Color filter="blur(5px)" />
      <Color filter="blur(10px)" />
      <Color filter="blur(25px)" />
      <Color filter="blur(50px)" />
    </Box>
  );
};

type ColorProps = {
  filter: string;
};

const Color: React.FC<ColorProps> = ({ filter }) => {
  const animationKeyframes = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
  }`;

  const animation = `${animationKeyframes} 1s linear infinite`;

  return (
    <MotionBox
      position="absolute"
      h="100px"
      w="100px"
      borderRadius="50%"
      background="linear-gradient(#D55C31, #982743, #7027DA)"
      animation={animation}
      filter={filter}
    />
  );
};
