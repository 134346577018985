import { userProfileStore } from '@/store/user';
import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { useStore } from 'zustand';
import { ConnectWallet } from '../wallet/ConnectWallet';
import { Sidebar } from './sidebar';

type Props = {
  requiresSelectedWallet?: boolean;
  hideSideBar?: boolean;
  connectWalletDescription?: string;
};

export const DefaultLayout: React.FC<React.PropsWithChildren<Props>> = ({
  requiresSelectedWallet = false,
  hideSideBar,
  connectWalletDescription,
  children,
}) => {
  const currentUserWallet = useStore(userProfileStore, (s) => s.selectedWallet);

  // If the responsive width is sm, hide the side bar
  const forceHideSideBar = useBreakpointValue({ base: true, md: false });

  return (
    <Flex h="full" direction="row">
      {!hideSideBar && !forceHideSideBar && <Sidebar />}
      <Box flex={1} h="full" overflow="hidden">
        {!currentUserWallet && requiresSelectedWallet ? (
          <ConnectWallet description={connectWalletDescription} />
        ) : (
          children
        )}
      </Box>
    </Flex>
  );
};
