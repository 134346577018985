import type { SelectOption } from '@/components/form';
import { SubscriptionsWithStorefrontData } from '@monax/aspen-spec';
import { UUIDFromString } from '@monaxlabs/aspen-sdk';

export type SubscriptionOption = SelectOption<UUIDFromString>;

export const useSubscriptionOptions = (
  storefrontId: UUIDFromString,
  subscriptions: SubscriptionsWithStorefrontData,
): SubscriptionOption[] => {
  if (!subscriptions) return [];

  return subscriptions
    .filter((s) => s.storefrontId === storefrontId)
    .map((subscription) => ({
      value: subscription.id,
      label: subscription.name,
    }));
};
