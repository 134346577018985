/**
 * Helper function for when, at compile time, we want to be sure we are checking all possible values of an enum/type.
 *
 * @example
 * type Items = 'abc' | 'def';
 * const dummyFunc = (items: Items): string => {
 *   switch (items) {
 *     case 'abc':
 *       return 'ABC';
 *     case 'def':
 *       return 'DEF';
 *   }
 *   assertUnreachable(items);
 * };
 *
 * @comment
 * If in the future we expand the type `Items` and forget to add a case to the `dummyFunc` switch we will have a compile time error.
 */
export const assertUnreachable = (x: never): never => {
  throw new Error(`Value should be 'never' but found ${JSON.stringify(x)} instead.`);
};

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

// https://www.typescriptlang.org/docs/handbook/2/mapped-types.html#mapping-modifiers
export type RequiredPick<T, K extends keyof T> = T & { [P in K]-?: NonNullable<T[P]> };
