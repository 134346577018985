import type { SystemStyleFunction, SystemStyleObject } from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';
import { theme } from '../theme';

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/divider.ts

const defaultVariant: SystemStyleFunction = (props) => ({
  borderStyle: 'solid',
  borderColor: mode('blackAlpha.400', 'whiteAlpha.300')(props),
  marginTop: '2',
  marginBottom: '2',
});

const subtleVariant: SystemStyleFunction = (props) => ({
  ...theme.components.Divider.variants.default(props),
  borderColor: mode('#E5E5EA', 'rgba(255, 255, 255, 0.20)')(props),
});

const variantTransparent: SystemStyleObject = {
  borderColor: 'transparent',
};

const variants = {
  default: defaultVariant,
  subtle: subtleVariant,
  transparent: variantTransparent,
};

const sizes = {
  md: {
    borderWidth: '1px',
    borderRadius: 'sm', // uses theme radii
  },
  lg: {
    borderWidth: '0.125rem',
    borderRadius: 'sm',
  },
  xl: {
    borderWidth: '0.25rem',
    borderRadius: 'sm',
  },
};

const defaultProps = {
  size: 'md',
  variant: 'default',
};

export const Divider = {
  defaultProps,
  variants,
  sizes,
};
