import { FileInput, FormLabel } from '@/components/form';
import { Media, UploadButton } from '@/components/media';
import { MediaBlob, useMediaBlob } from '@/hooks/query/common';
import { SUPPORTED_FILE_EXTENSIONS } from '@/utils/media';
import { Box, BoxProps, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { InputError } from '../form/InputError';

type Props = {
  name: string;
  url?: string | null;
  onFileSelected: (file: File) => void;
  height?: number | string;
  mediaType?: string;
  isLoading?: boolean;
  acceptExts?: string[];
  defaultFile?: File | undefined;
  uploadButtonProps?: BoxProps;
  label?: string;
  tooltip?: string;
  showFormInputError?: boolean;
};

export const EditMedia: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  name,
  url = '',
  onFileSelected,
  height,
  mediaType = 'Media',
  isLoading,
  acceptExts,
  defaultFile,
  uploadButtonProps,
  label,
  tooltip,
  showFormInputError = true,
}) => {
  const { data: existingMedia, isLoading: isLoadingMedia } = useMediaBlob({ url: url ?? '', enabled: !!url });

  const [file, setFile] = useState<File | undefined>(defaultFile);

  const onSelectFiles = async (files: File[]) => {
    setFile(files[0]);
    onFileSelected(files[0]);
  };

  const hasLoadedMedia = !!file || !!existingMedia;

  return (
    <VStack alignItems="start" h="full" spacing={0}>
      {label && <FormLabel label={label} name={name} tooltip={tooltip} />}
      <FileInput
        name={name}
        acceptExts={acceptExts ?? SUPPORTED_FILE_EXTENSIONS}
        acceptMimes={[]}
        maxFiles={1}
        onUploadFiles={onSelectFiles}
        isLoading={isLoading || isLoadingMedia}
        buttonContainerProps={{
          w: !hasLoadedMedia ? 'full' : undefined,
          h: '100%',
        }}
        button={
          hasLoadedMedia ? (
            <Box>
              <PreviewMedia existingMedia={existingMedia} file={file} height={height} />
            </Box>
          ) : (
            <UploadButton title={`Upload ${mediaType}`} isLoading={isLoading || isLoadingMedia} {...uploadButtonProps}>
              {children}
              {showFormInputError && <InputError name={name} isStandAlone errorMessageProps={{ display: 'block' }} />}
            </UploadButton>
          )
        }
      />
    </VStack>
  );
};

type PreviewMediaProps = {
  existingMedia?: MediaBlob;
  file?: File;
  height?: number | string;
};

const PreviewMedia: React.FC<PreviewMediaProps> = ({ file, existingMedia, height }) => {
  let media: React.ReactNode | undefined = undefined;

  if (file) {
    media = <Media height={height} blob={file} contentType={file.type} />;
  } else if (existingMedia)
    media = <Media height={height} blob={existingMedia.blob} contentType={existingMedia.contentType} />;

  if (!media) return null;

  return <>{media}</>;
};
