import { Button, Icon, useColorMode } from '@chakra-ui/react';
import React from 'react';
import { FiMoon, FiSun } from 'react-icons/fi';

type Props = {};

export const ColorModeIcon: React.FC<Props> = ({}) => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Button variant="unstyled" size="xs" onClick={toggleColorMode}>
      <Icon as={colorMode === 'light' ? FiMoon : FiSun} boxSize={6} />
    </Button>
  );
};
