import icon from '@/assets/chain/palm.png';
import type { ResponsiveValue } from '@chakra-ui/react';
import React from 'react';
import { Icon } from '../menu/Icon';

type Props = {
  height?: ResponsiveValue<string>;
  width?: ResponsiveValue<string>;
};

export const PalmIcon: React.FC<Props> = ({ height = '5', width = '5' }) => {
  return <Icon src={icon} height={height} width={width} />;
};
