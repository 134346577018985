import { useDidUpdateEffect } from '@/hooks';
import { useIsConnectedAccount, useRequireWallet } from '@/hooks/user';
import { userProfileStore } from '@/store/user';
import { resetSegmentEventSession } from '@/utils/segment/store';
import { Button, Icon } from '@chakra-ui/react';
import React, { lazy } from 'react';
import { IoWalletOutline } from 'react-icons/io5';
import { useStore } from 'zustand';
import { EthAddressIcon } from '../icons/profile';
import { userProfileDrawerStore } from './store/userProfileDrawerStore';

const ProfileDrawer = lazy(() => import('./ProfileDrawer'));

export const ProfileButton: React.FC = () => {
  const { closeDrawer, isOpen } = useStore(userProfileDrawerStore);
  const { isConnectedAccount } = useIsConnectedAccount();
  const { openDrawer } = useStore(userProfileDrawerStore);
  const selectedWallet = useStore(userProfileStore, (s) => s.selectedWallet);

  const { requireWallet } = useRequireWallet();

  // clean up state on logout
  useDidUpdateEffect(() => {
    if (!selectedWallet) {
      resetSegmentEventSession();
      closeDrawer();
    }
  }, [selectedWallet]);

  return (
    <>
      {selectedWallet ? (
        <>
          <Button variant="unstyled" size="xl" onClick={() => openDrawer()}>
            <EthAddressIcon
              size="md"
              address={selectedWallet.address}
              isConnected={isConnectedAccount(selectedWallet)}
            />
          </Button>
          <ProfileDrawer onClose={closeDrawer} isOpen={isOpen} />
        </>
      ) : (
        <Button variant="outline-with-shadow" size="xl" onClick={() => requireWallet()}>
          <Icon as={IoWalletOutline} mr="2.5" boxSize={4} />
          Connect Wallet
        </Button>
      )}
    </>
  );
};
