import { ErrorMessage, Loader } from '@/components/common';
import { getStorefrontPath } from '@/components/routes';
import { config } from '@/config';
import { useOwnedNftCollectionsQuery } from '@/hooks/query/nft-collections';
import { userProfileStore } from '@/store/user';
import {
  Icon,
  Input,
  InputGroup,
  InputGroupProps,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { OwnedNftCollectionStorefront } from '@monax/aspen-spec';
import React, { useCallback, useMemo, useState } from 'react';
import { RiSearchLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'zustand';
import { StorefrontAndCollectionSearchItem } from './StorefrontAndCollectionSearchItem';
import { WalletCollectionModal } from './WalletCollectionModal';

const chainIds = [...config.contracts.supportedChainIds];
const INPUT_MIN_WIDTH = '15rem';
const INPUT_MAX_WIDTH = '25rem';

export const StorefrontAndCollectionSearchBox: React.FC<InputGroupProps> = (props) => {
  const navigate = useNavigate();
  const [isQueryEnabled, setIsQueryEnabled] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedCollection, setSelectedCollection] = useState<OwnedNftCollectionStorefront>();
  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const clickOutsideRef = React.useRef<HTMLInputElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const accountAddresses = useStore(userProfileStore, (s) => s.accountAddresses);

  useOutsideClick({ ref: clickOutsideRef, handler: onClose, enabled: isOpen });

  const {
    data: collections,
    isLoading,
    error,
  } = useOwnedNftCollectionsQuery({ accountAddresses, chainIds, enabled: isQueryEnabled });

  const { storefronts, nonStorefronts } = useMemo(() => {
    const formattedSearch = searchText.trim().toLocaleLowerCase();
    if (formattedSearch.length < 3) {
      onClose();
      return { storefronts: [], nonStorefronts: [] };
    }

    onOpen();
    const filtered = (collections ?? []).filter(({ nftCollection }) =>
      nftCollection.name.toLocaleLowerCase().includes(formattedSearch),
    );
    return {
      storefronts: filtered.filter((c) => !!c.storefrontId),
      nonStorefronts: filtered.filter((c) => !c.storefrontId),
    };
  }, [searchText, collections]);

  const onItemClick = useCallback((collection: OwnedNftCollectionStorefront) => {
    if (collection.storefrontId) {
      return navigate(getStorefrontPath(collection.storefrontId, collection.storefrontSlug));
    }

    setSearchText('');
    setSelectedCollection(collection);
    onClose();
  }, []);

  return (
    <>
      {selectedCollection && (
        <WalletCollectionModal
          isOpen={true}
          chainId={selectedCollection.nftCollection.chainId}
          contractAddress={selectedCollection.nftCollection.contractAddress}
          onClose={() => setSelectedCollection(undefined)}
        />
      )}
      <Popover isOpen={isOpen} initialFocusRef={initialFocusRef} matchWidth={true}>
        <PopoverTrigger>
          <InputGroup size="md" minW={INPUT_MIN_WIDTH} maxW={INPUT_MAX_WIDTH} {...props}>
            <Input
              placeholder="Search your NFTs or Collections"
              value={searchText}
              onFocus={() => setIsQueryEnabled(true)}
              onChange={(e) => setSearchText(e.target.value)}
              ref={initialFocusRef}
            />
            <InputRightElement>
              <Icon as={RiSearchLine} color="textDisabled" boxSize={5} />
            </InputRightElement>
          </InputGroup>
        </PopoverTrigger>
        <PopoverContent w="full" maxH="18rem" overflow="auto" ref={clickOutsideRef}>
          <PopoverBody
            px={6}
            py={5}
            onKeyDown={(e) => {
              if (e.code === 'Escape') {
                initialFocusRef.current?.focus();
                onClose();
              }
            }}
          >
            <VStack align="left" fontSize="lg" spacing={4}>
              {isLoading && <Loader py={15} />}
              {!isLoading && storefronts.length === 0 && nonStorefronts.length === 0 && <Text>No results...</Text>}
              {storefronts.length > 0 && (
                <>
                  <Text fontWeight="medium" color="textDisabled">
                    Aspen Storefronts
                  </Text>
                  {storefronts.map((c) => (
                    <StorefrontAndCollectionSearchItem
                      key={c.nftCollection.id}
                      collection={c}
                      onItemClick={onItemClick}
                    />
                  ))}
                </>
              )}
              {nonStorefronts.length > 0 && (
                <>
                  <Text fontWeight="medium" color="textDisabled">
                    Hasn’t claimed their storefront yet
                  </Text>
                  {nonStorefronts.map((c) => (
                    <StorefrontAndCollectionSearchItem
                      key={c.nftCollection.id}
                      collection={c}
                      onItemClick={onItemClick}
                    />
                  ))}
                </>
              )}
              {!!error && <ErrorMessage title="Something went wrong" message="Failed to fetch data" />}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};
