import { BlurhashCanvas, Loader } from '@/components/common';
import { getStorefrontMediaTypeFromContentType } from '@/utils/media';
import { Box, Image as CImage, Center, Text, VStack, useBoolean } from '@chakra-ui/react';
import { TextMimeTypeEnum } from '@monax/aspen-spec';
import React, { useEffect } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { PdfViewMode, PdfViewer } from './PdfViewer';

export type ObjectFit = 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';

type Props = {
  url?: string;
  contentType?: string;
  height?: number | string;
  width?: number | string;
  hasError?: boolean;
  objectFit?: ObjectFit;
  pdfViewMode?: PdfViewMode;
  onPlay?: () => void;
  blurhash?: string | null;
  showProcessing?: boolean;
  poster?: string;
  preload?: boolean;
};

const MediaDisplayBase: React.FC<Props> = ({
  url,
  contentType,
  height,
  width,
  hasError,
  objectFit,
  pdfViewMode,
  onPlay,
  blurhash,
  showProcessing = false,
  poster,
  preload = false,
}) => {
  const [imageLoadError, setImageLoadError] = useBoolean(false);

  useEffect(() => {
    if (hasError) setImageLoadError.on();
  }, [hasError]);

  const heightFormatted = height ? (typeof height === 'number' ? `${height}px` : height) : undefined;
  const widthFormatted = width ? (typeof width === 'number' ? `${width}px` : width) : undefined;

  const loaderComponent = blurhash ? (
    <Box h="full" w="full">
      <AutoSizer>
        {({ height, width }) => <BlurhashCanvas hash={blurhash} punch={0} width={width} height={height} />}
      </AutoSizer>
    </Box>
  ) : (
    <Center height={heightFormatted} width={widthFormatted}>
      <VStack gap={2}>
        <Loader loaderSize={showProcessing ? 'xl' : 'lg'} />
        {showProcessing && <Text color="primary">Processing</Text>}
      </VStack>
    </Center>
  );

  if (!url || !contentType) return loaderComponent;

  const mediaFormat = getStorefrontMediaTypeFromContentType(contentType);

  return (
    <>
      {mediaFormat === 'Video' && contentType && (
        <Center h="full" w="full">
          <video
            style={{
              height: heightFormatted ?? '100%',
              width: widthFormatted ?? 'auto',
              objectFit: objectFit ?? 'cover',
            }}
            onPlay={onPlay}
            controls
            autoPlay={false}
            poster={poster}
            preload={preload ? 'metadata' : 'none'}
          >
            <source src={url} type="video/mp4" />
          </video>
        </Center>
      )}
      {mediaFormat === 'Audio' && contentType && (
        <Center h="full" w="full">
          <audio controls autoPlay={false} preload={preload ? 'metadata' : 'none'}>
            <source src={url} type={contentType} />
          </audio>
        </Center>
      )}
      {mediaFormat === 'Image' && (
        <CImage
          src={url}
          style={{ height: heightFormatted || '100%', objectFit: objectFit ?? 'contain' }}
          fallback={
            imageLoadError ? (
              <Center height={heightFormatted} color="error">
                Error
              </Center>
            ) : (
              loaderComponent
            )
          }
          onError={(error) => {
            console.error(`error loading image ${url}`, { error });
            setImageLoadError.on();
          }}
        />
      )}
      {(mediaFormat === 'Documents' || mediaFormat === 'Other') &&
        (contentType === TextMimeTypeEnum.PDF ? (
          <PdfViewer viewMode={pdfViewMode} pdfLink={url} />
        ) : (
          <Center w="full" h="full">
            <object
              data={url}
              type={contentType}
              style={{ height: heightFormatted, minHeight: '32em', width: '100%' }}
            />
          </Center>
        ))}
    </>
  );
};

export const MediaDisplay = React.memo(MediaDisplayBase);
