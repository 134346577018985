import { assertUnreachable } from '@/utils/types';
import { Duration } from '@monax/xylem/dist/types';

export type DurationUnit = keyof Duration;

export type DurationPickerOption = {
  label: string;
  value: DurationUnit;
};

export const durationUnitToLabel = (key: DurationUnit): string => {
  switch (key) {
    case 'seconds':
      return 'Seconds';
    case 'minutes':
      return 'Minutes';
    case 'hours':
      return 'Hours';
    case 'days':
      return 'Days';
    case 'weeks':
      return 'Weeks';
    case 'months':
      return 'Months';
    case 'years':
      return 'Years';
  }

  assertUnreachable(key);
};

const allDurationOptions: DurationPickerOption[] = [
  { label: durationUnitToLabel('seconds'), value: 'seconds' },
  { label: durationUnitToLabel('minutes'), value: 'minutes' },
  { label: durationUnitToLabel('hours'), value: 'hours' },
  { label: durationUnitToLabel('days'), value: 'days' },
  { label: durationUnitToLabel('weeks'), value: 'weeks' },
  { label: durationUnitToLabel('months'), value: 'months' },
  { label: durationUnitToLabel('years'), value: 'years' },
];

const UnitOrderDictionary = {
  seconds: 0,
  minutes: 1,
  hours: 2,
  days: 3,
  weeks: 4,
  months: 5,
  years: 6,
};

export const getFilteredUnitOptions = (minUnit?: DurationUnit, maxUnit?: DurationUnit): DurationPickerOption[] => {
  let options = [...allDurationOptions];
  if (minUnit) {
    options = options.filter((o) => UnitOrderDictionary[o.value] >= UnitOrderDictionary[minUnit]);
  }
  if (maxUnit) {
    options = options.filter((o) => UnitOrderDictionary[o.value] <= UnitOrderDictionary[maxUnit]);
  }
  return options;
};

type ReturnType = ['years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds', number | undefined];

export const getDurationUnitAndValue = (
  duration: Duration,
  defaultValue: ['years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds', number | undefined],
): ReturnType => {
  if (Object.entries(duration).length === 1) return Object.entries(duration)[0] as ReturnType;

  return (Object.entries(duration).find(([, v]) => v > 0) as ReturnType | undefined) ?? defaultValue;
};
