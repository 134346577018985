import copyIconDark from '@/assets/organizations/organization-dark.svg';
import copyIconLight from '@/assets/organizations/organization-light.svg';
import { ResponsiveValue, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { Icon } from '../menu/Icon';

type Props = {
  cursor?: string;
  height?: ResponsiveValue<string>;
  width?: ResponsiveValue<string>;
};

export const OrganizationIcon: React.FC<Props> = ({ cursor = '', height = '5', width = '5' }) => {
  const src = useColorModeValue(copyIconDark, copyIconLight);

  return <Icon src={src} height={height} width={width} cursor={cursor} />;
};
