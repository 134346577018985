import { SystemStyleObject, theme } from '@chakra-ui/react';
import type { SystemStyleFunction } from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/button.ts

const baseStyle: SystemStyleObject = {
  borderRadius: 'md',
};

// Core Button Variants
// ===================================================================
const variantPrimary: SystemStyleFunction = () => {
  return {
    bg: 'primary',
    color: 'white',
    _hover: {
      bg: 'primary.400',
      _disabled: {
        bg: 'primary.400',
      },
    },
  };
};

const variantPrimaryOffset: SystemStyleFunction = () => {
  return {
    borderRadius: 'full',
    bg: 'primaryOffset',
    color: 'primary',
    _hover: {
      bg: 'whiteAlpha.800',
      _disabled: {
        bg: 'whiteAlpha.800',
      },
    },
  };
};

const variantSecondary: SystemStyleFunction = (props) => {
  return {
    bg: 'transparent',
    color: mode(`black`, `white`)(props),
    borderColor: 'primary',
    borderWidth: '2px',
  };
};

const variantOutline: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;
  const borderColor = mode(`gray.800`, `whiteAlpha.800`)(props);
  return {
    color: mode(`black`, `white`)(props),
    borderColor: c === 'gray' ? borderColor : props.borderColor ?? 'currentColor',
    borderWidth: '2px',
  };
};

const variantOutlineWithShadow: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;
  const borderColor = mode(`gray.800`, `whiteAlpha.300`)(props);
  const outlineVariant = theme.components.Button.variants?.outline as SystemStyleFunction;
  return {
    ...outlineVariant(props),
    ...variantOutline(props),
    borderColor: c === 'gray' ? borderColor : 'currentColor',
    _hover: { boxShadow: '0px 0px 20px rgba(255, 255, 255, 0.2)' },
  };
};

const variantAlert: SystemStyleFunction = () => {
  return {
    bg: 'orange.400',
    color: 'white',
    borderWidth: '0',
  };
};

const variantDefault: SystemStyleFunction = (props) => {
  return {
    bg: mode('gray.100', 'gray.900')(props),
    _hover: {
      bg: mode('gray.200', 'gray.800')(props),
    },
  };
};

const variantInactive: SystemStyleFunction = (props) => {
  return {
    bg: mode('gray.100', 'gray.800')(props),
    _hover: {
      bg: mode('gray.200', 'gray.700')(props),
    },
    _disabled: {
      opacity: mode(0.7, 0.4)(props),
      borderWidth: '1px',
      borderColor: mode('black', 'white')(props),
    },
  };
};

const variantLink: SystemStyleFunction = () => {
  return {
    color: 'primary',
    textTransform: 'uppercase',
  };
};

const variantBlock: SystemStyleFunction = (props) => {
  return {
    bg: mode(`gray.100`, `gray.800`)(props),
    color: mode(`black`, `white`)(props),
    textTransform: 'uppercase',
    w: 'full',
    justifyContent: 'start',
  };
};

const variantGhost: SystemStyleFunction = () => {
  return {
    _hover: {
      backgroundColor: (() => {
        return 'transparent';
      })(),
    },
  };
};

const variantGradient: SystemStyleFunction = () => {
  return {
    bg: 'linear-gradient(297.93deg, #D65C30 -26.16%, #7026D9 113.75%), #D9D9D9',
    _hover: {
      bg: 'linear-gradient(274.73deg, #953847 -57.62%, #6A2390 103.04%)',
    },
  };
};

const variantCircular: SystemStyleFunction = (props) => {
  return {
    w: '40px',
    h: '40px',
    padding: '0',
    bg: 'cardBackgroundNested',
    rounded: 'full',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: mode('gray.200', 'aspen.200')(props),
    cursor: 'pointer',
    _hover: { color: 'primary' },
  };
};

const variantGreen: SystemStyleFunction = () => {
  return {
    bg: 'green.200',
    color: 'green.500',
    _hover: {
      bg: 'green.50',
    },
    _disabled: {
      opacity: 1,
    },
  };
};

const variantYellow: SystemStyleFunction = () => {
  return {
    bg: 'yellow.500',
    color: 'black',
    _hover: {
      bg: 'yellow.300',
    },
  };
};

// ================================================================================

// Additional Button Variants (more specific)
// ===================================================================
const variantSolid: SystemStyleFunction = ({ colorScheme }) => {
  return {
    bg: (() => {
      if (colorScheme === 'primary') return 'primary';
      if (colorScheme === 'success') return 'green.50';
      return undefined;
    })(),
    color: (() => {
      if (colorScheme === 'primary') return 'white';
      if (colorScheme === 'success') return 'green.500';
      return undefined;
    })(),
    _hover: {
      bg: (() => {
        if (colorScheme === 'primary') return 'purple.400';
        return undefined;
      })(),
    },
  };
};

// ================================================================================

const variants = {
  primary: variantPrimary,
  primaryOffset: variantPrimaryOffset,
  secondary: variantSecondary,
  'outline-with-shadow': variantOutlineWithShadow,
  outline: variantOutline,
  alert: variantAlert,
  default: variantDefault,
  inactive: variantInactive,
  link: variantLink,
  block: variantBlock,
  solid: variantSolid,
  ghost: variantGhost,
  circular: variantCircular,
  gradient: variantGradient,
  green: variantGreen,
  yellow: variantYellow,
};

export const sizes: Record<string, SystemStyleObject> = {
  '2xl': {
    h: 12,
    minH: 12,
    minW: 12,
    fontSize: 'lg',
    px: 8,
  },
  xl: {
    h: 10,
    minH: 10,
    minW: 12,
    fontSize: 'lg',
    fontWeight: 'medium',
    borderRadius: '4xl',
    px: 6,
  },
  lg: {
    h: 9,
    minH: 9,
    minW: 12,
    fontSize: 'lg',
    px: 8,
  },
  md: {
    h: 7,
    minH: 7,
    minW: 10,
    fontSize: 'md',
    px: 8,
  },
  sm: {
    h: 5,
    minH: 5,
    minW: 8,
    fontSize: 'sm',
    px: 3,
    borderRadius: 'full',
  },
};

const defaultProps = {
  variant: 'default',
  size: 'md',
};

export const Button = {
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
