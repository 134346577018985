import { config } from '@/config';
import { handleError } from '@/hooks';
import { getAspenApi } from '@/utils/api-clients';
import { Address, encodeRichJson } from '@monax/xylem/dist/types';
import { getUnixTime } from 'date-fns';
import { persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import { AnalyticsUser, PageCategory, SegmentEvent, initAnalytics } from '../segment';

export interface TrackState {
  enabled: boolean;
  invoked: boolean;
  isLoading: boolean;
  initialLanding: boolean;
  sessionId: number;
  ensureAnalytics: () => Promise<void>;
  loadSession: () => void;
  resetSession: () => void;
  segmentIdentify: (userId: string, accountAddresses: Address[]) => Promise<void>;
  trackEvent: (event: SegmentEvent) => Promise<void>;
  trackPage: (category: PageCategory, name: string) => void;
  reset: () => void;
  mustGetAnonymousId: () => Promise<string>;
}

const initialStateFlags: Pick<TrackState, 'enabled' | 'invoked' | 'isLoading' | 'initialLanding'> = {
  enabled: !!config.integrations.segmentAnalyticsApiKey,
  invoked: false,
  isLoading: false,
  initialLanding: true,
};

/** Direct usage is discouraged! */
export const trackEventStore = createWithEqualityFn<TrackState>()(
  persist(
    (set, get) => ({
      ...initialStateFlags,
      sessionId: 0,
      ensureAnalytics: async () => {
        if (!get().enabled) {
          set({ invoked: true });
          return;
        }

        if (!window.analytics && !get().invoked) {
          set({ isLoading: true });
          try {
            await initAnalytics(set);
          } catch (error) {
            handleError(error, 'Failed to initialize analytics', { captureException: true });
          } finally {
            set({ isLoading: false });
          }
        }
      },
      loadSession: () => {
        if (get().sessionId === 0) get().resetSession();
      },
      resetSession: () => {
        set({ sessionId: getUnixTime(new Date()) });
      },
      segmentIdentify: async (userId, accountAddresses) => {
        if (!get().enabled) return;

        const anonymousId = await get().mustGetAnonymousId();
        await getAspenApi().request('trackIdentify', null, null, {
          anonymousId,
        });

        const analytics = window.analytics;
        const user = (await analytics.user()) as AnalyticsUser;
        let traits = {
          accountAddresses,
        };

        // check if user is anon. if so, merge traits with anon user traits which may contain utm info.
        if (user.id && !user.id()) {
          traits = Object.assign(user.traits(), traits);
        }

        analytics.identify(userId, traits, {
          context: {
            app: {
              version: config.appVersion,
            },
          },
        });
      },
      trackEvent: async (event: SegmentEvent) => {
        if (!get().enabled) return;

        const props = encodeRichJson(event.properties);
        await getAspenApi().request('trackEvent', null, null, {
          anonymousId: await get().mustGetAnonymousId(),
          name: event.name,
          properties: props,
          version: config.appVersion,
          sessionId: get().sessionId,
        });
      },
      trackPage: (category: PageCategory, name: string) => {
        if (category === 'internal') return;

        if (!get().enabled) return;

        const analytics = window.analytics;

        if (get().initialLanding) {
          get().trackEvent({ name: 'application-entered', properties: { pageName: name, pageCategory: category } });
          set({ initialLanding: false });
        }

        if (analytics) {
          analytics.page(
            category,
            name,
            {},
            {
              context: { app: { version: config.appVersion } },
              integrations: { Amplitude: { session_id: get().sessionId } },
            },
          );
        }
      },
      reset: () => {
        if (window.analytics?.reset) window.analytics.reset();
      },
      mustGetAnonymousId: async () => {
        if (!window.analytics) throw new Error('Analytics not initialized');
        return (await window.analytics.user()).anonymousId();
      },
    }),
    {
      name: 'session-id',
      partialize(state) {
        return { ...state, ...initialStateFlags };
      },
    },
  ),
  shallow,
);

// making sure we have a valid session
trackEventStore.getState().loadSession();

// for use in logout and other places
export const resetSegmentEventSession = () => {
  trackEventStore.getState().resetSession();
};

// TODO: this functions needs to be refactored everywhere
export const trackSegmentEvent = (event: SegmentEvent) => {
  trackEventStore.getState().trackEvent(event);
};
