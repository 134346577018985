import { Box, BoxProps, Center, Image, ImageProps, Skeleton, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

type Props = { queryIsLoading?: boolean; src?: string; imgProps?: ImageProps } & BoxProps;

export const ImageWithSkeleton: React.FC<Props> = ({ queryIsLoading, src, imgProps, ...props }) => {
  // If the parent query isn't loading and there's no src, consider
  // the img loaded so that the error component is displayed.
  const [imgIsLoaded, setImgIsLoaded] = useState(queryIsLoading ? false : !src);
  const fallbackProps = {
    borderRadius: props.borderRadius,
    borderTopRadius: props.borderTopRadius,
    borderBottomRadius: props.borderBottomRadius,
  };

  useEffect(() => {
    if (!queryIsLoading) setImgIsLoaded(!src);
  }, [queryIsLoading, src]);

  return (
    <Box overflow="hidden" {...props}>
      <Image
        w="full"
        h="full"
        objectFit="cover"
        src={src}
        onLoad={() => setImgIsLoaded(true)}
        onError={() => setImgIsLoaded(true)}
        fallback={imgIsLoaded ? <ErrorFallback {...fallbackProps} /> : <LoadingFallback {...fallbackProps} />}
        {...imgProps}
      />
    </Box>
  );
};

type FallbackProps = Pick<BoxProps, 'borderRadius' | 'borderTopRadius' | 'borderBottomRadius'>;

const ErrorFallback: React.FC<FallbackProps> = (props) => {
  const border = useColorModeValue('blackAlpha.400', 'whiteAlpha.500');

  return (
    <Center w="full" h="full" bg={border} border="1px solid" borderColor={border} {...props}>
      {/* TODO[andre]: validate with Luke if we want and icon for broken imgs or not */}
      {/* <Icon as={BsXCircle} boxSize="30%" minH={3} minW={3} maxH={6} maxW={6} /> */}
    </Center>
  );
};

const LoadingFallback: React.FC<FallbackProps> = (props) => {
  return <Skeleton w="full" h="full" {...props} />;
};
