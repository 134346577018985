import i18n, { TFunction } from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as yup from 'yup';
import common from './en/common.json';
import components from './en/components.json';
import storefrontPage from './en/storefrontPage.json';
import tooltips from './en/tooltips.json';
import validation from './en/validation.json';
import views from './en/views.json';

export type I18nNamspace = keyof typeof resources.en;

export const resources = {
  en: {
    common,
    views,
    components,
    validation,
    tooltips,
    storefrontPage,
  },
} as const;

// Build default yup validation messages
// All options here: https://github.com/jquense/yup/blob/master/src/locale.ts
export function buildYupLocale(_: unknown, t: TFunction): void {
  yup.setLocale({
    mixed: {
      required: t('validation:mixed.required'),
      notType: t('validation:mixed.required'), // replaces error messages when strings are used on number fields and similar scenarios
    },
  });
}

export const initLocalization = () => {
  i18n.use(initReactI18next).init(
    {
      lng: 'en',
      ns: ['common', 'views', 'components', 'validation', 'tooltips', 'storefrontPage'],
      resources,
    },
    buildYupLocale,
  );
};
