import darkIcon from '@/assets/sidebar/creator-tools-royalties-dark.svg';
import grayIcon from '@/assets/sidebar/creator-tools-royalties-gray.svg';
import lightIcon from '@/assets/sidebar/creator-tools-royalties-light.svg';
import { ImageProps, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { Icon } from './Icon';

type Variant = 'light' | 'dark' | 'gray';

type Props = ImageProps & {
  variant?: Variant;
};

export const RoyaltiesIcon: React.FC<Props> = ({ variant, height = '5', width = '5', ...imageProps }) => {
  const defaultIcon = useColorModeValue(darkIcon, lightIcon);
  return <Icon src={iconFromVariant(variant, defaultIcon)} height={height} width={width} {...imageProps} />;
};

const iconFromVariant = (variant: Variant | undefined, defaultIcon: string) => {
  switch (variant) {
    case 'light':
      return lightIcon;
    case 'dark':
      return darkIcon;
    case 'gray':
      return grayIcon;
    default:
      return defaultIcon;
  }
};
