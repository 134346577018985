import { useDidUpdateEffect } from '@/hooks';
import { userProfileStore } from '@/store/user';
import { userWalletStore } from '@/store/user/wallet';
import { useQueryClient } from 'react-query';
import { useStore } from 'zustand';

export const useQueryCache = () => {
  const chainId = useStore(userWalletStore, (s) => s.currentChainId);
  const selectedWalletAddress = useStore(userProfileStore, (s) => s.selectedWalletAddress);

  const queryClient = useQueryClient();

  // If the chainId OR wallet changes clear ALL query cache
  useDidUpdateEffect(() => {
    console.debug('clearing query cache');
    queryClient.invalidateQueries();
  }, [selectedWalletAddress, chainId]);
};
