import { getMagicLinkCallbackPath } from '@/components/routes';
import { config } from '@/config';
import { magicLinkStore } from '@/store/user/magic-link/magicLinkStore';
import { assertUnreachable } from '@/utils/types';
import { ExternalProvider, Web3Provider } from '@ethersproject/providers';
import { OAuthExtension } from '@magic-ext/oauth';
import { InstanceWithExtensions, SDKBase } from '@magic-sdk/provider';
import { Chain } from '@monax/xylem/dist/types';
import { normalizeChainId } from '@wagmi/core';
import { Signer, ethers } from 'ethers';
import { getAddress } from 'ethers/lib/utils';
import { Magic } from 'magic-sdk';
import { Address, Connector, Chain as WagmiChain } from 'wagmi';
import { getWagmiChainById, wagmiChains } from '../chains';

// If we get reports of Oauth sessions with errors on restart check: https://github.com/magiclabs/magic-js/issues/348

// TODO: this can potentially be replaced by the new official wagmi connectors here https://github.com/magiclabs?q=wagmi
export class MagicAuthConnector extends Connector<Web3Provider> {
  ready = true;
  readonly id = 'magic';
  readonly name = 'Magic';

  readonly magicMainnet: InstanceWithExtensions<SDKBase, {}>;
  readonly providerMainnet: Web3Provider;

  readonly magicPolygon: InstanceWithExtensions<SDKBase, {}>;
  readonly providerPolygon: Web3Provider;

  readonly magicMumbai: InstanceWithExtensions<SDKBase, {}>;
  readonly providerMumbai: Web3Provider;

  readonly magicGoerli: InstanceWithExtensions<SDKBase, {}>;
  readonly providerGoerli: Web3Provider;

  constructor(apiKey: string, config: { chains?: WagmiChain[] }) {
    super({ ...config, options: null });

    this.magicMainnet = new Magic(apiKey, {
      network: {
        rpcUrl: 'https://eth-rpc.gateway.pokt.network',
        chainId: Chain.Mainnet,
      },

      extensions: [new OAuthExtension()],
    });
    this.providerMainnet = new ethers.providers.Web3Provider(
      this.magicMainnet.rpcProvider as unknown as ExternalProvider,
    );

    this.magicPolygon = new Magic(apiKey, {
      network: {
        rpcUrl: getWagmiChainById(Chain.Polygon).rpcUrls.default.http[0],
        chainId: Chain.Polygon,
      },
      extensions: [new OAuthExtension()],
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.providerPolygon = new ethers.providers.Web3Provider(
      this.magicPolygon.rpcProvider as unknown as ExternalProvider,
    );

    this.magicMumbai = new Magic(apiKey, {
      network: {
        rpcUrl: getWagmiChainById(Chain.Mumbai).rpcUrls.default.http[0],
        chainId: Chain.Mumbai,
      },
      extensions: [new OAuthExtension()],
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.providerMumbai = new ethers.providers.Web3Provider(
      this.magicMumbai.rpcProvider as unknown as ExternalProvider,
    );

    this.magicGoerli = new Magic(apiKey, {
      network: {
        rpcUrl: getWagmiChainById(Chain.Goerli).rpcUrls.default.http[0],
        chainId: Chain.Goerli,
      },
      extensions: [new OAuthExtension()],
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.providerGoerli = new ethers.providers.Web3Provider(
      this.magicGoerli.rpcProvider as unknown as ExternalProvider,
    );
  }

  getMagic(): InstanceWithExtensions<SDKBase, {}> {
    const chainId = magicLinkStore.getState().chainId;
    switch (chainId) {
      case Chain.Mainnet:
        return this.magicMainnet;
      case Chain.Polygon:
        return this.magicPolygon;
      case Chain.Mumbai:
        return this.magicMumbai;
      case Chain.Goerli:
        return this.magicGoerli;
    }
    assertUnreachable(chainId);
  }

  async getProvider(): Promise<Web3Provider> {
    const chainId = magicLinkStore.getState().chainId;
    switch (chainId) {
      case Chain.Mainnet:
        return this.providerMainnet;
      case Chain.Polygon:
        return this.providerPolygon;
      case Chain.Mumbai:
        return this.providerMumbai;
      case Chain.Goerli:
        return this.providerGoerli;
    }
    assertUnreachable(chainId);
  }

  async getChainId(): Promise<number> {
    return magicLinkStore.getState().chainId;
  }

  async getSigner(): Promise<Signer> {
    return (await this.getProvider()).getSigner();
  }

  async getAccount(): Promise<Address> {
    const signer = await this.getSigner();
    return (await signer.getAddress()) as Address;
  }

  async isAuthorized(): Promise<boolean> {
    try {
      return await this.getMagic().user.isLoggedIn();
    } catch (e) {
      return false;
    }
  }

  async connect({ chainId }: { chainId?: number } = {}) {
    try {
      const provider = await this.getProvider();

      if (provider.on) {
        provider.on('accountsChanged', this.onAccountsChanged);
        provider.on('chainChanged', this.onChainChanged);
        provider.on('disconnect', this.onDisconnect);
      }

      const isAuthenticated = await this.isAuthorized();
      const id = chainId ?? (await this.getChainId());

      // If there is a user logged in, return it
      if (isAuthenticated) {
        return { provider, chain: { id, unsupported: false }, account: await this.getAccount() };
      }

      const magic = this.getMagic();

      const origin = window.location.pathname + window.location.search;
      const redirectURI = window.location.origin + getMagicLinkCallbackPath(origin);

      // TODO: in the meantime while we need to support both email and social logins, we need to try to login with both
      const socialLoginProvider = magicLinkStore.getState().provider;
      const email = magicLinkStore.getState().email;

      if (socialLoginProvider) {
        await (magic as any).oauth.loginWithRedirect({ provider: socialLoginProvider, redirectURI });
      } else if (email) {
        await magic.auth.loginWithMagicLink({ email, showUI: false });
      } else {
        throw new Error('Error connecting wallet, please sign in via e-mail');
      }

      // Temporary hack for this connector to work the first time before the redirect, otherwise it would throw and error
      // because the signer is not connected or there's no signer yet. Solution is to use the official magic link wagmi connector
      const signer = await this.getSigner();
      let account = undefined as unknown as Address;

      try {
        account = (await signer.getAddress()) as Address;
      } catch {}

      return { account, chain: { id, unsupported: false }, provider };
    } catch (error) {
      console.error('error while connecting wallet', error);
      throw error;
    }
  }

  async disconnect(): Promise<void> {
    await Promise.allSettled([
      (await this.magicMainnet.user.isLoggedIn()) ? this.magicMainnet.user.logout() : Promise.resolve(null),
      (await this.magicPolygon.user.isLoggedIn()) ? this.magicPolygon.user.logout() : Promise.resolve(null),
      (await this.magicMumbai.user.isLoggedIn()) ? this.magicMumbai.user.logout() : Promise.resolve(null),
      (await this.magicGoerli.user.isLoggedIn()) ? this.magicGoerli.user.logout() : Promise.resolve(null),
    ]);
  }

  // This connector behaves differently: we can't switch chains through the magic ethereum provider,
  // calling this method after updating the chainId in `magicLinkStore` notifies the wagmi provider
  // that we "switched chains" by calling `this.onChainChanged` explicitily.
  forceEmitChainChanged(): void {
    const chainId = magicLinkStore.getState().chainId;
    this.onChainChanged(chainId);
  }

  protected onAccountsChanged(accounts: string[]): void {
    if (accounts.length === 0) this.emit('disconnect');
    else this.emit('change', { account: getAddress(accounts[0]) });
  }

  protected onChainChanged(chainId: string | number): void {
    const id = normalizeChainId(chainId);
    const unsupported = this.isChainUnsupported(id);
    this.emit('change', { chain: { id, unsupported } });
  }

  protected onDisconnect(): void {
    this.emit('disconnect');
  }
}

let magicLinkConnector: MagicAuthConnector | null = null;
export const getMagicLinkConnector = () => {
  if (!magicLinkConnector) {
    magicLinkConnector = new MagicAuthConnector(config.integrations.magicLinkApiKey, { chains: wagmiChains });
  }
  return magicLinkConnector;
};
