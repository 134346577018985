import {
  AudioMimeTypeEnum,
  ImageMimeTypeEnum,
  StorefrontMediaType,
  TextMimeTypeEnum,
  VideoMimeTypeEnum,
} from '@monax/aspen-spec';
import { vimeoRegex } from './vimeoRegex';
import { youtubeRegex } from './youtubeRegex';

export const SUPPORTED_IMAGE_EXTENSIONS = Object.keys(ImageMimeTypeEnum).map((e) => `.${e.toLowerCase()}`);
export const SUPPORTED_VIDEO_EXTENSIONS = Object.keys(VideoMimeTypeEnum).map((e) => `.${e.toLowerCase()}`);
export const SUPPORTED_AUDIO_EXTENSIONS = Object.keys(AudioMimeTypeEnum).map((e) => `.${e.toLowerCase()}`);
export const SUPPORTED_DOCUMENT_EXTENSIONS = Object.keys(TextMimeTypeEnum).map((e) => `.${e.toLowerCase()}`);

export const SUPPORTED_FILE_EXTENSIONS = [
  ...SUPPORTED_IMAGE_EXTENSIONS,
  ...SUPPORTED_VIDEO_EXTENSIONS,
  ...SUPPORTED_AUDIO_EXTENSIONS,
  ...SUPPORTED_DOCUMENT_EXTENSIONS,
];

export const isImage = (contentType?: string | null): boolean => {
  return !!Object.values(ImageMimeTypeEnum).find((type) => type === contentType);
};
export const isVideo = (contentType?: string | null): boolean => {
  return !!Object.values(VideoMimeTypeEnum).find((type) => type === contentType);
};
export const isAudio = (contentType?: string | null): boolean => {
  return !!Object.values(AudioMimeTypeEnum).find((type) => type === contentType);
};
export const isDocument = (contentType?: string | null): boolean => {
  return !!Object.values(TextMimeTypeEnum).find((type) => type === contentType);
};

export const getStorefrontMediaTypeFromContentType = (contentType?: string | null): StorefrontMediaType | undefined => {
  if (!contentType) return undefined;
  if (Object.values(ImageMimeTypeEnum).find((v) => v === contentType)) return 'Image';
  if (Object.values(VideoMimeTypeEnum).find((v) => v === contentType)) return 'Video';
  if (Object.values(AudioMimeTypeEnum).find((v) => v === contentType)) return 'Audio';
  if (Object.values(TextMimeTypeEnum).find((v) => v === contentType)) return 'Documents';
  return 'Other';
};

export type VideoPlayerConfig = {
  url: string;
  type: 'embed' | 'video';
};

export const getVideoPlayerConfig = (url: string): VideoPlayerConfig => {
  const [youtubeMatch] = Array.from(url.matchAll(youtubeRegex()));
  if (youtubeMatch) {
    return { url: `https://youtube.com/embed/${youtubeMatch[1]}`, type: 'embed' };
  }
  const [vimeoMatch] = Array.from(url.matchAll(vimeoRegex()));
  if (vimeoMatch) {
    return { url: `https://player.vimeo.com/video/${vimeoMatch[1]}`, type: 'embed' };
  }

  return { url, type: 'video' };
};
