import activeIcon from '@/assets/sidebar/creator-tools-collections-active.svg';
import icon from '@/assets/sidebar/creator-tools-collections.svg';
import type { ResponsiveValue } from '@chakra-ui/react';
import React from 'react';
import { Icon } from './Icon';

type Props = {
  active?: boolean;
  height?: ResponsiveValue<string>;
  width?: ResponsiveValue<string>;
};

export const CollectionsIcon: React.FC<Props> = ({ active = false, height = '5', width = '5' }) => {
  return <Icon src={active === true ? activeIcon : icon} height={height} width={width} />;
};
