import { TokenAssetMetadata } from '@/classes/CollectionContract/types';
import { MarketplaceToken } from '@/hooks/query/marketplace';
import { TokensQueryOrder } from '@/utils/reservoir/queries';
import { EmptyString, SafeAddress } from '@/views/CreatorTools/Royalties/components/StorefrontRoyaltiesForm/validation';
import { StorefrontMarketing } from '@/views/StorefrontV2/types';
import { ColorMode } from '@chakra-ui/react';
import {
  MemberRoles,
  MembershipList,
  Organization,
  RoyaltiesAppealsConfiguration,
  RoyaltiesAppealsVenue,
  RoyaltiesChangedEvent,
  SegmentUserType,
  Storefront,
  Subscription,
} from '@monax/aspen-spec';
import { Address, ChainId, JsonERecord, UUIDFromString } from '@monax/xylem/dist/types';
import * as t from 'io-ts';
import { config } from '../../config';
import { TrackState, trackEventStore } from './store';

// Gymnastics required because `Storefront` and others
// exported by `@monax/aspen-spec` are interfaces
type StorefrontType = t.TypeOf<typeof Storefront>;
type OrganizationType = t.TypeOf<typeof Organization>;
type SubscriptionDefinitionType = t.TypeOf<typeof Subscription>;
type MembershipListType = t.TypeOf<typeof MembershipList>;
type RoyaltiesChangedEventType = t.TypeOf<typeof RoyaltiesChangedEvent>;

export type AnalyticsUser = {
  id: () => string;
  anonymousId: () => string;
  traits: () => Record<string, unknown>;
};

export type TrackingPhaseset = {
  name: string;
  maxClaimableSupply: string;
  pricePerToken: number;
  allowlist: boolean;
  startTimestamp: Date;
};

export type EventAsset = {
  contractAddress: Address;
  tokenId: string;
};

type EventBase<N extends string, P extends JsonERecord> = {
  name: N;
  properties: P;
};

export type SegmentEvent =
  // Settings
  | EventBase<
      'wallet-added',
      {
        chainId?: string;
        address: Address;
        addressCount?: number;
      }
    >
  | EventBase<
      'wallet-removed',
      {
        chainId?: string;
        address: Address;
      }
    >
  | EventBase<
      'application-entered',
      {
        pageName: string;
        pageCategory: PageCategory;
      }
    >
  | EventBase<
      'setting-color-mode-changed',
      {
        colorMode: ColorMode;
      }
    >
  | EventBase<
      'settings-name-updated',
      {
        name: string;
      }
    >
  | EventBase<
      'settings-email-updated',
      {
        email: string;
        userType: SegmentUserType;
      }
    >
  | EventBase<
      'app-mode-changed',
      {
        appMode: SegmentUserType;
      }
    >
  // Portfolio
  | EventBase<
      'user-folders-link-copied',
      {
        folderId: string;
      }
    >
  | EventBase<
      'user-folders-assets-added-to-folder',
      {
        folderId: string;
        newAssets: EventAsset[];
        assets: EventAsset[];
      }
    >
  | EventBase<
      'user-folders-home-folder-set',
      {
        folderId: string;
        assets: EventAsset[];
      }
    >
  | EventBase<'portfolio-gallery-view-mode-selected', { userType: SegmentUserType }>
  | EventBase<'portfolio-default-view-mode-selected', { userType: SegmentUserType }>
  // Collection Page
  | EventBase<
      'nft-collection-collection-shared',
      {
        name: string;
        link: string;
      }
    >
  | EventBase<
      'nft-collection-external-link-accessed',
      {
        link: string | undefined;
      }
    >
  | EventBase<
      'nft-collection-social-accessed',
      {
        type: string;
        link: string | undefined;
      }
    >
  | EventBase<
      'nft-collection-video-played',
      {
        status: string;
        name: string;
        chainId: string;
        address: string;
        link: string | null | undefined;
      }
    >
  | EventBase<
      'nft-collection-collection-accept-terms',
      {
        userType: SegmentUserType;
        storefrontId: UUIDFromString | null;
        status: string;
        statusTitle?: string;
        statusDescription?: string;
        accountAddress?: string;
        chainId: string;
        collectionAddress: string;
        link?: string | null;
      }
    >
  | EventBase<
      'nft-collection-collection-advert-image-accessed',
      {
        chainId: string;
        title?: string;
        content?: string;
        collectionAddress: string;
        cta?: string;
        imageUrl?: string;
        redirectUrl?: string;
      }
    >
  | EventBase<
      'nft-collection-collection-advert-video-accessed',
      {
        chainId: string;
        title?: string;
        content?: string;
        collectionAddress: string;
        cta?: string;
        videoUrl?: string;
        redirectUrl?: string;
      }
    >
  | EventBase<
      'nft-collection-items-sorted',
      {
        chainId: string;
        collectionAddress: string;
        type?: string;
      }
    >
  // Storefront Page
  | EventBase<
      'nft-storefront-social-accessed',
      {
        storefrontId: UUIDFromString;
        userType: SegmentUserType;
        type: string | number;
        link: string | undefined;
      }
    >
  | EventBase<
      'nft-storefront-video-played',
      {
        storefrontId: UUIDFromString;
        userType: SegmentUserType;
        status: string;
        storefrontName: string;
        chainId: string;
        address: string;
        link: string | null | undefined;
      }
    >
  | EventBase<
      'nft-storefront-storefront-token-minted',
      {
        storefrontId: UUIDFromString;
        userType: SegmentUserType;
        status: string;
        statusTitle?: string;
        transactionHash?: string[];
        accountAddress: Address;
        quantity?: number;
        tokens?: TokenAssetMetadata[];
      }
    >
  | EventBase<
      'nft-storefront-collection-token-minted',
      {
        storefront: StorefrontMarketing | null;
        userType: SegmentUserType;
        status: string;
        statusTitle?: string;
        transactionHash?: string[];
        accountAddress: Address;
        quantity?: number;
        tokens?: TokenAssetMetadata[];
      }
    >
  | EventBase<
      'nft-storefront-collection-accept-terms',
      {
        userType: SegmentUserType;
        storefrontId: UUIDFromString | null;
        status: string;
        statusTitle?: string;
        statusDescription?: string;
        accountAddress?: string;
        chainId: string;
        collectionAddress: string;
        link?: string | null;
      }
    >
  | EventBase<
      'nft-storefront-collection-advert-image-accessed',
      {
        storefrontId: UUIDFromString;
        userType: SegmentUserType;
        chainId: string;
        title?: string;
        content?: string;
        collectionAddress: string;
        cta?: string;
        imageUrl?: string;
        redirectUrl?: string;
      }
    >
  | EventBase<
      'nft-storefront-collection-advert-video-accessed',
      {
        storefrontId: UUIDFromString;
        userType: SegmentUserType;
        chainId: string;
        title?: string;
        content?: string;
        collectionAddress: string;
        cta?: string;
        videoUrl?: string;
        redirectUrl?: string;
      }
    >
  | EventBase<
      'nft-storefront-collection-advert-storefront-link-accessed',
      {
        storefrontId?: UUIDFromString;
        userType: SegmentUserType;
        chainId?: string;
        collectionAddress: string;
        cta?: string;
        redirectUrl?: string;
      }
    >
  | EventBase<
      'nft-storefront-items-sorted',
      {
        storefrontId: UUIDFromString;
        userType: SegmentUserType;
        chainId: string;
        collectionAddress: string;
        type?: string;
      }
    >
  | EventBase<
      'storefront-marketplace-buy-now-step-order-list',
      {
        userType: SegmentUserType;
        storefront: StorefrontMarketing | undefined;
        order: TokensQueryOrder | undefined;
      }
    >
  | EventBase<
      'storefront-marketplace-buy-now-step-preview',
      {
        userType: SegmentUserType;
        storefront: StorefrontMarketing | undefined;
        order: TokensQueryOrder | undefined;
      }
    >
  | EventBase<
      'storefront-marketplace-buy-now-step-transaction',
      {
        userType: SegmentUserType;
        storefront: StorefrontMarketing | undefined;
        order: TokensQueryOrder | undefined;
      }
    >
  | EventBase<
      'storefront-marketplace-buy-now-step-complete',
      {
        userType: SegmentUserType;
        storefront: StorefrontMarketing | undefined;
        order: TokensQueryOrder | undefined;
      }
    >
  | EventBase<
      'storefront-marketplace-view-listings',
      {
        userType: SegmentUserType;
        storefront: StorefrontMarketing | undefined;
        marketplaceToken: MarketplaceToken | undefined;
      }
    >
  | EventBase<
      'storefront-marketplace-create-listing-step-form',
      {
        userType: SegmentUserType;
        storefront: StorefrontMarketing | undefined;
        marketplaceToken: MarketplaceToken | undefined;
      }
    >
  | EventBase<
      'storefront-marketplace-create-listing-step-transaction',
      {
        userType: SegmentUserType;
        storefront: StorefrontMarketing | undefined;
        marketplaceToken: MarketplaceToken | undefined;
      }
    >
  | EventBase<
      'storefront-marketplace-create-listing-step-complete',
      {
        userType: SegmentUserType;
        storefront: StorefrontMarketing | undefined;
        marketplaceToken: MarketplaceToken | undefined;
      }
    >
  | EventBase<
      'storefront-marketplace-create-listing-step-complete',
      {
        userType: SegmentUserType;
        storefront: StorefrontMarketing | undefined;
        marketplaceToken: MarketplaceToken | undefined;
      }
    >
  | EventBase<
      'storefront-subscribe-to-correspondence',
      {
        storefrontId: UUIDFromString | null;
        type: string;
        userType: SegmentUserType;
      }
    >
  | EventBase<
      'storefront-unsubscribe-to-correspondence',
      {
        storefrontId: UUIDFromString | null;
        type: string;
        userType: SegmentUserType;
      }
    >
  // Nft Single Page
  | EventBase<
      'nft-details-collection-accept-terms',
      {
        userType: SegmentUserType;
        storefrontId: UUIDFromString | null;
        status: string;
        statusTitle?: string;
        statusDescription?: string;
        accountAddress?: string;
        chainId: string;
        collectionAddress: string;
        link?: string | null;
      }
    >
  | EventBase<
      'nft-details-nft-swiped',
      {
        userType: SegmentUserType;
        direction: string;
        chainId: string;
        tokenId: string;
        address: string;
      }
    >
  | EventBase<
      'nft-details-collection-accessed',
      {
        userType: SegmentUserType;
        chainId: string;
        tokenId: string;
        tokenAddress: string;
        link: string;
      }
    >
  | EventBase<
      'transfer-funds-from-email-wallet',
      {
        from: string;
        to: string;
      }
    >
  | EventBase<
      'transfer-funds-to-reservoir-wallet',
      {
        from: string;
        to: string;
      }
    >
  // Subscription Page
  | EventBase<
      'subscription-subscribed',
      {
        userType: SegmentUserType;
        status: string;
        statusTitle: string;
        storefrontId: UUIDFromString;
        definition: SubscriptionDefinitionType;
        months?: number;
      }
    >
  | EventBase<
      'subscription-voucher',
      {
        userType: SegmentUserType;
        status: string;
        statusTitle: string;
        subscriptionName?: string;
        subscriptionId: UUIDFromString;
        storefrontId: UUIDFromString;
        voucherId: string;
        duration: string | null;
      }
    >
  | EventBase<
      'voucher-campaign-created',
      {
        userType: SegmentUserType;
        status: string;
        statusTitle: string;
        subscriptionId: UUIDFromString;
        name: string;
        duration: string;
        quantity: number;
      }
    >
  | EventBase<
      'voucher-campaign-csv-download',
      {
        userType: SegmentUserType;
        status: string;
        statusTitle: string;
        subscriptionId: UUIDFromString;
        voucherCampaignId: UUIDFromString;
        campaignName: string;
        total: number;
      }
    >
  | EventBase<
      'coupon-created',
      {
        userType: SegmentUserType;
        status: string;
        statusTitle: string;
        subscriptionId: UUIDFromString;
        name: string;
        couponName: string;
        discountPercentage: number;
      }
    >
  | EventBase<
      'subscription-updated',
      {
        userType: SegmentUserType;
        status: string;
        statusTitle: string;
        subscriptionId: UUIDFromString;
        name: string;
        releaseStatus: string;
      }
    >
  | EventBase<
      'storefront-subscription',
      {
        userType: SegmentUserType;
        status: string;
        statusTitle: string;
        storefrontId: UUIDFromString;
        definition: SubscriptionDefinitionType | null;
      }
    >
  | EventBase<
      'storefront-subscription-subscriber',
      {
        userType: SegmentUserType;
        status: string;
        statusTitle: string;
        storefrontId: UUIDFromString;
        definition: SubscriptionDefinitionType | null;
        expiry: Date | null;
      }
    >
  // Creator Subscriptions
  | EventBase<
      'subscriptions-create-subscription',
      {
        userType: SegmentUserType;
        status: string;
        storefrontId: UUIDFromString;
        statusTitle: string;
      }
    >
  // Creator Tools 2.0
  | EventBase<
      'creator-tools-create-step',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        storefrontId: UUIDFromString | null;
        name: string;
      }
    >
  | EventBase<
      'creator-tools-import-collection-step-contract-address',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        chainId: ChainId;
        contractAddress: string | null | undefined;
        storefrontName: string | null | undefined;
      }
    >
  | EventBase<
      'creator-tools-import-collection-step-check-ownership',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        chainId: ChainId;
        contractAddress: string | null | undefined;
        storefrontName: string | null | undefined;
        owner: string | null | undefined;
      }
    >
  | EventBase<
      'creator-tools-import-collection-step-claim-proof',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        chainId: ChainId;
        contractAddress: string | null | undefined;
        storefrontName: string | null | undefined;
        owner: string | null | undefined;
      }
    >
  | EventBase<
      'creator-tools-import-collection-step-accept-terms',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        chainId: ChainId;
        contractAddress: string | null | undefined;
        storefrontName: string | null | undefined;
        owner: string | null | undefined;
        terms: object | null | undefined;
      }
    >
  | EventBase<
      'creator-tools-import-collection-step-claim-invoice',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        chainId: ChainId;
        contractAddress: string | null | undefined;
        storefrontName: string | null | undefined;
        owner: string | null | undefined;
        invoice: object | null | undefined;
      }
    >
  | EventBase<
      'creator-tools-import-collection-step-claim-processing',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        chainId: ChainId;
        contractAddress: string | null | undefined;
        storefrontName: string | null | undefined;
        owner: string | null | undefined;
      }
    >
  | EventBase<
      'creator-tools-import-collection-step-complete',
      {
        userType: SegmentUserType;
        chainId: ChainId;
        contractAddress: string | null | undefined;
        storefrontName: string | null | undefined;
        owner: string | null | undefined;
        storefront: object | null | undefined;
      }
    >
  | EventBase<
      'creator-tools-collection-step',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        storefront: StorefrontType;
        adSpacesCount: number;
      }
    >
  | EventBase<
      'creator-tools-nfts-step',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        storefront: StorefrontType;
      }
    >
  | EventBase<
      'creator-tools-nfts-step-single-nft-created',
      {
        userType: SegmentUserType;
        tokenName: string;
        tokenDescription: string;
        tokenExternalUrl: string;
        tokenMaxSupply: number | null;
      }
    >
  | EventBase<
      'creator-tools-nfts-step-bulk-upload-started',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        storefrontId: UUIDFromString;
      }
    >
  | EventBase<
      'creator-tools-nfts-step-bulk-upload-finished',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        storefrontId: UUIDFromString;
        tokensCount: number | null;
      }
    >
  | EventBase<
      'creator-tools-minting-step',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        storefront: StorefrontType;
        phaseset: TrackingPhaseset[];
        phaseCount: number;
      }
    >
  | EventBase<
      'creator-tools-terms-and-royalties-step',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        storefront: StorefrontType;
      }
    >
  | EventBase<
      'creator-tools-launch-step',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        storefront: StorefrontType;
        chainName: string;
      }
    >
  | EventBase<
      'creator-tools-sync-step-royalties',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        storefront?: StorefrontType;
      }
    >
  | EventBase<
      'creator-tools-sync-step-terms',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        storefront?: StorefrontType;
      }
    >
  | EventBase<
      'creator-tools-sync-step-reservoir',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        storefront?: StorefrontType;
      }
    >
  | EventBase<
      'creator-tools-sync-step-sidecar-terms',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        storefront?: StorefrontType;
      }
    >
  | EventBase<
      'creator-tools-sync-step-tokens-and-phases',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        storefront?: StorefrontType;
      }
    >
  | EventBase<
      'creator-tools-sync-step-max-claim-per-wallet',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        storefront?: StorefrontType;
      }
    >
  | EventBase<
      'creator-tools-sync-primary-sales-recipients',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        storefront?: StorefrontType;
      }
    >
  | EventBase<
      'creator-tools-sync-secondary-sales-recipients',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        storefront?: StorefrontType;
      }
    >
  | EventBase<
      'creator-tools-sync-name-and-symbol',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        storefront?: StorefrontType;
      }
    >
  | EventBase<
      'creator-tools-sync-global',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        storefront?: StorefrontType;
      }
    >
  | EventBase<
      'creator-tools-sync-pause',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        storefront?: StorefrontType;
      }
    >
  | EventBase<
      'creator-tools-sync-unpause',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        storefront?: StorefrontType;
      }
    >
  //Royalties
  | EventBase<
      'creator-tools-royalties-download-csv',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        storefront: StorefrontType;
        fractionPaid: number;
        received: string;
        recouped: string;
      }
    >
  | EventBase<
      'creator-tools-royalties-saved',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        canPayback: boolean;
        canAppeal: boolean;
        appealsVenue: RoyaltiesAppealsVenue | EmptyString;
        appealsConfigs: Required<RoyaltiesAppealsConfiguration>;
        safeAddresses: SafeAddress[];
        royaltiesChangedEvents: RoyaltiesChangedEventType[];
      }
    >
  | EventBase<
      'creator-tools-create-membership-list',
      {
        list: MembershipListType;
        userType: SegmentUserType;
      }
    >
  | EventBase<
      'creator-tools-update-membership-list',
      {
        list: MembershipListType;
        userType: SegmentUserType;
      }
    >
  // Organizations
  | EventBase<
      'organizations-create-organization',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        organization?: OrganizationType;
      }
    >
  | EventBase<
      'organizations-update-organization-name',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        organization?: OrganizationType;
        organizationName?: string;
      }
    >
  | EventBase<
      'organizations-invitation-user-sent',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        organization?: OrganizationType;
        accountAddress?: Address;
        role: MemberRoles;
      }
    >
  | EventBase<
      'organizations-invitation-user-accepted',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        organizationId: UUIDFromString;
        organizationName: string;
        invitationCode: UUIDFromString;
      }
    >
  | EventBase<
      'organizations-invitation-user-declined',
      {
        userType: SegmentUserType;
        savedStatus: boolean;
        organizationId: UUIDFromString;
        organizationName: string;
        invitationCode: UUIDFromString;
      }
    >
  // Royalties
  | EventBase<
      'royalties-payment-preview',
      {
        userType: SegmentUserType;
        storefrontId: UUIDFromString;
        tokenIds: string[];
        tokenCount: number;
        paymentAmount: string;
        paymentCurrency: string;
      }
    >
  | EventBase<
      'royalties-payment-transaction',
      {
        userType: SegmentUserType;
        storefrontId: UUIDFromString;
        tokenIds: string[];
        tokenCount: number;
        paymentAmount: string;
        paymentCurrency: string;
        successStatus: string;
      }
    >
  | EventBase<
      'royalties-appeal-preview',
      {
        userType: SegmentUserType;
        storefrontId: UUIDFromString;
        tokenId: string;
      }
    >
  | EventBase<
      'royalties-appeal-create',
      {
        userType: SegmentUserType;
        storefrontId: UUIDFromString;
        tokenId: string;
        successStatus: boolean;
      }
    >
  | EventBase<
      'royalties-appeal-paid',
      {
        userType: SegmentUserType;
        storefrontId: UUIDFromString;
        tokenId: string;
        successStatus: boolean;
      }
    >
  | EventBase<
      'royalties-appeal-wallet-connected',
      {
        userType: SegmentUserType;
        storefrontId: UUIDFromString;
        tokenId: string;
      }
    >;

export type PageCategory =
  | 'internal'
  | 'nft'
  | 'order'
  | 'user'
  | 'marketplace'
  | 'creator-tools'
  | 'callback'
  | 'subscription';

export const initAnalytics = async (set: (partial: Partial<TrackState>) => void): Promise<void> => {
  if (!config.integrations.segmentAnalyticsApiKey) {
    console.warn('No segment analytics id found');
    return;
  }

  // BELOW IS MOSTLY COPIED FROM: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/#step-2-copy-the-segment-snippet

  // Create a queue, but don't obliterate an existing one!
  const analytics = window.analytics || [];

  // If the real analytics.js is already on the page or the snippet was already invoked return.
  if (analytics.initialize || trackEventStore.getState().invoked) {
    return;
  }

  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'user',
    'once',
    'off',
    'on',
    'addSourceMiddleware',
    'addIntegrationMiddleware',
    'setAnonymousId',
    'addDestinationMiddleware',
  ];

  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function (method: unknown) {
    return function (...args: unknown[]) {
      args.unshift(method);
      analytics.push(args);
      return analytics;
    };
  };

  // For each of our methods, generate a queueing stub.
  for (let i = 0; i < analytics.methods.length; i++) {
    const key = analytics.methods[i];
    analytics[key] = analytics.factory(key);
  }

  // Add a version to keep track of what's in the wild.
  analytics._writeKey = config.integrations.segmentAnalyticsApiKey;
  analytics.SNIPPET_VERSION = '4.1.0';

  window.analytics = analytics;

  set({ invoked: true });

  // Load Analytics.js with your key, which will automatically
  // load the tools you've enabled for your account. Boosh!
  return new Promise((resolve, reject) => {
    window
      .fetch(
        'https://cdn.segment.com/analytics.js/v1/' + config.integrations.segmentAnalyticsApiKey + '/analytics.min.js',
      )
      .then(async (res) => {
        if (!res.ok) {
          console.error(`Failed to load from segment CDN`);
          return;
        }
        // Dangerous, but we trust are trusting segment...
        const script = new TextDecoder('utf-8').decode(new Uint8Array(await (await res.blob()).arrayBuffer()));

        window.eval(script);

        checkIfAnalyticsAreLoaded(resolve, reject);
      })
      .catch((err) => {
        console.error(`Failed to load from segment CDN: ${err.message}`);
        resolve();
      });
  });
};

let retries = 0;
const MAX_RETRIES = 10;

const checkIfAnalyticsAreLoaded = (resolve: () => void, reject: (reason?: Error) => void) => {
  if (typeof window.analytics?.user()?.anonymousId === 'function') {
    console.log('Analytics loaded');
    resolve();
  } else if (retries < MAX_RETRIES) {
    console.warn('Analytics not loaded yet, retrying.');
    retries++;
    setTimeout(() => checkIfAnalyticsAreLoaded(resolve, reject), 1000);
  } else {
    reject(new Error('max retries'));
  }
};
