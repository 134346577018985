import { Box } from '@chakra-ui/layout';
import { BoxProps, Container as ContainerChakra, ContainerProps } from '@chakra-ui/react';
import React from 'react';

type Props = {
  maxW?: ContainerProps['maxW'];
} & BoxProps;

export const Container: React.FC<React.PropsWithChildren<Props>> = ({ children, maxW, ...rest }) => {
  if (maxW) {
    return (
      <ContainerChakra maxW={maxW} {...rest}>
        {children}
      </ContainerChakra>
    );
  }

  return (
    <Box px={16} {...rest}>
      {children}
    </Box>
  );
};
