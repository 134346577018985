import { Chain } from '@monax/xylem/dist/types';

export type MagicSupportedChain =
  | typeof Chain.Mainnet
  | typeof Chain.Polygon
  | typeof Chain.Mumbai
  | typeof Chain.Goerli;

export const isMagicSupportedChain = (chainId: number): chainId is MagicSupportedChain =>
  chainId === Chain.Mainnet || chainId === Chain.Polygon || chainId === Chain.Mumbai || chainId === Chain.Goerli;
