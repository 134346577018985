import { Avatar, AvatarBadge, Image } from '@chakra-ui/react';
import { createIcon } from '@download/blockies';
import React, { useEffect, useState } from 'react';

export type EthAddressIconSize = 'xl' | 'lg' | 'md' | 'sm' | 'xs';
type Props = {
  address: string;
  size?: EthAddressIconSize;
  isConnected?: boolean;
  iconOnly?: boolean;
};

export const EthAddressIcon: React.FC<Props> = ({ address, size = 'md', isConnected, iconOnly }) => {
  let scale = 9.2;
  if (size === 'lg') scale = 6;
  if (size === 'md') scale = 4.3;
  if (size === 'sm') scale = 2.8;
  if (size === 'xs') scale = 2.2;

  const [src, setSrc] = useState<string>('');

  useEffect(() => {
    const canvas = createIcon({
      seed: address,
      size: 10,
      scale: scale,
    });
    setSrc(canvas.toDataURL('image/png'));
  }, [address, size]);

  if (!src) return null;

  if (iconOnly) return <Image src={src} borderRadius="full" />;

  return (
    <Avatar size={size} src={src}>
      {isConnected && <AvatarBadge boxSize="0.95em" bg="lightgreen" borderColor="gray.500" borderWidth={1} />}
    </Avatar>
  );
};
