import { useAsyncEffect } from '@/hooks';
import { useLoadUserProfile } from '@/hooks/user';
import { accessTokenStore } from '@/store/user';
import { userSocialLinksStore } from '@/store/user/social-link';
import { trackEventStore } from '@/utils/segment';
import { useBoolean } from '@chakra-ui/react';
import { parse } from '@monax/xylem/dist/schema';
import { Address } from '@monax/xylem/dist/types';
import { useEffect } from 'react';
import { useStore } from 'zustand';

export const useUserProfile = () => {
  const [loaded, setLoaded] = useBoolean(false);

  const accessToken = useStore(accessTokenStore, (s) => s.token);
  const loadSocialLinks = useStore(userSocialLinksStore, (s) => s.load);
  const { invoked, isLoading, segmentIdentify } = useStore(trackEventStore, (s) => ({
    invoked: s.invoked,
    isLoading: s.isLoading,
    segmentIdentify: s.segmentIdentify,
  }));

  const { loadUserProfile, clearUserProfile } = useLoadUserProfile();

  // Load profile on initial load
  useAsyncEffect(async () => {
    if (accessToken && invoked && !isLoading) {
      const profile = await loadUserProfile();
      if (profile) {
        segmentIdentify(
          profile.id,
          profile.wallets.map((w) => parse(Address, w.address)),
        );
        loadSocialLinks();
      }
    }
    setLoaded.on();
  }, [accessToken, invoked, isLoading, segmentIdentify]);

  useEffect(() => {
    if (!accessToken) {
      // if we have no token, we should have no user profile
      clearUserProfile();
    }
  }, [accessToken]);

  return {
    loaded,
  };
};
