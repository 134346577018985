import { getBlockchain } from '@monax/aspen-spec';
import type { ChainId } from '@monax/xylem/dist/types';
import React from 'react';
import { BaseIcon } from './BaseIcon';
import { CantoIcon } from './CantoIcon';
import { EthereumIcon } from './EthereumIcon';
import { PalmIcon } from './PalmIcon';
import { PolygonIcon } from './PolygonIcon';

type Props = {
  chainId: ChainId;
};

export const BlockchainIcon: React.FC<Props> = ({ chainId }) => {
  const blockchain = getBlockchain(chainId);

  if (blockchain === 'ETHEREUM') return <EthereumIcon />;
  if (blockchain === 'POLYGON') return <PolygonIcon />;
  if (blockchain === 'PALM') return <PalmIcon />;
  if (blockchain === 'CANTO') return <CantoIcon />;
  if (blockchain === 'BASE') return <BaseIcon />;

  throw new Error(`BlockchainIcon: unhandled chainId ${chainId}`);
};
