import { GroupBase, Select as ReactSelect } from 'chakra-react-select';
import React from 'react';
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';
import { FormItem } from '../..';
import { useInputProps } from '../../context';
import type { InputSize } from '../../types';
import { reactSelectDefaults } from './ReactSelect';
import type { SelectOption } from './types';

export type SelectProps<T extends FieldValues, O> = {
  name: Path<T>;
  label?: React.ReactNode;
  placeholder?: string;
  options: SelectOption<O>[];
  isClearable?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  size?: InputSize;
  tooltip?: string;
};

export function Select<T extends FieldValues, O = string>({
  name,
  label,
  placeholder,
  options,
  isClearable,
  isLoading,
  disabled,
  size,
  tooltip,
}: SelectProps<T, O>) {
  const { control } = useFormContext<T>();
  const inputProps = useInputProps({ name, size, isDisabled: disabled });
  return (
    <FormItem<T> name={name} label={label} tooltip={tooltip}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, name, ref, onBlur, value } }) => {
          return (
            <ReactSelect<SelectOption<O>, false, GroupBase<SelectOption<O>>>
              ref={ref}
              name={name}
              placeholder={placeholder}
              options={options}
              onBlur={onBlur}
              isClearable={isClearable}
              value={options.find((o) => o.value === value)}
              isLoading={isLoading}
              onChange={(val) => {
                onChange(val?.value ?? null);
              }}
              {...reactSelectDefaults(inputProps.size)}
              {...inputProps}
            />
          );
        }}
      />
    </FormItem>
  );
}
