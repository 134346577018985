import { config } from '@/config';
import { BooleanAppFeatures, updateFeatureAttributes } from '@/featureflags';
import { useIntercomHandler } from '@/utils/intercom';
import { trackEventStore } from '@/utils/segment';
import { PageCategory } from '@/utils/segment/segment';
import React, { useEffect } from 'react';
import { useStore } from 'zustand';
import { FeatureDevelopment } from '../layout';

type Props = {
  layout?: React.FC<React.PropsWithChildren<unknown>>;
  pageCategory: PageCategory;
  pageName: string;
  feature?: BooleanAppFeatures;
};

export const RouteWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  layout: Layout = React.Fragment,
  pageCategory,
  pageName,
  feature,
  children,
}) => {
  const { invoked, isLoading, trackPage } = useStore(trackEventStore, (s) => ({
    invoked: s.invoked,
    isLoading: s.isLoading,
    trackPage: s.trackPage,
  }));

  useEffect(() => {
    if (!invoked || isLoading) return;
    trackPage(pageCategory, pageName);
    updateFeatureAttributes();
  }, [invoked, isLoading, pageCategory, pageName]);

  const intercomEnabled = !!config.integrations.intercomAppId;

  const content = (
    <>
      {feature ? (
        <FeatureDevelopment feature={feature}>
          <Layout>{children}</Layout>
        </FeatureDevelopment>
      ) : (
        <Layout>{children}</Layout>
      )}
    </>
  );

  return intercomEnabled ? <IntercomWrapper pageCategory={pageCategory}>{content}</IntercomWrapper> : content;
};

type IntercomWrapperProps = {
  pageCategory: PageCategory;
};

export const IntercomWrapper: React.FC<React.PropsWithChildren<IntercomWrapperProps>> = ({
  pageCategory,
  children,
}) => {
  const { initializeIntercomByPageCategory } = useIntercomHandler();
  useEffect(() => {
    initializeIntercomByPageCategory(pageCategory);
  }, [pageCategory]);
  return <>{children}</>;
};
