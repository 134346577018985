import { getNullableUUIDFromStringSchema } from '@/utils/validation';
import * as yup from 'yup';
import { CreateRedemptionConfigForm, EditRedemptionConfigForm } from './types';

export const getRedemptionConfigFormSchema = () => {
  const schema: yup.SchemaOf<EditRedemptionConfigForm> = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    image: yup.string().required(),
    requireEmail: yup.boolean().required(),
    requireShippingAddress: yup.boolean().required(),
    webhookId: getNullableUUIDFromStringSchema(),
    subscriptionId: getNullableUUIDFromStringSchema(),
    subscriptionVoucherCampaignId: getNullableUUIDFromStringSchema(),
  });

  return schema;
};

export const getCreateRedemptionConfigFormSchema = () => {
  const schema: yup.SchemaOf<CreateRedemptionConfigForm> = yup.object().shape({
    name: yup.string().required(),
  });
  return schema;
};
