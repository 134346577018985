import { config } from '@/config';
import { QUERY_KEY } from '@/constants';
import { resolveIpfsUrl } from '@/utils/ipfs';
import { withThrottledRetries } from '@/utils/request';
import { pathMeta } from '@monax/aspen-spec';
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

export type MediaBlob = {
  blob: Blob;
  contentType: string;
};

export const useMediaBlob = ({
  url,
  fallbackUrl,
  useProxy,
  enabled,
}: {
  url: string;
  fallbackUrl?: string;
  useProxy?: boolean;
  enabled: boolean;
}): UseQueryResult<MediaBlob> => {
  const result = useQuery(
    [QUERY_KEY.media, url, fallbackUrl, useProxy],
    async () => {
      try {
        // Proxy request via our api, handy to get around CORs issues
        if (useProxy) url = `${config.apiUrl}${pathMeta['proxyGet'].url}?url=${encodeURIComponent(url)}`;

        const response = await withThrottledRetries(() =>
          axios.get<Blob>(resolveIpfsUrl(url), { responseType: 'blob' }),
        );

        if (response.status !== 200) throw new Error(`failed to get media, status: ${response.status}`);
        const blob = response.data;

        return {
          blob,
          contentType: response.headers['content-type'],
        };
      } catch (err) {
        console.error(err);
        if (!fallbackUrl) throw err;
      }

      console.log('trying with fallback media url');
      const response = await withThrottledRetries(() =>
        axios.get<Blob>(resolveIpfsUrl(fallbackUrl), { responseType: 'blob' }),
      );

      const blob = response.data;

      return {
        blob,
        contentType: response.headers['content-type'],
      };
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: enabled,
      retry: false,
    },
  );

  return result;
};
