import type { SelectOption } from '@/components/form';
import { MemberRoles, MemberRolesEnum } from '@monax/aspen-spec';

type RoleOption = SelectOption<MemberRoles>;

export const mapRoleToViewRole = (role: MemberRoles): string => {
  switch (role) {
    case MemberRolesEnum.ADMIN:
      return 'Admin';
    case MemberRolesEnum.MEMBER:
      return 'Editor';
    case MemberRolesEnum.READONLY:
      return 'Viewer';
    default:
      return 'Unknown';
  }
};

export const useRoleOptions = (): RoleOption[] => {
  return MemberRoles.types.map((c) => ({
    label: mapRoleToViewRole(c.value),
    value: c.value,
  }));
};
