import { userProfileStore } from '@/store/user';
import { userWalletStore } from '@/store/user/wallet';
import { ChainId } from '@monax/xylem/dist/types';
import { useCallback } from 'react';
import { useStore } from 'zustand';

export const useRequireWallet = (chainId?: ChainId) => {
  const selectedWalletAddress = useStore(userProfileStore, (s) => s.selectedWalletAddress);

  const {
    currentChainId,
    connectWalletDialogIsOpen,
    setCurrentChainId,
    setConnectWalletDialogIsOpen,
    setOnConnectWalletCancel,
  } = useStore(userWalletStore, (s) => ({
    currentChainId: s.currentChainId,
    connectWalletDialogIsOpen: s.connectWalletDialogIsOpen,
    setCurrentChainId: s.setCurrentChainId,
    setConnectWalletDialogIsOpen: s.setConnectWalletDialogIsOpen,
    setOnConnectWalletCancel: s.setOnConnectWalletCancel,
  }));

  const addWallet = useCallback(
    (onCancel?: VoidFunction | null) => {
      const isRequiring = !connectWalletDialogIsOpen;

      if (isRequiring) {
        if (onCancel) {
          setOnConnectWalletCancel(onCancel);
        }
        if (chainId && chainId !== currentChainId) {
          setCurrentChainId(chainId);
        }
        setConnectWalletDialogIsOpen(true);
      }

      return { isRequiring };
    },
    [connectWalletDialogIsOpen, chainId, currentChainId],
  );

  const requireWallet = useCallback(
    (onCancel?: VoidFunction | null) => {
      const isRequiring = !connectWalletDialogIsOpen && !selectedWalletAddress;

      if (isRequiring) {
        if (onCancel) {
          setOnConnectWalletCancel(onCancel);
        }
        if (chainId && chainId !== currentChainId) {
          setCurrentChainId(chainId);
        }
        setConnectWalletDialogIsOpen(true);
      }

      return { isRequiring };
    },
    [connectWalletDialogIsOpen, selectedWalletAddress, chainId, currentChainId],
  );

  return { addWallet, requireWallet };
};
