import { FormControl, FormControlProps } from '@chakra-ui/react';
import { get } from '@chakra-ui/utils';
import React, { ReactNode } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';
import { FormLabel } from './FormLabel';
import { InputError } from './InputError';

type Props<T extends FieldValues> = {
  name: Path<T>;
  label?: string | ReactNode;
  tag?: string;
  tooltip?: string;
} & Omit<FormControlProps, 'isInvalid' | 'label'>;

function FormItem<T extends FieldValues>({
  name,
  label,
  tag,
  tooltip,
  children,
  mb,
}: React.PropsWithChildren<Props<T>>) {
  const {
    formState: { errors },
  } = useFormContext<T>();

  const error = get(errors, name);

  return (
    <FormControl isInvalid={!!error} mb={mb ?? 4}>
      <FormLabel name={name} label={label} tag={tag} tooltip={tooltip} />
      {children}
      <InputError name={name} />
    </FormControl>
  );
}

export { FormItem };
