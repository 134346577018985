import { getAddressSchema } from '@/utils/validation';
import { MemberRoles, MemberRolesEnum } from '@monax/aspen-spec';
import { Address } from '@monax/xylem/dist/types';
import * as yup from 'yup';

export type CreateOrganization = { name: string };

export const getCreateOrganizationFormSchema = () => {
  const schema: yup.SchemaOf<CreateOrganization> = yup.object().shape({
    name: yup.string().required(),
  });
  return schema;
};

export type InviteUserOrganization = { address?: Address; role: MemberRoles };

export const getInviteUserOrganizationFormSchema = () => {
  const schema: yup.SchemaOf<InviteUserOrganization> = yup.object().shape({
    address: getAddressSchema().required(),
    role: yup.mixed<MemberRoles>().oneOf(Object.values(MemberRolesEnum)).required(),
  });
  return schema;
};
