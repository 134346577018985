import { Props as CardProps, LegacyCard } from '@/components/card';
import { CheckIcon } from '@chakra-ui/icons';
import { Box, Flex, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

const grayLightColor = 'rgba(0, 0, 0, 0.1)';
const grayDarkColor = 'rgba(255, 255, 255, 0.1)';

export type TimelineStep = {
  key: string | number;
  title: string;
  active: boolean;
};

type Props = {
  title?: string;
  steps: TimelineStep[];
} & CardProps;

const TimelineBase: React.FC<Props> = ({ title, steps, ...cardProps }) => {
  return (
    <LegacyCard {...cardProps}>
      {!!title && (
        <Box mb={2}>
          <Text fontWeight="bold" fontSize="lg">
            {title}
          </Text>
        </Box>
      )}
      <Box>
        <Flex flex="1 1 0%" flexDirection="row" width="full">
          {steps.map((step, idx) => (
            <TimelinePhase key={step.key} isActive={step.active} isFirst={idx === 0} />
          ))}
        </Flex>
      </Box>
      <SimpleGrid columns={steps.length}>
        {steps.map((step, idx) => (
          <Box key={step.key} pt={6} pb={2}>
            <Text textAlign={idx === 0 ? 'left' : idx === steps.length - 1 ? 'right' : 'center'} fontWeight="medium">
              {step.title}
            </Text>
          </Box>
        ))}
      </SimpleGrid>
    </LegacyCard>
  );
};

export const Timeline = React.memo(TimelineBase);

type TimelinePhaseProps = {
  isActive: boolean;
  isFirst: boolean;
};

const TimelinePhase: React.FC<TimelinePhaseProps> = ({ isActive, isFirst }) => {
  const borderColor = useColorModeValue(grayLightColor, grayDarkColor);
  return (
    <Flex flex={isFirst ? '0 0 auto' : '1 0 auto'} flexDirection="row" alignItems="center">
      {!isFirst && <Flex flex="1 1 0%" borderTop="2px" borderColor={isActive ? 'primary' : borderColor} />}
      <TimelineIcon isActive={isActive} />
    </Flex>
  );
};

type TimelineIconProps = {
  isActive: boolean;
};

const TimelineIcon: React.FC<TimelineIconProps> = ({ isActive }) => {
  const borderColor = useColorModeValue(grayLightColor, grayDarkColor);
  return (
    <Flex
      w={8}
      h={8}
      flex="none"
      justifyContent="center"
      alignItems="center"
      bg={isActive ? 'primary' : 'transparent'}
      rounded="full"
      border="2px"
      borderColor={isActive ? 'primary' : borderColor}
      fontSize="xl"
    >
      <CheckIcon color={isActive ? 'white' : 'transparent'} />
    </Flex>
  );
};
