import { BooleanAppFeatures, useFeatureIsOn } from '@/featureflags';
import React from 'react';

type Props = {
  feature: BooleanAppFeatures;
};

// Use for hiding/showing features is different environments
export const FeatureDevelopment: React.FC<React.PropsWithChildren<Props>> = ({ feature, children }) => {
  const showFeatureComponent = useFeatureIsOn(feature);
  return showFeatureComponent ? <>{children}</> : null;
};
