import { userWalletStore } from '@/store/user/wallet';
import { Drawer, DrawerOverlay } from '@chakra-ui/react';
import React, { lazy } from 'react';
import { useStore } from 'zustand';
import { SuspenseLoader } from '../common';

const ConnectWalletDrawerLazy = lazy(() => import('./ConnectWalletDrawerLazy'));

export const ConnectWalletDrawer: React.FC = () => {
  const connectWalletDialogIsOpen = useStore(userWalletStore, (s) => s.connectWalletDialogIsOpen);

  return connectWalletDialogIsOpen ? (
    <SuspenseLoader
      fallback={
        <Drawer isOpen={true} closeOnOverlayClick={false} onClose={() => null} size="lg">
          <DrawerOverlay />
        </Drawer>
      }
    >
      <ConnectWalletDrawerLazy />
    </SuspenseLoader>
  ) : null;
};
