import { Textarea as TextareaCui } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';
import { FormItem } from '..';
import { useInputProps } from '../context';
import type { InputSize } from '../types';

type Props<T extends FieldValues> = {
  name: Path<T>;
  label?: string | ReactNode;
  placeholder?: string;
  size?: InputSize;
  rows?: number;
  tooltip?: string;
  isDisabled?: boolean;
};

function Textarea<T extends FieldValues>({ name, label, placeholder, size, rows, tooltip, isDisabled }: Props<T>) {
  const { register } = useFormContext<T>();
  const hookProps = register(name);
  const inputProps = useInputProps({ name, size, isDisabled });
  return (
    <FormItem<T> name={name} label={label} tooltip={tooltip}>
      <TextareaCui id={name} placeholder={placeholder} rows={rows} {...inputProps} {...hookProps} />
    </FormItem>
  );
}

export { Textarea };
