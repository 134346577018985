import { useTranslation } from '@/featureflags';
import { Box, BoxProps, Button } from '@chakra-ui/react';
import type { DocumentMimeType } from '@monax/aspen-spec';
import { uniqueId } from 'lodash';
import React, { useMemo, useRef } from 'react';
import { useInputProps } from '../context';
import { useOnFileChange } from '../hooks';
import type { InputSize } from '../types';

type Props = {
  name: string;
  acceptMimes?: DocumentMimeType[];
  acceptExts: string[];
  onUploadFiles: (files: File[]) => void;
  maxFiles: number;
  multiple?: boolean;
  size?: InputSize;
  isLoading?: boolean;
  isDisabled?: boolean;
  button?: React.ReactNode;
  buttonContainerProps?: BoxProps;
};

export const FileInput: React.FC<React.PropsWithChildren<Props>> = ({
  name,
  acceptMimes = [],
  acceptExts,
  onUploadFiles,
  maxFiles = 10,
  multiple = true,
  size,
  isLoading,
  isDisabled,
  button,
  children,
  buttonContainerProps,
}) => {
  const { t } = useTranslation(['components']);

  const ref = useRef<HTMLInputElement>(null);
  const onChange = useOnFileChange(acceptMimes, onUploadFiles, maxFiles);
  const inputProps = useInputProps({ name, size, isDisabled });
  const uuid = useMemo(() => uniqueId(), []);

  const onClick = () => {
    if (isLoading || inputProps.isDisabled) return;
    ref.current?.click();
  };

  return (
    <>
      <input
        id={name}
        onChange={onChange}
        onDrop={onChange}
        value={undefined}
        style={{ display: 'none' }}
        ref={ref}
        type="file"
        multiple={multiple}
        accept={acceptExts.join(',')}
      />
      {button ? (
        <Box
          id={uuid}
          onClick={onClick}
          onDragOver={(ev) => ev.preventDefault()} // needed before `onDrop` event handler for events to be registered
          onDrop={onChange}
          cursor={inputProps.cursor}
          {...buttonContainerProps}
        >
          {button}
        </Box>
      ) : (
        <Button isLoading={isLoading} variant="secondary" onClick={onClick} {...inputProps}>
          {children ? children : t('components:common.file.uploadFile')}
        </Button>
      )}
    </>
  );
};
