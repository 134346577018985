import { ConnectWallet } from '@/components/wallet';
import { userProfileStore } from '@/store/user';
import { Box } from '@chakra-ui/react';
import React from 'react';
import { useStore } from 'zustand';

type Props = {
  requiresSelectedWallet?: boolean;
};

export const DefaultLayout: React.FC<React.PropsWithChildren<Props>> = ({
  requiresSelectedWallet = false,
  children,
}) => {
  const selectedWallet = useStore(userProfileStore, (s) => s.selectedWallet);

  return (
    <Box w="full" h="full" overflow="hidden">
      {!selectedWallet && requiresSelectedWallet ? <ConnectWallet /> : children}
    </Box>
  );
};
