import { useError } from '@/hooks';
import { toast } from '@/utils/toast';
import { ActionCard } from '@/views/CreatorTools/EditStorefront/steps/Manage/components/ActionCard';
import { redemptionConfigStore } from '@/views/CreatorTools/Redemption/store/redemptionConfigStore';
import { Button, ButtonGroup, useBoolean } from '@chakra-ui/react';
import { EditRedemptionConfig, RedemptionConfig, Storefront } from '@monax/aspen-spec';
import { parse } from '@monaxlabs/aspen-sdk';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useStore } from 'zustand';
import { EditRedemptionConfigForm } from '../types';
import { getTokenRange } from '../utils';

type Props = {
  storefront: Storefront;
  redemptionConfig: RedemptionConfig;
  methods: UseFormReturn<EditRedemptionConfigForm, any, undefined>;
};

export const PublishStatus: React.FC<Props> = ({ storefront, redemptionConfig, methods }) => {
  const { handleError } = useError();
  const [isLoading, setIsLoading] = useBoolean();
  const config = useWatch<EditRedemptionConfig>();

  const { updateRedemptionConfiguration, publishRedemptionConfiguration, unpublishRedemptionConfiguration } = useStore(
    redemptionConfigStore,
    (s) => ({
      updateRedemptionConfiguration: s.updateRedemptionConfiguration,
      publishRedemptionConfiguration: s.publishRedemptionConfiguration,
      unpublishRedemptionConfiguration: s.unpublishRedemptionConfiguration,
    }),
  );

  const publishRedemption = async () => {
    try {
      const isValid = await methods.trigger();
      if (!isValid) {
        toast({
          status: 'error',
          title: 'Please fill out all required fields',
        });
        return;
      }
      setIsLoading.on();
      const tokenRange = await getTokenRange(storefront.chainId, storefront.address, redemptionConfig.tokenRange);

      await updateRedemptionConfiguration(
        parse(EditRedemptionConfig, { storefrontId: storefront.id, tokenRange, ...config }),
        redemptionConfig.id,
      );
      await publishRedemptionConfiguration(storefront.id, redemptionConfig.id);
      toast({
        status: 'success',
        title: 'Redemption status updated successfully',
      });

      setIsLoading.off();
    } catch (error) {
      setIsLoading.off();
      handleError(error, 'Failed to update redemption status', { captureException: true });
    }
  };

  const unpublishRedemption = async () => {
    try {
      setIsLoading.on();
      await unpublishRedemptionConfiguration(storefront.id, redemptionConfig.id);
      toast({
        status: 'success',
        title: 'Redemption status updated successfully',
      });
      setIsLoading.off();
    } catch (error) {
      handleError(error, 'Failed to update redemption status', { captureException: true });
      setIsLoading.off();
    }
  };

  return (
    <ActionCard
      title="Publish Redemption"
      description="Publish your redemption program to make it available to your holders."
    >
      <ButtonGroup isAttached>
        <Button
          variant={redemptionConfig.published ? 'primary' : undefined}
          onClick={() => publishRedemption()}
          isLoading={isLoading}
          size="lg"
        >
          Publish
        </Button>
        <Button
          variant={!redemptionConfig.published ? 'primary' : undefined}
          onClick={() => unpublishRedemption()}
          isLoading={isLoading}
          size="lg"
        >
          Hidden
        </Button>
      </ButtonGroup>
    </ActionCard>
  );
};
