import type { SystemStyleObject } from '@chakra-ui/theme-tools';

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/form-label.ts

const baseStyle: SystemStyleObject = {
  fontSize: 'md',
};

export const FormLabel = {
  baseStyle,
};
