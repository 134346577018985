import { userWalletStore } from '@/store/user/wallet';
import { useEffect } from 'react';
import { useStore } from 'zustand';

export const useUserWallet = () => {
  const loadWallet = useStore(userWalletStore, (s) => s.load);

  useEffect(() => {
    loadWallet();
  }, []);
};
