import { QUERY_KEY } from '@/constants';
import { useAspenApiClient } from '@/hooks/common';
import { userWalletStore } from '@/store/user/wallet';
import { OwnedNftCollectionStorefronts } from '@monax/aspen-spec';
import { Address, ChainId } from '@monax/xylem/dist/types';
import { useQuery } from 'react-query';
import { useStore } from 'zustand';

type QueryParams = {
  accountAddresses: Address[];
  chainIds?: ChainId[];
  enabled?: boolean;
};

export const useOwnedNftCollectionsQuery = ({ accountAddresses, chainIds, enabled = true }: QueryParams) => {
  const { getApiClient } = useAspenApiClient();

  const chainId = useStore(userWalletStore, (s) => s.currentChainId);

  const params: QueryParams = {
    accountAddresses,
    chainIds: chainIds ?? [chainId],
  };

  const result = useQuery<OwnedNftCollectionStorefronts>(
    [QUERY_KEY.nftCollectionsOwned, ...Object.values(params)],
    async () => {
      return await getApiClient().request(
        'getOwnedNftCollections',
        null,
        { accountAddresses: JSON.stringify(params.accountAddresses), chainIds: JSON.stringify(params.chainIds) },
        null,
      );
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled,
      retry: false,
    },
  );

  return result;
};
