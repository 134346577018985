import { useWeb3EthersContext } from '@/contexts/Web3EthersContext';
import { userProfileStore } from '@/store/user';
import { magicLinkStore } from '@/store/user/magic-link/magicLinkStore';
import { getLastConnectorId } from '@/utils/local-storage';
import { getConnectorFor, wagmiConnectorIdToWalletConnectorType } from '@/utils/wagmi';
import { getMagicLinkConnector } from '@/utils/wagmi/connectors/magic-link-connector';
import { WalletConnectorType } from '@monax/aspen-identity-sdk';
import { useStore } from 'zustand';
import { useAsyncEffect } from '../common';

export const useReconnectLastConnector = () => {
  const {
    connect: { connectAsync },
  } = useWeb3EthersContext();
  const selectedWallet = useStore(userProfileStore, (s) => s.selectedWallet);
  const lastConnectorId = getLastConnectorId();
  const setEmail = useStore(magicLinkStore, (s) => s.setEmail);

  useAsyncEffect(async () => {
    if (!lastConnectorId || !selectedWallet) return;

    const lastConnectorType = wagmiConnectorIdToWalletConnectorType(lastConnectorId.replaceAll('"', ''));

    connectAsync({ connector: await getConnectorFor(lastConnectorType) });

    if (lastConnectorType === WalletConnectorType.MAGIC_LINK) {
      const email = (await getMagicLinkConnector().getMagic().user.getMetadata()).email;
      if (email) setEmail(email);
    }
  }, []);
};
