import { config } from '@/config';
import { useFeatureIsOn } from '@/featureflags';
import RedemptionPage from '@/views/CreatorTools/Redemption/RedemptionPage';
import React, { lazy } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { SuspenseLoader } from '../common';
import { ConnectedLayout } from '../layout';
import { DefaultLayout as DefaultLayoutV2 } from '../layoutV2';
import {
  getCreatorToolsCreateMembershipListPath,
  getCreatorToolsCreateStorefrontPath,
  getCreatorToolsEditMembershipListPath,
  getCreatorToolsEditStorefrontPath,
  getCreatorToolsEditWebhookPath,
  getCreatorToolsMarketingPath,
  getCreatorToolsMembershipsPath,
  getCreatorToolsOrganizationsPath,
  getCreatorToolsRedemptionPath,
  getCreatorToolsRoyaltiesPath,
  getCreatorToolsStorefrontsPath,
  getCreatorToolsSubscriptionsPath,
  getEmailVerificationPath,
  getHandleEmailAddedPath,
  getHandleSocialLinkAddedPath,
  getInternalComponentsPath,
  getMagicLinkCallbackPath,
  getNftDetailsPath,
  getOASPath,
  getRootPath,
  getStorefrontIdPath,
  getStorefrontSlugPath,
} from './paths';
import { RouteWrapper } from './RouteWrapper';

const NftDetailsPage = lazy(() => import('@/views/Nft/Details/NftDetailsPage'));
const StorefrontPage = lazy(() => import('@/views/StorefrontV2/StorefrontPage'));
const LandingPage = lazy(() => import('@/views/Landing/LandingPage'));
const ComponentsPage = lazy(() => import('@/views/Internal/ComponentsPage'));
const OASPage = lazy(() => import('@/views/Internal/OASPage'));
const UnderConstructionPage = lazy(() => import('@/views/UnderConstruction/UnderConstructionPage'));
const HandleSocialLinkAddedPage = lazy(() => import('@/views/Integration/HandleSocialLinkAddedPage'));
const HandleEmailAddedPage = lazy(() => import('@/views/Integration/HandleEmailAddedPage'));
const EmailVerificationPage = lazy(() => import('@/views/Integration/EmailVerificationPage'));
const CreateStorefrontPage = lazy(() => import('@/views/CreatorTools/CreateStorefront/CreateStorefrontPage'));
const EditStorefrontPage = lazy(() => import('@/views/CreatorTools/EditStorefront/EditStorefrontPage'));
const StorefrontsListPage = lazy(() => import('@/views/CreatorTools/StorefrontsList/StorefrontsListPage'));
const MembershipsPage = lazy(() => import('@/views/CreatorTools/Memberships/MembershipsPage'));
const CreateMembershipListPage = lazy(() => import('@/views/CreatorTools/MembershipList/CreateMembershipListPage'));
const EditMembershipListPage = lazy(() => import('@/views/CreatorTools/MembershipList/EditMembershipListPage'));
const MarketingPage = lazy(() => import('@/views/CreatorTools/Marketing/MarketingPage'));
const RoyaltiesPage = lazy(() => import('@/views/CreatorTools/Royalties/RoyaltiesPage'));
const SubscriptionsPage = lazy(() => import('@/views/CreatorTools/Subscriptions/SubscriptionsPage'));
const OrganizationsPage = lazy(() => import('@/views/CreatorTools/Organizations/OrganizationsPage'));
const EditWebhookPage = lazy(() => import('@/views/CreatorTools/EditWebhook/EditWebhookPage'));
const MagicLinkCallbackPage = lazy(() => import('@/views/Callback/MagicLinkCallbackPage'));

const routerInMaintenance = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="*"
      element={
        <RouteWrapper pageCategory="internal" pageName="under-construction">
          <UnderConstructionPage />
        </RouteWrapper>
      }
    />,
  ),
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route
        path={getNftDetailsPath(':chainId', ':contractAddress', ':tokenId', {}, false)}
        element={
          <RouteWrapper layout={DefaultLayoutV2} pageCategory="nft" pageName="preview">
            <NftDetailsPage />
          </RouteWrapper>
        }
      />
      <Route
        path={getStorefrontIdPath(':storefrontId', undefined, false)}
        element={
          <RouteWrapper layout={DefaultLayoutV2} pageCategory="nft" pageName="storefront">
            <StorefrontPage />
          </RouteWrapper>
        }
      />
      <Route
        path={getHandleSocialLinkAddedPath()}
        element={
          <RouteWrapper pageCategory="internal" pageName="handle-social-link-added-page">
            <HandleSocialLinkAddedPage />
          </RouteWrapper>
        }
      />
      <Route
        path={getHandleEmailAddedPath()}
        element={
          <RouteWrapper pageCategory="internal" pageName="handle-email-link-added-page">
            <HandleEmailAddedPage />
          </RouteWrapper>
        }
      />
      <Route
        path={getEmailVerificationPath()}
        element={
          <RouteWrapper pageCategory="user" pageName="email-verification-success">
            <EmailVerificationPage />
          </RouteWrapper>
        }
      />
      <Route
        path={getMagicLinkCallbackPath()}
        element={
          <RouteWrapper layout={DefaultLayoutV2} pageCategory="callback" pageName="magic-link-callback">
            <MagicLinkCallbackPage />
          </RouteWrapper>
        }
      />
      {config.appEnv !== 'production' && (
        <Route
          path={getInternalComponentsPath()}
          element={
            <RouteWrapper pageCategory="internal" pageName="components">
              <ComponentsPage />
            </RouteWrapper>
          }
        />
      )}
      <Route
        path={getOASPath()}
        element={
          <RouteWrapper pageCategory="internal" pageName="oas">
            <OASPage />
          </RouteWrapper>
        }
      />
      {/* Creator Tools --------------------------------------------------- */}
      <Route
        path={getCreatorToolsCreateStorefrontPath()}
        element={
          <RouteWrapper layout={ConnectedLayout} pageCategory="creator-tools" pageName="collections-create">
            <CreateStorefrontPage />
          </RouteWrapper>
        }
      />
      <Route
        path={getCreatorToolsEditStorefrontPath(':storefrontId', false)}
        element={
          <RouteWrapper layout={ConnectedLayout} pageCategory="creator-tools" pageName="collections-edit">
            <EditStorefrontPage />
          </RouteWrapper>
        }
      />
      <Route
        path={getCreatorToolsStorefrontsPath()}
        element={
          <RouteWrapper layout={ConnectedLayout} pageCategory="creator-tools" pageName="collections-list">
            <StorefrontsListPage />
          </RouteWrapper>
        }
      />
      <Route
        path={getCreatorToolsMembershipsPath()}
        element={
          <RouteWrapper layout={ConnectedLayout} pageCategory="creator-tools" pageName="memberships">
            <MembershipsPage />
          </RouteWrapper>
        }
      />
      <Route
        path={getCreatorToolsMarketingPath()}
        element={
          <RouteWrapper layout={ConnectedLayout} pageCategory="creator-tools" pageName="marketing">
            <MarketingPage />
          </RouteWrapper>
        }
      />
      <Route
        path={getCreatorToolsRoyaltiesPath()}
        element={
          <RouteWrapper layout={ConnectedLayout} pageCategory="creator-tools" pageName="collections-royalties">
            <RoyaltiesPage />
          </RouteWrapper>
        }
      />
      <Route
        path={getCreatorToolsSubscriptionsPath()}
        element={
          <RouteWrapper
            layout={ConnectedLayout}
            pageCategory="creator-tools"
            pageName="subscriptions"
            feature="creator-tools-subscriptions"
          >
            <SubscriptionsPage />
          </RouteWrapper>
        }
      />
      <Route
        path={getCreatorToolsRedemptionPath()}
        element={
          <RouteWrapper layout={ConnectedLayout} pageCategory="creator-tools" pageName="redemption">
            <RedemptionPage />
          </RouteWrapper>
        }
      />
      <Route
        path={getCreatorToolsOrganizationsPath()}
        element={
          <RouteWrapper layout={ConnectedLayout} pageCategory="creator-tools" pageName="organizations">
            <OrganizationsPage />
          </RouteWrapper>
        }
      />
      <Route
        path={getCreatorToolsCreateMembershipListPath(':storefrontId', false)}
        element={
          <RouteWrapper layout={ConnectedLayout} pageCategory="creator-tools" pageName="memberships-list-create">
            <CreateMembershipListPage />
          </RouteWrapper>
        }
      />
      <Route
        path={getCreatorToolsEditMembershipListPath(':storefrontId', ':listId', false)}
        element={
          <RouteWrapper layout={ConnectedLayout} pageCategory="creator-tools" pageName="memberships-list-edit">
            <EditMembershipListPage />
          </RouteWrapper>
        }
      />
      <Route
        path={getCreatorToolsEditWebhookPath(':webhookId', false)}
        element={
          <RouteWrapper layout={ConnectedLayout} pageCategory="creator-tools" pageName="organization-webhook-edit">
            <EditWebhookPage />
          </RouteWrapper>
        }
      />
      {/* ------------------------------------------------------------------- */}

      {/* This path is almost like a catch all and needs to sit at the bottom of the list */}
      <Route
        path={getStorefrontSlugPath(':storefrontId', undefined, false)}
        element={
          <RouteWrapper layout={DefaultLayoutV2} pageCategory="nft" pageName="storefront">
            <StorefrontPage />
          </RouteWrapper>
        }
      />
      <Route
        path={getRootPath()}
        element={
          <RouteWrapper layout={DefaultLayoutV2} pageCategory="order" pageName="landing">
            <LandingPage />
          </RouteWrapper>
        }
      />
      {/* Catch all other routes (should default to home page) */}
      <Route
        path="*"
        element={
          <RouteWrapper layout={DefaultLayoutV2} pageCategory="order" pageName="landing">
            <LandingPage />
          </RouteWrapper>
        }
      />
      {/* ----------------------------------------------------------------- */}
    </Route>,
  ),
);

export const AppRoutes: React.FC = () => {
  const inMaintenance = useFeatureIsOn('in-maintenance-mode');

  return (
    <SuspenseLoader>
      <RouterProvider router={inMaintenance ? routerInMaintenance : router} />
    </SuspenseLoader>
  );
};
