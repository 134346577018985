import type { AxiosError } from 'axios';

export const withThrottledRetries = async <Ret>(func: () => Promise<Ret>, retries = 0): Promise<Ret> => {
  try {
    const res = await func();
    return res;
  } catch (err) {
    const throttled =
      (err as Error).message?.startsWith('API Error 429') || (err as AxiosError).response?.status === 429;
    if (!throttled) {
      throw err;
    }
    if (retries >= 5) {
      throw new Error(`Maximum retries for requests`);
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return withThrottledRetries(func, retries + 1);
  }
};
