import { useForm } from '@/components/form';
import { FormConfigContext } from '@/components/form/context/FormConfigContext';
import { handleError } from '@/hooks';
import { toast } from '@/utils/toast';
import { Button } from '@chakra-ui/react';
import { EditRedemptionConfig, RedemptionConfig, Storefront } from '@monax/aspen-spec';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useStore } from 'zustand';
import { useRedemptionConfigFields } from '../hooks/useRedemptionConfigFields';
import { redemptionConfigStore } from '../store/redemptionConfigStore';
import { EditRedemptionConfigForm } from '../types';
import { getTokenRange } from '../utils';
import { getRedemptionConfigFormSchema } from '../validation';
import { RedemptionConfigForm } from './RedemptionConfigForm';

type Props = {
  storefront: Storefront;
  redemption: RedemptionConfig;
};

export const StorefrontRedemptionConfig: React.FC<Props> = ({ storefront, redemption }) => {
  const { enabledFields, allFieldsDisabled } = useRedemptionConfigFields(storefront.id);

  const { updateRedemptionConfiguration, updatePublishedRedemptionConfiguration } = useStore(
    redemptionConfigStore,
    (s) => ({
      updateRedemptionConfiguration: s.updateRedemptionConfiguration,
      updatePublishedRedemptionConfiguration: s.updatePublishedRedemptionConfiguration,
    }),
  );

  const methods = useForm<EditRedemptionConfigForm>(getRedemptionConfigFormSchema(), {
    name: redemption.name || '',
    description: redemption.description || '',
    image: redemption.image || null,
    requireEmail: redemption.requireEmail || false,
    requireShippingAddress: redemption.requireShippingAddress || false,
    webhookId: redemption.webhookId || null,
    subscriptionId: redemption.subscription ? redemption.subscription.subscriptionId : null,
    subscriptionVoucherCampaignId: redemption.subscription
      ? redemption.subscription.subscriptionVoucherCampaignId
      : null,
  });

  const onSubmit = async (data: EditRedemptionConfigForm) => {
    try {
      if (redemption.published) {
        await updatePublishedRedemptionConfiguration({ description: data.description }, storefront.id, redemption.id);
        toast({
          status: 'success',
          title: 'Redemption successfully updated',
        });

        return;
      }

      const tokenRange = await getTokenRange(storefront.chainId, storefront.address, redemption.tokenRange);

      const config: EditRedemptionConfig = {
        storefrontId: storefront.id,
        tokenRange,
        name: data.name,
        description: data.description,
        image: data.image,
        requireEmail: data.requireEmail,
        requireShippingAddress: data.requireShippingAddress,
        webhookId: data.webhookId ?? null,
        subscriptionVoucherCampaignId: data.subscriptionVoucherCampaignId ?? null,
      };

      await updateRedemptionConfiguration(config, redemption.id);

      toast({
        status: 'success',
        title: 'Redemption successfully updated',
      });
    } catch (error) {
      handleError(error, 'Error updating redemption', { captureException: true });
    }
  };

  return (
    <>
      <FormConfigContext.Provider
        value={{
          fields: enabledFields,
          fieldsMode: 'enabled',
        }}
      >
        <FormProvider {...methods}>
          <form id={`update-redemption-to-webhook-${redemption.id}`} onSubmit={methods.handleSubmit(onSubmit)}>
            <RedemptionConfigForm storefront={storefront} redemption={redemption} methods={methods} />
          </form>
        </FormProvider>
      </FormConfigContext.Provider>
      <Button
        form={`update-redemption-to-webhook-${redemption.id}`}
        type="submit"
        variant="primary"
        w="full"
        isDisabled={allFieldsDisabled}
        isLoading={methods.formState.isSubmitting || methods.formState.isValidating}
      >
        Save changes
      </Button>
    </>
  );
};
