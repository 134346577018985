import { Box, Button, HStack, useColorModeValue, useToken } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';
import { FormItem, SelectOption } from '..';
import { useInputProps } from '../context';

export const BOOLEAN_TOGGLE_OPTIONS: SelectOption<boolean>[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

type ToggleItem = {
  label: string;
  value: string | boolean | number;
};

type Props<T extends FieldValues> = {
  name: Path<T>;
  label?: string | ReactNode;
  items: ToggleItem[];
  tag?: string;
  tooltip?: string;
  isDisabled?: boolean;
  variant?: 'default' | 'gradient';
};

function Toggle<T extends FieldValues>({ name, label, items, tag, tooltip, isDisabled, variant }: Props<T>) {
  const { register, control } = useFormContext<T>();
  const hookProps = register(name);
  const inputProps = useInputProps({ name, isDisabled });
  const backgroundColor = useColorModeValue('blackAlpha.50', 'whiteAlpha.50');

  return (
    <Box backgroundColor={backgroundColor} w="-webkit-max-content" borderRadius="md" display="block">
      <FormItem<T> name={name} label={label} tag={tag} tooltip={tooltip} mb={0}>
        <HStack p={1}>
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <>
                  {items.map((item) => (
                    <FormToggleButton
                      key={`${item.value}`}
                      onClick={() => onChange(item.value ?? null)}
                      isActive={value === item.value}
                      variant={variant}
                      {...inputProps}
                      {...hookProps}
                    >
                      {item.label}
                    </FormToggleButton>
                  ))}
                </>
              );
            }}
          />
        </HStack>
      </FormItem>
    </Box>
  );
}

type FormToggleButtonProps = {
  onClick: () => void;
  isActive: boolean;
  isDisabled?: boolean;
  variant?: 'default' | 'gradient';
};

const FormToggleButton: React.FC<React.PropsWithChildren<FormToggleButtonProps>> = React.forwardRef(
  function FormToggleButton(
    { onClick, isActive, isDisabled, variant, children },
    ref: React.LegacyRef<HTMLButtonElement>,
  ) {
    const cardBackgroundNestedContent = useColorModeValue('blackAlpha.200', 'whiteAlpha.200');

    const activeBg = variant === 'gradient' ? 'primaryGradient' : 'primary';

    const [px, py] = useToken('space', [4, 0.5]);

    return (
      <Button
        ref={ref}
        variant="unstyled"
        size="xs"
        isDisabled={isDisabled}
        style={{ padding: `${py} ${px}` }}
        onClick={onClick}
        bg={isActive ? (isDisabled ? cardBackgroundNestedContent : activeBg) : undefined}
        color={isActive ? 'white' : undefined}
        borderRadius="md"
        fontSize="md"
        fontWeight="normal"
      >
        {children}
      </Button>
    );
  },
);

export { Toggle };
