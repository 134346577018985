import { LegacyCard } from '@/components/card';
import { Loader } from '@/components/common';
import { Box, BoxProps, Center, Icon, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { BiPlusMedical } from 'react-icons/bi';

export type Props = {
  title: string;
  isLoading?: boolean;
} & BoxProps;

export const UploadButton: React.FC<React.PropsWithChildren<Props>> = ({ title, children, isLoading, ...boxProps }) => {
  return (
    <LegacyCard
      variant="nested"
      borderColor="primary"
      borderStyle="dashed"
      borderWidth={1}
      w="full"
      h="full"
      mt={0}
      {...boxProps}
    >
      <Center h="full">
        {isLoading ? (
          <Loader loaderSize="lg" />
        ) : (
          <VStack w="full">
            <Icon boxSize={7} as={BiPlusMedical} aria-label="plus" />
            <Text fontWeight="semibold" fontSize="lg" textAlign="center">
              {title}
            </Text>
            <Box textAlign="center" color="textSubtle" fontWeight="medium" fontSize="md">
              {children}
            </Box>
          </VStack>
        )}
      </Center>
    </LegacyCard>
  );
};
