import { FormToggleCard } from '@/components/card';
import { Input, Select, Textarea } from '@/components/form';
import { InputError } from '@/components/form/InputError';
import { EditMedia } from '@/components/media';
import { useTranslation } from '@/featureflags';
import { useDidUpdateEffect } from '@/hooks';
import { useGetVoucherCampaignsQuery } from '@/hooks/query/subscription/useGetVoucherCampaignsQuery';
import { useWebhooksForOrganizationQuery } from '@/hooks/query/webhooks';
import { useWebhookOptions } from '@/hooks/webhooks';
import { SUPPORTED_IMAGE_EXTENSIONS } from '@/utils/media';
import { GridItem, HStack, SimpleGrid, Text } from '@chakra-ui/react';
import { RedemptionConfig, Storefront } from '@monax/aspen-spec';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useStore } from 'zustand';
import { useSubscriptionOptions } from '../hooks/useSubscriptionOptions';
import { redemptionConfigStore } from '../store/redemptionConfigStore';
import { EditRedemptionConfigForm } from '../types';
import { mapVoucherCampaignsOptions } from '../utils';
import { PublishStatus } from './PublishStatus';

type Props = {
  storefront: Storefront;
  redemption: RedemptionConfig;
  methods: UseFormReturn<EditRedemptionConfigForm>;
};

export const RedemptionConfigForm: React.FC<Props> = ({ storefront, redemption, methods }) => {
  const { t } = useTranslation(['tooltips']);

  const webhooksQuery = useWebhooksForOrganizationQuery();

  const { uploadFile, isUploading, subscriptions } = useStore(redemptionConfigStore, (s) => ({
    uploadFile: s.uploadFile,
    isUploading: s.isUploading,
    subscriptions: s.subscriptions,
  }));

  const webhookOptions = useWebhookOptions(webhooksQuery.data ?? []);

  const subscriptionOptions = useSubscriptionOptions(storefront.id, subscriptions);
  const subscriptionId = useWatch<EditRedemptionConfigForm, 'subscriptionId'>({
    name: 'subscriptionId',
  });

  const query = useGetVoucherCampaignsQuery(subscriptionId);

  useDidUpdateEffect(() => {
    if (subscriptionId) methods.setValue('subscriptionVoucherCampaignId', null);
  }, [subscriptionId]);

  const onUploadRedeemFile = async (file: File) => {
    const result = await uploadFile(storefront.id, 'redeemImage', file);
    if (result?.url) methods.setValue('image', result?.url);
  };

  return (
    <SimpleGrid columns={2} spacingX={3} spacingY={5}>
      <GridItem colSpan={2}>
        <Text fontSize="md" color="textSubtle">
          Create a new redeemable item for this collection. Allow holders of this collection to redeem assets or
          products (physical or digital) for every token they hold.
        </Text>
      </GridItem>
      <GridItem>
        <EditMedia
          name="image"
          onFileSelected={onUploadRedeemFile}
          url={redemption.image}
          mediaType="Upload File for Promo Image"
          isLoading={isUploading}
          acceptExts={SUPPORTED_IMAGE_EXTENSIONS}
          uploadButtonProps={{ py: '8' }}
          label="Image"
        >
          <Text textStyle="lg_LineHeight">Recommended screen size: 686x386</Text>
          <Text textStyle="lg_LineHeight">Supported formats: {SUPPORTED_IMAGE_EXTENSIONS.join(', ')}</Text>
        </EditMedia>
      </GridItem>
      <GridItem>
        <Input<EditRedemptionConfigForm>
          name="name"
          placeholder="Find out more"
          label="Title"
          tooltip={t('tooltips:redemption.name')}
        />
        <Textarea<EditRedemptionConfigForm>
          name="description"
          placeholder="Find out more"
          label="Content"
          tooltip={t('tooltips:redemption.description')}
        />
        <Select<EditRedemptionConfigForm>
          name="webhookId"
          label="Select webhook"
          placeholder="Select webhook"
          options={webhookOptions}
          isClearable={true}
          isLoading={webhooksQuery.isLoading}
        />
        <HStack>
          <Select<EditRedemptionConfigForm>
            name="subscriptionId"
            label="Select subscription"
            placeholder="Select subscription"
            isClearable={true}
            options={subscriptionOptions}
          />
          <Select<EditRedemptionConfigForm>
            name="subscriptionVoucherCampaignId"
            label="Select Voucher Campaign"
            placeholder="Select Voucher Campaign"
            isClearable={true}
            options={query.data ? mapVoucherCampaignsOptions(query.data) : []}
            isLoading={query.isLoading}
          />
        </HStack>
        <PublishStatus storefront={storefront} redemptionConfig={redemption} methods={methods} />
      </GridItem>
      <GridItem colSpan={2}>
        <Text fontSize="lg" fontWeight="bold">
          Redemption Distribution
        </Text>
        <Text fontSize="md" color="textSubtle">
          When holders choose to redeem, they will be asked to provide email address, postal address or both to complete
          distribution. This info will be sent to the webhook you have attached and is never stored by Aspen.
        </Text>
      </GridItem>
      <GridItem colSpan={2}>
        <InputError<EditRedemptionConfigForm> isStandAlone={true} name="requireEmail" />
        <FormToggleCard<EditRedemptionConfigForm>
          name="requireEmail"
          label="Email address"
          tooltip={t('tooltips:redemption.requireEmail')}
        />
        <FormToggleCard<EditRedemptionConfigForm>
          name="requireShippingAddress"
          label="Shipping address"
          tooltip={t('tooltips:redemption.requireShippingAddress')}
        />
      </GridItem>
    </SimpleGrid>
  );
};
