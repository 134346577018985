import { ChevronDownIcon } from '@chakra-ui/icons';
import { FlexboxProps, HStack, MenuButton as MenuButtonCui, MenuButtonProps, Text } from '@chakra-ui/react';
import React from 'react';

type Props = {
  startIcon?: React.ReactNode;
  justifyContent?: FlexboxProps['justifyContent'];
} & MenuButtonProps;

export const MenuButton: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  startIcon,
  justifyContent,
  ...rest
}) => {
  return (
    <MenuButtonCui {...rest}>
      <HStack justifyContent={justifyContent}>
        <HStack>
          {startIcon}
          <Text pr={4}>{children}</Text>
        </HStack>
        <ChevronDownIcon boxSize={4} />
      </HStack>
    </MenuButtonCui>
  );
};
