import type { SystemStyleFunction } from '@chakra-ui/theme-tools';
import { keyframes } from '@emotion/react';

const variantGradient: SystemStyleFunction = (props) => {
  return {
    background: props.background ?? 'linear-gradient(93deg, #855FF2 21.92%, #BA324F 117.22%, #D85B2C 214.78%)',
    backgroundClip: 'text !important',
    WebkitBackgroundClip: 'text !important',
    WebkitTextFillColor: 'transparent',
  };
};

const scollAnimation = keyframes`
from { background-position: 0% center;}
to { background-position: -200% center;}`;

const variantGradientScrolling: SystemStyleFunction = (props) => {
  return {
    background: props.background ?? 'linear-gradient(296.39deg, #7026d9, #bc4e5b, #7026d9)',
    backgroundClip: 'text !important',
    WebkitBackgroundClip: 'text !important',
    WebkitTextFillColor: 'transparent',
    animation: `${scollAnimation} 5s linear infinite`,
    backgroundSize: '200%',
  };
};

const variants = {
  gradient: variantGradient,
  gradientScrolling: variantGradientScrolling,
};

export const Text = {
  variants,
};

export const textGradientPropsFor = (gradient: string) => {
  return {
    background: gradient,
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  };
};
