import { config } from '@/config';
import { userProfileStore } from '@/store/user';
import { useIntercom } from 'react-use-intercom';
import { useStore } from 'zustand';
import { PageCategory } from '../segment';

export const useIntercomHandler = () => {
  const hasAppId = !!config.integrations.intercomAppId;

  const { boot, shutdown } = useIntercom();

  const { name, userId, email } = useStore(userProfileStore, (s) => ({
    name: s.displayName,
    userId: s.userId,
    email: s.email,
  }));

  const toggleIntercom = (enabled = true) => {
    if (!hasAppId) return;

    if (enabled) {
      boot({ name, userId, email });
      return;
    }
    shutdown();
  };

  const initializeIntercomByPageCategory = (pageCategory: PageCategory) => {
    toggleIntercom(pageCategory === 'creator-tools');
  };

  return { toggleIntercom, initializeIntercomByPageCategory };
};
